import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { base_url, countryList } from "../../services/const";
import {
  ButtonSpinner,
  SearchSpinner,
  userLevelColors,
} from "../../services/globalFunctions";
import { NotificationManager } from "react-notifications";
import { updateUserLevel } from "../../store/actions/loginAction";
import customHistory from "../../services/history";
import { urls } from "../../services/router/utils/urls";
import { isResponseError } from "../../utils/axios";

class BankTransferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      streetName: "",
      postalCode: "",
      city: "",
      country: "",
      vat: "",
      fName: "",
      lName: "",
      title: "",
      email: "",
      phone: "",
      submitLoading: false,
      showLoading: false,
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.getCompanyDetails = this.getCompanyDetails.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.show !== prevProps.show &&
      this.props.show &&
      this.state.companyName === ""
    ) {
      this.getCompanyDetails();
    }

    if (
      this.props.level !== prevProps.level &&
      this.props.level === this.props.description
    ) {
      customHistory.push("/company-profile/user/" + this.props.companyIds[0]);
    }
  }

  onSubmitForm(e) {
    e.preventDefault();
    this.setState({ submitLoading: true });
    let data = new URLSearchParams();
    data.append("fname", this.state.fName);
    data.append("lname", this.state.lName);
    data.append("company_name", this.state.companyName);
    data.append("street", this.state.streetName);
    data.append("city", this.state.city);
    data.append("postcode", this.state.postalCode);
    data.append("country", this.state.country);
    data.append("reg_no", this.state.vat);
    data.append("level", this.props.description);
    data.append("has_paid", "false");
    axios
      .request({
        method: "post",
        data: data,
        url: `${base_url}/accounting/paymentReceived`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        this.setState({ submitLoading: false });
        if (ret.data.status === 200) {
          NotificationManager.success("Data sent successfully", "Success");
          let userUpgrade = {
            fname: this.state.fName,
            lname: this.state.lName,
            level: this.props.description,
            firstLogin: true,
          };
          this.props.updateUserLevel(userUpgrade);
          this.props.onHide();
        } else {
          NotificationManager.error(
            "Something went wrong. Please try again.",
            "Error"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getCompanyDetails() {
    this.setState({ showLoading: true });
    axios
      .get(`/companies/${this.props.companyIds[0]}`)
      .then((res) => {
        if (!isResponseError(res)) {
          this.setState({
            companyName: res.data.name || "",
            city:
              res.data.addresses && res.data.addresses.length
                ? res.data.addresses[0].city_name
                : "",
            country:
              res.data.addresses && res.data.addresses.length
                ? res.data.addresses[0].country_name
                : "",
            postalCode:
              res.data.addresses && res.data.addresses.length
                ? res.data.addresses[0].postal_code
                : "",
            streetName:
              res.data.addresses && res.data.addresses.length
                ? res.data.addresses[0].street
                : "",
            title: "Dr.",
            fName:
              res.data.contacts && res.data.contacts.length
                ? res.data.contacts[0].first_name
                : "",
            lName:
              res.data.contacts && res.data.contacts.length
                ? res.data.contacts[0].last_name
                : "",
            email:
              res.data.contacts && res.data.contacts.length
                ? res.data.contacts[0].email
                : "",
            phone:
              res.data.contacts && res.data.contacts.length
                ? res.data.contacts[0].phone
                : "",
            showLoading: false,
          });
        } else {
          this.setState({ showLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        size={"lg"}
        onHide={this.props.onHide}
        className="ContactUsModal"
      >
        <Modal.Header
          className="border-0"
          closeButton
          style={{ background: userLevelColors(this.props.level).bg }}
        >
          <h3 style={{ color: userLevelColors(this.props.level).font }}>
            INVOICE
          </h3>
        </Modal.Header>
        <SearchSpinner
          showLoading={this.state.showLoading}
          level={this.props.level}
        />
        <Modal.Body
          className={"pt-0 " + (this.state.showLoading ? "d-none" : "")}
        >
          <div>
            <p className="mt-2">Please enter your invoice details here.</p>
            <p className="mt-2">
              After all details have been entered, you will receive an invoice
              for your membership, which is due at the end of your trial period.
            </p>
          </div>
          <form onSubmit={this.onSubmitForm}>
            <h6 className="text-uppercase text-center">Your company details</h6>
            <div className="row">
              <div className="col-lg-12 row pr-0">
                <div className="form-group col-lg-12">
                  <input
                    value={this.state.companyName}
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Company Name"
                    onChange={(e) =>
                      this.setState({ companyName: e.target.value })
                    }
                    required={true}
                  />
                </div>
              </div>
              <div className="row col-lg-12 pr-0">
                <div className="form-group col-lg-8">
                  <input
                    value={this.state.streetName}
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Street"
                    onChange={(e) =>
                      this.setState({ streetName: e.target.value })
                    }
                    required={true}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <input
                    value={this.state.postalCode}
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Postal Code"
                    onChange={(e) =>
                      this.setState({ postalCode: e.target.value })
                    }
                    required={true}
                  />
                </div>
              </div>
              <div className="row col-lg-12 pr-0">
                <div className="form-group col-lg-6">
                  <input
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="City"
                    onChange={(e) => this.setState({ city: e.target.value })}
                    value={this.state.city}
                    required={true}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <select
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    onChange={(e) => this.setState({ country: e.target.value })}
                    value={this.state.country}
                    required={true}
                  >
                    <option disabled value={null}>
                      Country
                    </option>
                    {countryList.map((item, i) => {
                      return (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group col-lg-12">
                  <input
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="VAT registration"
                    onChange={(e) => this.setState({ vat: e.target.value })}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <h6 className="text-uppercase text-center">Your contact details</h6>
            <div className="row">
              <div className="row col-lg-12 pr-0">
                <div className="form-group col-lg-2">
                  <select
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    className={
                      "form-control " +
                      userLevelColors(this.props.level).className
                    }
                    required={true}
                  >
                    <option disabled selected value={null}>
                      Title
                    </option>
                    <option value="Dr.">Dr.</option>
                    <option value="Prof.">Prof.</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-group col-lg-5">
                  <input
                    required={true}
                    value={this.state.fName}
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="First Name"
                    onChange={(e) => this.setState({ fName: e.target.value })}
                  />
                </div>
                <div className="form-group col-lg-5">
                  <input
                    required={true}
                    value={this.state.lName}
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Last Name"
                    onChange={(e) => this.setState({ lName: e.target.value })}
                  />
                </div>
              </div>
              <div className="row col-lg-12 pr-0">
                <div className="form-group col-lg-6">
                  <input
                    required={true}
                    value={this.state.email}
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <input
                    required={true}
                    value={this.state.phone}
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Phone"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    required={true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleCheck1"
                    style={{ marginTop: "5px", maxWidth: "100%" }}
                  >
                    You hereby agree to our{" "}
                    <Link to={urls.membership.agreement}>Terms</Link> and that
                    you have read our{" "}
                    <Link to={urls.privacyPolicy}>privacy policy</Link>,
                    including our Cookie use.
                  </label>
                </div>
              </div>
            </div>
            <Modal.Footer className="mt-3">
              <Button
                className="btn-wlr"
                variant="secondary"
                onClick={this.props.onHide}
                style={{
                  background: userLevelColors(this.props.level).buttonBg,
                  color: userLevelColors(this.props.level).buttonFont,
                  borderColor: userLevelColors(this.props.level).buttonFont,
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                className="btn-wlr"
                variant="dark"
                style={{
                  background: userLevelColors(this.props.level).buttonBg,
                  color: userLevelColors(this.props.level).buttonFont,
                  borderColor: userLevelColors(this.props.level).buttonFont,
                }}
              >
                {this.state.submitLoading ? (
                  <ButtonSpinner
                    level={this.props.level}
                    showLoading={this.state.submitLoading}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let userRole = state.login.group;
  let token = state.login.token;
  let agContact = state.login.ag_contact;
  let level = state.login.level;
  let companyIds = state.login.company_ids;
  return {
    userRole,
    token,
    agContact,
    level,
    companyIds,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserLevel: (userLevel) => dispatch(updateUserLevel(userLevel)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BankTransferModal)
);
