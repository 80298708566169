import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const CustomTabs = ({ items, setActiveButtonId, isAdmin }) => {
  const colors = ["#4EAC82", "#D5A559", "#C23F39", "#9DA8B1"];
  const backgrounds = ["#DBF6E5", "#FFF1D6", "#FFE4DE", "#EDEFF1"];

  const renderTabPanes = () => {
    const currentArr = isAdmin
      ? items
      : items.filter((item) => !item.adminOnly);
    return currentArr.map((item) => {
      const colorIndex = item.count % colors.length;
      const backgroundIndex = item.count % backgrounds.length;
      return (
        <TabPane
          tab={
            <div
              className="antCustomTab"
              style={{ display: "flex" }}
              onClick={() => setActiveButtonId(item.id)}
            >
              <div>{item.label}</div>
              <div
                style={{
                  marginLeft: 10,
                  minWidth: 30,
                  height: 30,
                  borderRadius: 5,
                  display: "flex",
                  padding: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 600,
                  color: colors[colorIndex],
                  backgroundColor: backgrounds[backgroundIndex],
                }}
              >
                {item.count}
              </div>
            </div>
          }
          key={item.key}
        />
      );
    });
  };

  return (
    <Tabs size="large" defaultActiveKey="1">
      {renderTabPanes()}
    </Tabs>
  );
};
