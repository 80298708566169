import React from "react";
import { Redirect, Route } from "react-router-dom";
import { store } from "../../../../store/store";
import { urls } from "../../utils/urls";
import { roles } from "../../../const";

export const UserRoutes = ({ component: Component, ...rest }) => {
  const loginLevel = store.getState().login.level;
  const isLoggedIn = store.getState().login.isLoggedIn;
  const { jumpseat, accepted } = roles;

  const validateUser = () => {
    try {
      return loginLevel !== jumpseat && loginLevel !== accepted && isLoggedIn;
    } catch (e) {
      console.log(e);
    }
  };

  const isUser = validateUser();

  return (
    <Route
      {...rest}
      render={(props) =>
        isUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: urls.jumpseat.registration,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
