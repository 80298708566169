import React from "react";
import { authenticateUser } from "../../store/actions/loginAction";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { countryList, googleMapApiKey } from "../../services/const";
import { SearchResultListItem } from "../../components/SearchResultListItem";
import MapContainer from "../../components/MapContainer";
import {
  filterCompaniesByCountry,
  getCountriesFromCompaniesList,
  SearchSpinner,
} from "../../services/globalFunctions";
import Select from "react-select";
import { isResponseError } from "../../utils/axios";
import { AgentInfoModal } from "./AgentInfoModal";

class AgentSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      searchVal: "",
      countries: countryList,
      agentsCc: [],
      showLoading: false,
      matchingAg: null,
      centerTo: null,
      agentProfileModal: null,
    };
    this.getAgents = this.getAgents.bind(this);
    this.onChangeCountryFilter = this.onChangeCountryFilter.bind(this);
    this.onClickMarker = this.onClickMarker.bind(this);
    this.onClickListItem = this.onClickListItem.bind(this);
  }

  componentDidMount() {
    this.getAgents();
  }

  getAgents(e) {
    e ? e.preventDefault() : void 0;
    this.setState({ showLoading: true });
    axios
      .get("/companies", {
        params: {
          per_page: -1,
          // role_id: 2
        },
      })
      .then((ret) => {
        if (!isResponseError(ret)) {
          let companies = ret.data.data.slice();

          companies.map((item, i) => {
            if (item.id === this.props.agContact.company_id) {
              this.setState({ matchingAg: item.id });
              companies.splice(0, 0, companies.splice(i, 1)[0]);
            }
            return null;
          });
          this.setState({
            agents: companies,
            agentsCc: companies,
            countries: getCountriesFromCompaniesList(companies),
            showLoading: false,
          });
        } else {
          this.setState({ showLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ showLoading: false });
        console.log(err);
      });
  }

  onChangeCountryFilter(e) {
    if (e.value === "") {
      this.getAgents();
    } else {
      this.setState({
        agents: filterCompaniesByCountry(this.state.agentsCc, e.value),
      });
    }
  }

  onClickMarker(marker) {
    console.log("marker", marker);
    this.state.agents.map((item) => {
      document
        .getElementById(item.id)
        .classList.remove("map-marker-match-company");
      return null;
    });
    let element = document.getElementById(marker);
    element.classList.add("map-marker-match-company");
    const offset = 90;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  onClickListItem(id) {
    this.state.agents.map((item) => {
      document
        .getElementById(`${item.id}`)
        .classList.remove("map-marker-match-company");

      return null;
    });
    let element = document.getElementById(id);
    element.classList.add("map-marker-match-company");
    let obj = this.state.agents.find((o) => o.id === id);
    console.log("OBJ", obj);
    if (obj.lat && obj.lon) {
      let latLng = {
        lat: parseFloat(obj.lat),
        lng: parseFloat(obj.lon),
        companyId: obj.id,
      };
      this.setState({ centerTo: latLng });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      let address =
        obj.hq_address +
        " " +
        obj.hq_city +
        " " +
        obj.hq_postcode +
        " " +
        obj.hq_country;
      let companyId = obj.id;
      axios
        .get(
          `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${encodeURI(
            address
          )}&inputtype=textquery&fields=photos,formatted_address,name,rating,opening_hours,geometry&key=${googleMapApiKey}`
        )
        .then((ret) => {
          if (ret.data.candidates.length > 0) {
            let latLng = ret.data.candidates[0].geometry.location;
            latLng.companyId = companyId;
            this.setState({ centerTo: latLng });
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        })
        .catch((err) => {
          console.log(err.request);
        });
    }
  }

  render() {
    let options = [];
    for (let i = 0; i < this.state.countries.length; i++) {
      if (this.state.countries[i]) {
        options.push({
          value: this.state.countries[i],
          label: this.state.countries[i],
        });
      }
    }

    const agentInfo = this.state.agentProfileModal;
    return (
      <div className="page-container">
        <div
          className={"page-header mb-5 position-relative"}
          style={{ height: "50vh" }}
        >
          <MapContainer
            centerTo={this.state.centerTo}
            onClickMarker={this.onClickMarker}
            agents={this.state.agents}
            latLng={[{ lat: 52.520008, lng: 13.404954 }]}
          />
          <form
            onSubmit={this.getAgents}
            className="form-group col-5 position-absolute topsearch-container row"
            style={{ bottom: "55px" }}
          >
            <h1 className="search-heading">Worldring Agents</h1>
            <input
              className="form-control topsearch"
              placeholder="Search.."
              value={this.state.searchVal}
              onChange={(e) => this.setState({ searchVal: e.target.value })}
            />
            <button
              type="submit"
              className="navbar-search-btn bg-transparent border-0"
              onClick={this.getAgents}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <h4 className="font-weight-light">&nbsp;</h4>
            </div>
            <div className="col-lg-8 col-sm-12 col-sm-12 row">
              <div className="col-4 ml-auto">
                <Select
                  options={options}
                  placeholder="Country"
                  onChange={this.onChangeCountryFilter}
                />
              </div>
            </div>
          </div>
          <SearchSpinner showLoading={this.state.showLoading} />
          <div className={"mt-5 " + (this.state.showLoading ? "d-none" : "")}>
            {this.state.agents.length > 0 ? (
              this.state.agents.map((item, i) => {
                return (
                  <div key={i} onClick={() => this.onClickListItem(item.id)}>
                    <SearchResultListItem
                      classAg={
                        this.state.matchingAg === item.id
                          ? "agent-match-search"
                          : ""
                      }
                      user={this.props.group}
                      isAgentSearchModal={true}
                      setAgentSearchModal={() =>
                        this.setState({ agentProfileModal: item })
                      }
                      button="Agent Profile"
                      {...item}
                    />
                  </div>
                );
              })
            ) : (
              <div
                className={
                  "searchresult w-100 row p-3 mb-4 position-relative col-lg-12 "
                }
              >
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="col-lg-2">
                    <img
                      style={{
                        height: "70px",
                        width: "auto",
                        objectFit: "contain",
                        maxWidth: "200px",
                      }}
                      src={require("../../assets/images/WorldRing.png")}
                      alt="..."
                    />
                  </div>
                  <div className="col-lg-6 ml-3">No agents found.</div>
                  <div className="col-lg-2 ml-3"></div>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.agentProfileModal && (
          <AgentInfoModal
            agentInfo={agentInfo}
            closeModal={() => this.setState({ agentProfileModal: null })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let token = state.login.token;
  let companyIds = state.login.company_ids;
  let level = state.login.group;
  let group = state.login.level;
  let agContact = state.login.ag_contact;
  return {
    isLoggedIn,
    token,
    companyIds,
    level,
    group,
    agContact,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AgentSearch);
