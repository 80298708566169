import React from "react";
import { Modal } from "../Modal";
import styles from "./index.module.scss";
import { crossIcon } from "../../assets/icons";
import { Button } from "../Button";

export const MyMatchesModal = ({ isOpen, onClose, myLogo, customerLogo }) => {
  const contactCustomer = () => {

  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.closeIconWrapper} onClick={onClose}>
          <img src={crossIcon} alt="close icon" />
        </div>
        <div className={styles.icons}>
          <div className={styles.iconWrapper}>
            <img
              src={`data:image/jpeg;base64,${myLogo ? myLogo.data : ""}`}
              alt="my company logo"
            />
          </div>
          <div className={styles.crossIconWrapper}>
            <img src={crossIcon} alt="cross icon" />
          </div>
          <div className={styles.iconWrapper}>
            <img
              src={`data:image/jpeg;base64,${customerLogo ? customerLogo.data : ""
                }`}
              alt="customer logo"
            />
          </div>
        </div>

        <div className={styles.title}>Hurray! You have a match!</div>
        <div className={styles.description}>
          Your entry: "Title, HSCode," has a match. View your match by clicking
          on the button below.
        </div>
        <div className={styles.buttons}>
          <Button label="View Match" onClick={contactCustomer} />
          <Button
            label="Proceed to Marketplace"
            type="secondary"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};
