import PropTypes from 'prop-types';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import  { useState } from 'react';

export const humanFileSize = (bytes, fraction = 2) => {
    if (!bytes) return '0 B';
    const index = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${Number((bytes / Math.pow(1024, index)).toFixed(fraction))} ${(['B', 'KB', 'MB', 'GB', 'TB'])[index]}`;
};

const notifyError = (str) => NotificationManager.error(str);
const notifyInfo = (str) => console.log(str);

const fileConfigs = [
    { type: 'application/zip', ext: 'zip' },
    { type: 'application/x-zip-compressed', ext: 'zip' },
    { type: 'application/x-7z-compressed', ext: '7z' },
    { type: 'text/plain', ext: 'txt' },
    { type: 'application/msword', ext: 'doc' },
    { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', ext: 'docx' },
    { type: 'application/vnd.ms-excel', ext: 'xls' },
    { type: 'application/vnd.ms-excel', ext: 'xlsx' },
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ext: 'xls' },
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ext: 'xlsx' },
    { type: 'application/pdf', ext: 'pdf' },
    { type: 'image/png', ext: 'png' },
    { type: 'image/jpeg', ext: 'jpg' },
    { type: 'text/xml', ext: 'xml' },
    { type: 'application/json', ext: 'json' }
];

const fileResponseCheck = (result) => {
    if (!result.data) {
        notifyError('No data in response');
        return;
    }
    if (result.data.error) {
        switch (result.data.type) {
            case 'Info':
                notifyInfo(result.data.error);
                break;
            default:
                notifyError(result.data.error);
        }
        return;
    }
    return result.data;
};

export const useFileUpload = (configUpload) => {
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [progressString, setProgressString] = useState('');
    const [uploading, setUploading] = useState(false);
    const [objectURL, setObjectURL] = useState(null);
    const [file, setFile] = useState(null);
    const [currentFileType, setCurrentFileType] = useState('txt');
    const controller = new AbortController();

    const config = {
        maxFileSizeMb: 5,
        isTemp:true,
        ...configUpload,
        config: {
            onUploadProgress: (progressEvent) => {
                if (config.progressHandler) config.progressHandler(progressEvent);
                if (progressEvent.total !== undefined) {
                    setProgressPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    setProgressString(`${humanFileSize(progressEvent.loaded, 0)} / ${humanFileSize(progressEvent.total, 0)}`);
                }
            },
            signal: controller.signal
        }
    };

    const formats = fileConfigs.map(f => config.fileFormats.includes(f.ext) ? f.type || f.ext : undefined).filter(t => !!t);

    const beforeFileUpload = (file) => {
        const isType = config.isAnyFormat || formats.some(f => file.type ? file.type === f : file.name.includes(f));
        const isSize = file.size / 1024 / 1024 < (config.maxFileSizeMb || 5);

        if (!isType) {
            notifyError(`The file format is not ${config.fileFormats.map(f => `${f}\n\r`).join('')}`);
        }
        if (!isSize) {
            notifyError(`File size exceeds ${config.maxFileSizeMb}MB!`);
        }
        return isType && isSize;
    };

    const uploadFile = (f) => {
        return new Promise((resolve, reject) => {
            if (!f || !beforeFileUpload(f)) {
                reject(null);
                return;
            }
            setCurrentFileType(f.name.slice(f.name.lastIndexOf('.') + 1));
            const fileUrl = URL.createObjectURL(f);
            setObjectURL(fileUrl);
            setFile(f);
            if (!f || !fileUrl) {
                reject(null);
                return;
            }
            setUploading(true);
            const pack = new FormData();
            pack.append('_method', 'PUT');
            if(config.label)
                pack.append('label', config.label);
            if(config.isManyFiles)
                pack.append('replace_existing', false);
            pack.append('is_temp', config.isTemp);
            pack.append(config.fileContentName || 'file', f);
            if (typeof config.formDataModel === 'object')
                Object.entries(config.formDataModel).forEach(([k, v]) => {
                    pack.append(k, v);
                });
            axios.post(config.resource, pack, config.config)
                .then(result => {
                    const checkResponse = fileResponseCheck(result);
                    if (checkResponse) resolve(checkResponse);
                    else reject(null);
                })
                .catch(e => {
                    if (e && e.message === 'canceled') {
                        notifyInfo(`Upload of file ${file.name} cancelled.`);
                    } else {
                        notifyError(e, 'Upload Error');
                    }
                    reject(null);
                })
                .finally(() => setUploading(false));
        });
    };

    const abortUpload = () => controller.abort();

    return {
        config,
        abortUpload,
        uploadFile,
        file,
        uploading,
        objectURL,
        currentFileType,
        progressPercentage,
        progressString
    };
};

useFileUpload.propTypes = {
    configUpload: PropTypes.shape({
        maxFileSizeMb: PropTypes.number,
        fileFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
        isManyFiles: PropTypes.bool,
        isAnyFormat: PropTypes.bool,
        isTemp: PropTypes.bool,
        config: PropTypes.object,
        resource: PropTypes.string.isRequired,
        formDataModel: PropTypes.object,
        fileContentName: PropTypes.string,
        label: PropTypes.string,
        progressHandler: PropTypes.func
    }).isRequired
};

export const beforeFileUpload = (file, maxFileSizeMb, fileFormats = [], notify) => {
    const isType = !fileFormats.length || fileFormats.some(f => file.type ? file.type === f : file.name.includes(f));
    const isSize = file.size / 1024 / 1024 < (maxFileSizeMb || 5);

    if (!isType) {
        notify({
            type: 'error',
            message: `The selected file has the wrong format ${fileFormats.map(f => `${f}\n\r`).join('')}`
        });
    }
    if (!isSize) {
        notify({
            type: 'error',
            message: `The selected file exceeds the allowable size of ${maxFileSizeMb}MB!`
        });
    }
    return isType && isSize;
};

export const readFiles = (files, fileFormats = ['application/json'], notify) => new Promise((resolve, reject) => {
    let textData = '';
    let count = files.length;
    let type = '';
    const onLoad = (event) => {
        textData += event.target.result;
        count--;
        if (!count) resolve({ data: textData, type });
    };
    Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = onLoad;
        if (file.type && !fileFormats.includes(file.type)) {
            console.error(`The selected file has the wrong format ${fileFormats.map(f => `${f}\n\r`).join('')}`, file.type, file);
            notify({
                type: 'error',
                message: `The selected file has the wrong format ${fileFormats.map(f => `${f}\n\r`).join('')}`
            });
            reject();
            return;
        }
        type = file.type;
        reader.readAsText(file);
    });
});

export const filePathToGlobal = (f)=>{
    if(f._isPathModifed)
        return  f
    const pathPrefix = `${process.env.REACT_APP_BACKEND_URL}/storage/`
    const modifyFile = {...f}
    if(f.path)
        modifyFile.path = `${pathPrefix}${f.path}`
    if(f.path)
        modifyFile.thumb = `${pathPrefix}${f.thumb}`
    f._isPathModifed = true
    return modifyFile
}