import React from "react";
import classes from "./AgentInfoModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMailBulk,
  faMobile,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

export const AgentInfoModal = ({ agentInfo, closeModal }) => {
  console.log("agentInfo", agentInfo);

  const isHaveContacts = agentInfo.contacts && agentInfo.contacts.length > 0;

  const contact = isHaveContacts && agentInfo.contacts[0];

  const contactLogo = isHaveContacts
    ? agentInfo.contacts[0].wp_logo || "https://via.placeholder.com/150x250"
    : "https://via.placeholder.com/150x250";
  return (
    <div className={classes.wrapper} onClick={closeModal}>
      <div className={classes.content} onClick={(e) => e.stopPropagation()}>
        <div
          className={classes.userImg}
          style={{
            backgroundImage: `url(${contactLogo})`,
          }}
        />

        <div className={classes.userInfo}>
          <h1>
            {isHaveContacts
              ? `${contact.first_name || ""} ${contact.last_name || ""}`
              : "-"}
          </h1>
          <h2> {isHaveContacts ? contact.job_title || "-" : "-"}</h2>

          <div className={classes.contact}>
            {isHaveContacts && contact.phone && (
              <>
                <FontAwesomeIcon style={{ marginTop: "5px" }} icon={faPhone} />
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
              </>
            )}

            {isHaveContacts && contact.mobile && (
              <>
                <FontAwesomeIcon style={{ marginTop: "5px" }} icon={faMobile} />
                <a href={`tel:${contact.mobile}`}>{contact.mobile}</a>
              </>
            )}

            {isHaveContacts && contact.email && (
              <>
                <FontAwesomeIcon
                  style={{ marginTop: "5px" }}
                  icon={faMailBulk}
                />
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
