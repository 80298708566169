import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {base_url} from "../../services/const";
import {NotificationManager} from "react-notifications";
import {authenticateUser} from "../../store/actions/loginAction";
import {connect} from "react-redux";
import {ButtonSpinner, userLevelColors} from "../../services/globalFunctions";
import CommentsList from "./CommentsList";
import {Link} from "react-router-dom";
import {filePathToGlobal} from "../../utils/file";

class NewsfeedItem extends React.Component {
    constructor(props) {
        super(props);
        this.textAreaRef = React.createRef();
        this.state = {
            showMore: false,
            showComment: false,
            commentText: '',
            postBtnLoading: false,
            comments: [],
            commentAdded: false,
            postLikedData: [],
            showPostLikes: false
        };
        this.showMore = this.showMore.bind(this);
        this.onClickLike = this.onClickLike.bind(this);
        this.onClickPostComment = this.onClickPostComment.bind(this);
        this.getPostLikes = this.getPostLikes.bind(this);
    }

    showMore() {
        if(this.props.item.content.length > 150) {
            return (
                <div>
                    <h4 className="col-lg-12 mt-4 ">{this.props.item.title}</h4>
                    <p className={"col-lg-12 mt-4 " + (this.state.showMore ? "d-none" : "")}>
                        <span dangerouslySetInnerHTML={{__html: (this.props.item.content.substr(0, 150) + "...")}} />
                    </p>
                    <p className={"col-lg-12 mt-4 " + (this.state.showMore ? "" : "d-none")}>
                        <span dangerouslySetInnerHTML={{__html: this.props.item.content}} />
                    </p>
                    <a className="col-lg-12 " style={{cursor: 'pointer'}} onClick={() => this.setState(prevState => ({
                        showMore: !prevState.showMore
                    }))}><u>{this.state.showMore ? 'Show Less' : 'Show More'}</u></a>
                </div>
            )
        } else {
            return (
                <div>
                    <p className="col-lg-12 mt-4">
                        <span dangerouslySetInnerHTML={{__html: this.props.item.content}} />
                    </p>
                </div>
            );
        }
    }

    onClickLike() {
        axios.request({
            method: 'put',
            url: `${base_url}/newsfeed/like/${this.props.item.newsfeed_id}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then((res) => {
            if(res.status === 200 && res.data.status === 200) {
                this.props.getNewsfeed();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    onClickPostComment() {
        this.setState({postBtnLoading: true, commentAdded: false});
        let params = new URLSearchParams();
        params.append('content', this.state.commentText);
        axios.request({
            method: 'put',
            data: params,
            url: `${base_url}/newsfeed/comment/${this.props.item.newsfeed_id}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then((res) => {
            this.setState({postBtnLoading: false});
            if(res.status === 200 && res.data.status === 200) {
                NotificationManager.success('Comment posted', 'Success');
                this.setState({commentText: '', showComment: false, commentAdded: true});
                this.props.getNewsfeed();
            } else {
                NotificationManager.error('Something went wrong. Try again.', 'Error');
            }
        }).catch(err => {
            console.log(err);
        });
    }

    getPostLikes(postId) {
        axios.request({
            method: 'get',
            url: `${base_url}/newsfeed/like/${postId}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then((res) => {
            if(res.status === 200 && res.data.status === 200) {
                this.setState({postLikedData: res.data.data, showPostLikes: true})
            } else {
                NotificationManager.error('Something went wrong. Try again.', 'Error');
            }
        }).catch(err => {
            console.log(err);
        });

    }

    render() {
        const {item} = this.props;
        item.company.files.forEach(f=>filePathToGlobal(f))
        const companyLogo = item.company.files.find(f => f.label === 'logo')
        const userImage = item.company.files.find(f => f.label === 'logo')
        const address = item.company.addresses[0] || {}
        const countryName = address.country_name
        const cityName = address.city_name

        return (
            <div>
                <div className="newsfeed">
                    <header className="newsfeed-header pb-0">
                        <img alt={'...'} src={companyLogo ? companyLogo.path : ''} style={{ objectFit: "contain" }} />
                        <div>
                            <h3 className="mb-0">{item.company.name}</h3>
                            <p className="mt-0"><small className="font-italic">{cityName}, {countryName}</small></p>
                        </div>
                    </header>
                    <div className="newsfeed-content">
                        {
                            this.showMore()
                        }
                        <img
                            alt={'...'}
                            src={userImage ? userImage.path : ''}
                            className="w-100 px-3 my-3"
                            style={{maxHeight: '300px'}}
                        />
                    </div>
                    <div className="row col-lg-12 mb-3 newsfeed-comments-likes">
                        <div className="pl-3" style={{cursor: 'pointer'}} onClick={() => item.likes > 0 ? this.getPostLikes(item.id) : void 0}><img src={require('../../assets/icons/like.png')} alt='...' style={{width: '25px'}} className="ml-2"/><span className="ml-3">{item.likes}</span></div>
                        <div className="ml-4" onClick={() => item.comments > 0 ? this.setState(prevState => ({
                            comments: !prevState.comments
                        })) : NotificationManager.error('This post has no comments.', '', 1500)}><img src={require('../../assets/icons/comment.png')} alt={'...'} style={{width: '25px'}} /><span className="ml-3">{item.comments}</span></div>
                    </div>
                    <footer className="newsfeed-footer">
                        <ul className="newsfeed-footer_list">
                            <li className="newsfeed-footer_element">
                                <a style={{color: 'black', textDecoration: 'none'}} onClick={this.onClickLike}><img src={require('../../assets/icons/like.png')} alt='...' style={{width: '25px'}} /><span className="ml-3">Like</span></a>
                            </li>
                            <li className="newsfeed-footer_element">
                                <a style={{color: 'black', textDecoration: 'none'}} onClick={() => this.setState(prevState => ({
                                    commentText: '',
                                    showComment: !prevState.showComment
                                }), () => this.textAreaRef.current.focus())} target='_blank'><img src={require('../../assets/icons/comment.png')} alt={'...'} style={{width: '25px'}} /><span className="ml-3">Comment</span></a>
                            </li>
                        </ul>
                    </footer>
                    <div className={this.state.showPostLikes ? "" : 'd-none'}>
                        <footer className="newsfeed-footer pl-4">
                            {
                                this.state.postLikedData.map((item, i) => {
                                    return <span key={i}>{this.state.postLikedData.length > 1 ? i === this.state.postLikedData.length - 1 ? ' and ' : '' : ''}<Link to={`/company-profile/${item.user.company_id}`}>{item.user.company}</Link>{this.state.postLikedData.length > 2 ? i === this.state.postLikedData.length - 1 || i === this.state.postLikedData.length - 2 ? '' : ', ' : ''} </span>
                                })
                            } liked this post
                        </footer>
                    </div>
                </div>
                <div className={"newsfeed-comments " + (this.state.comments ? "" : "d-none") + (item.comments > 0 ? "" : "d-none")}>
                    <CommentsList
                        postId={item.id}
                        show={this.state.comments}
                        commentAdded={this.state.commentAdded}
                        getNewsfeed={this.props.getNewsfeed}
                    />
                </div>
                <div className={"col-lg-12 px-0 mt-3 " + (this.state.showComment ? "" : "d-none")}>
                    <textarea
                        ref={this.textAreaRef}
                        rows={1}
                        className="w-100 pl-3 pt-3 pb-3"
                        style={{borderColor: '#ebebeb', resize: 'none', borderRadius: '5px', paddingRight: '125px'}}
                        value={this.state.commentText}
                        onChange={(e) => this.setState({commentText: e.target.value})}
                    />
                    <button
                        disabled={this.state.postBtnLoading}
                        style={{right: '30px', top: '15px', minWidth: 'unset'}}
                        onClick={this.onClickPostComment}
                        className="btn position-absolute btn-wlr"><span className={this.state.postBtnLoading ? "d-none" : ""}>Post</span> <ButtonSpinner showLoading={this.state.postBtnLoading} /></button>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let level = state.login.level;
    return {
        token, level
    };
};

const mapDispatchToProps = (dispatch) => ({
    authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsfeedItem);
