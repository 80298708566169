import React from "react";
import { brandLogoPath } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  checkFileIfExist,
  fileLabels,
} from "../../../../services/globalFunctions";
import { brandLogo } from "../../../../assets/brand";

export const TmpOrJsMainContact = ({ companyData }) => {
  return (
    <div
      className="company-profile-box box"
      style={{ paddingTop: 15, marginBottom: 0 }}
    >
      <div className="company-contact">
        <p className="subsection text-uppercase">Main contact</p>
        <div className="row">
          <div className="col-lg-4">
            <img
              style={{
                width: "100%",
              }}
              src={
                checkFileIfExist(
                  companyData.inviter.companies[0].files,
                  fileLabels.company.mainContact
                ) || brandLogo
              }
              alt="..."
            />
          </div>
          <div className="company-contact-list col-lg-7">
            <p className="company-contact-list-name">
              <span
                className={
                  companyData.inviter.companies[0].contacts[0].title
                    ? ""
                    : "d-none"
                }
              >
                {companyData.inviter.companies[0].contacts[0].title}
              </span>
              {companyData.inviter.companies[0].contacts[0].first_name}{" "}
              {companyData.inviter.companies[0].contacts[0].last_name}
            </p>

            <p
              className={
                "font-weight-light " +
                (companyData.inviter.companies[0].contacts[0].job_title
                  ? ""
                  : "d-none")
              }
            >
              {companyData.inviter.companies[0].contacts[0].job_title}
            </p>

            <p
              className={
                "company-contact-list-phone " +
                (companyData.inviter.companies[0].contacts[0].phone
                  ? ""
                  : "d-none")
              }
              style={{ fontSize: "14px" }}
            >
              <FontAwesomeIcon icon={faPhone} className="mr-1" />{" "}
              {companyData.inviter.companies[0].contacts[0].phone}
            </p>

            <p
              className={
                "company-contact-list-phone " +
                (companyData.inviter.companies[0].contacts[0].mobile
                  ? ""
                  : "d-none")
              }
              style={{ fontSize: "14px" }}
            >
              <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />{" "}
              {companyData.inviter.companies[0].contacts[0].mobile}
            </p>
            <a
              onClick={() =>
                (window.location.href = `mailto:${companyData.inviter.companies[0].contacts[0].email}`)
              }
            >
              <div></div>
              <p
                className={
                  "company-contact-list-email d-inline-flex " +
                  (companyData.inviter.companies[0].contacts[0].email
                    ? ""
                    : "d-none")
                }
                style={{ fontSize: "14px" }}
              >
                <FontAwesomeIcon icon={faEnvelope} className="mr-2 mt-1" />
                <span style={{ wordBreak: "break-word" }}>
                  {companyData.inviter.companies[0].contacts[0].email}
                </span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
