import React from "react";
import { Redirect, Route } from "react-router-dom";
import { store } from "../../../../store/store";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const checkAuth = () => {
    try {
      return store.getState().login.isLoggedIn;
    } catch (e) {
      console.log(e);
    }
  };

  const isLogged = checkAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `/`, state: { from: props.location } }} />
        )
      }
    />
  );
};
