import React, { useEffect } from "react";
import styles from "./index.module.scss";

export const ClickAwayListener = ({ onClickAway, children }) => {
  useEffect(() => {
    const handleClick = (event) => {
      if (!event.target.closest(`.${styles.modalContent}`)) {
        onClickAway();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onClickAway]);

  return <>{children}</>;
};

export const Modal = ({ children, isOpen, onClose }) => {
  useEffect(() => {
    const handleScroll = (e) => {
      if (isOpen) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (isOpen) {
      document.documentElement.classList.add(styles.modalOpen);
      document.documentElement.style.overflow = "hidden";
      document.documentElement.addEventListener("scroll", handleScroll, {
        passive: false,
      });
    } else {
      document.documentElement.classList.remove(styles.modalOpen);
      document.documentElement.style.overflow = "";
      document.documentElement.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.documentElement.classList.remove(styles.modalOpen);
      document.documentElement.style.overflow = "";
      document.documentElement.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <ClickAwayListener onClickAway={onClose}>
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </ClickAwayListener>
    </div>
  );
};
