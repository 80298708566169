import React from "react";
import { logoutUser } from "../../store/actions/loginAction";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faTimesCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import MembershipPackages from "../MembershipPackages";
import UserSettingsModal from "../UserSettingsModal";
import { userLevelColors } from "../../services/globalFunctions";
import customHistory from "../../services/history";
import { faSignOutAlt as faSignOut } from "@fortawesome/free-solid-svg-icons";
import WelcomeVideoModal from "./WelcomeVideoModal";
import { urls } from "../../services/router/utils/urls";

class JumpseatSlideInMenu extends React.Component {
  constructor(props) {
    super(props);
    this.jumpseatMenuRef = React.createRef();
    this.state = {
      show: this.props.showMenu,
      membershipModal: false,
      showContactUsModal: false,
      showUserSettingModal: false,
      showOverlay: false,
      navIcon: userLevelColors(this.props.group).icon,
    };
    this.onClickLogout = this.onClickLogout.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, {
      passive: true,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (
      this.jumpseatMenuRef &&
      this.jumpseatMenuRef.current.contains(e.target)
    ) {
      this.props.closeMenu();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //scroll to top when routes change
    if (this.props.location !== prevProps.location) {
      this.props.closeMenu();
      window.scrollTo(0, 0);
    }
    //show and hide overlay and disallow user scroll
    if (this.props.showMenu !== prevProps.showMenu) {
      if (this.props.showMenu) {
        // document.body.style.overflow = 'hidden';
        this.setState({ showOverlay: true });
      } else {
        // document.body.style.overflow = 'unset';
        setTimeout(() => {
          this.setState({ showOverlay: false });
        }, 200);
      }
      this.setState({ show: this.props.showMenu });
    }
    if (this.props.group !== prevProps.group) {
      this.setState({ navIcon: userLevelColors(this.props.group).icon });
    }
  }

  onClickLogout() {
    this.props.closeMenu();
    this.props.logoutUser();
    setTimeout(() => {
      customHistory.push("/");
    }, 500);
  }

  render() {
    return (
      <div>
        <div
          className={"overlay " + (this.state.showOverlay ? "" : "d-none")}
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            zIndex: "99",
            opacity: "0.5",
            background: "grey",
          }}
          ref={this.jumpseatMenuRef}
        ></div>
        <div
          className={
            "position-fixed bg-white col-lg-3 p-0 shadow-lg overflow-hidden slideInMenu-container"
          }
          style={{
            height: "100vh",
            zIndex: "999",
            right: "0",
            top: "0",
            width: this.state.show ? "27%" : "0px",
            minWidth: this.state.show ? "360px" : "0px",
            maxWidth: "320px",
            transition: "0.5s",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "1rem",
              left: "1rem",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              size={"lg"}
              icon={faTimesCircle}
              onClick={() => this.props.closeMenu()}
            />
          </span>
          {/* <img src={this.props.companyLogos[0].data ? `data:image/jpeg;base64,${this.props.companyLogos[0].data}` : 'https://via.placeholder.com/350x150'} style={{height: '68px', width: '290px'}} */}
          {/* className="p-2 ml-5 float-right" alt='...'/> */}
          <img
            src={require("../../assets/images/WorldRing.png")}
            style={{ height: "68px", width: "auto" }}
            className="p-2 ml-5 float-right mr-3"
            alt="..."
          />
          <nav
            className="navbar navbar-light w-100"
            style={{
              background: userLevelColors(this.props.group).bg,
              height: "60px",
              paddingTop: "0",
              paddingBottom: "0",
            }}
          >
            <div className="container">
              <span
                className="text-uppercase text-white w-100 text-right"
                style={{ fontSize: "18px" }}
              >
                <img
                  className="pr-4"
                  style={{ height: "35px", left: "140px", top: "5px" }}
                  src={
                    this.state.navIcon
                      ? require(`../../assets/userIcons/${this.state.navIcon}`)
                      : ""
                  }
                  alt={"..."}
                />
                <span
                  style={{
                    fontSize: "16px",
                    color: userLevelColors(this.props.group).font,
                  }}
                >
                  {userLevelColors(this.props.group).label}
                </span>
              </span>
            </div>
          </nav>
          <ul
            className="list-group text-right col-lg-12"
            style={{ paddingRight: "0" }}
          >
            <li
              className="list-group-item list-group-item-action bg-transparent border-0"
              style={{ padding: "15px" }}
            >
              <label className="text-dark">
                Welcome back, <strong>{this.props.fname}</strong>
              </label>
            </li>
          </ul>
          <div
            className="col-lg-12 mt-2 position-relative"
            style={{ height: "100vh", padding: "0" }}
          >
            <ul className="slidein-menu list-group text-right">
              <li className="list-group-item list-group-item-action bg-transparent border-0">
                <h5>
                  <Link
                    className="text-dark font-weight-light"
                    to={urls.jumpseat.newsFeed}
                  >
                    Newsfeed
                  </Link>
                </h5>
              </li>
              <li className="list-group-item list-group-item-action bg-transparent border-0">
                <h5>
                  <Link
                    className="text-dark font-weight-light"
                    to={urls.jumpseat.myMatches}
                  >
                    My Matches
                  </Link>
                </h5>
              </li>
              <li
                className={
                  "list-group-item list-group-item-action bg-transparent border-0 "
                }
              >
                <h5>
                  <span
                    className={"text-dark font-weight-light "}
                    onClick={() => this.setState({ membershipModal: true })}
                  >
                    Membership
                  </span>
                </h5>
              </li>
              <li
                className={
                  "list-group-item list-group-item-action bg-transparent border-0 " +
                  ((this.props.havePns ||
                    this.props.hsCodeSearching.length > 0 ||
                    this.props.offeringHsCode.length > 0) &&
                  !this.props.location.pathname.includes(
                    "jumpseat/registration"
                  )
                    ? ""
                    : "d-none")
                }
              >
                <h5>
                  <Link
                    className="text-dark font-weight-light"
                    to={urls.jumpseat.hsCodes}
                  >
                    My Searches/Offers
                  </Link>
                </h5>
              </li>
            </ul>
            <div
              className={
                "contact-card box " +
                (this.props.group === "admin" ? "d-none" : "")
              }
              style={{ display: "flex", flexWrap: "nowrap" }}
            >
              <div style={{ width: "50%", overflow: "hidden" }}>
                {this.props.level === "agent" ? (
                  <Link
                    to={"/company-profile/167"}
                    style={{ display: "block", width: "100%", height: "100%" }}
                  >
                    <img
                      src={require("../../assets/images/WorldRing.png")}
                      alt={"..."}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        objectPosition: "top",
                      }}
                    />
                  </Link>
                ) : (
                  <Link
                    to={
                      this.props.agentContact
                        ? `/company-profile/${this.props.agentContact.company_id}`
                        : 0
                    }
                    style={{ display: "block", width: "100%", height: "100%" }}
                  >
                    <img
                      src={
                        this.props.agentContact
                          ? this.props.agentContact.logo
                            ? this.props.agentContact.logo.data
                              ? `data:image/jpeg;base64,${this.props.agentContact.logo.data}`
                              : "https://via.placeholder.com/350x150"
                            : "https://via.placeholder.com/350x150"
                          : "https://via.placeholder.com/350x150"
                      }
                      alt={"..."}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        objectPosition: "top",
                      }}
                    />
                  </Link>
                )}
                {/*<img src={this.props.level === 'agent' ? require('../assets/images/WorldRing.png') : this.props.agentContact ? this.props.agentContact.logo ? this.props.agentContact.logo.data ? `data:image/jpeg;base64,${this.props.agentContact.logo.data }`: 'https://via.placeholder.com/350x150' : "" : "" } alt={'...'} />*/}
              </div>
              <div
                style={{ width: "50%", marginLeft: "20px", textAlign: "right" }}
              >
                <p className="mb-1">
                  <FontAwesomeIcon className="mr-3" icon={faPhoneAlt} />
                  {this.props.level === "agent"
                    ? "WorldRing Support"
                    : "Your Agent"}
                </p>
                <p
                  className={"font-weight-light "}
                  style={{ marginBottom: "10px" }}
                >
                  {this.props.level === "agent"
                    ? "+49 2163 888 14 60"
                    : this.props.agentContact
                    ? this.props.agentContact.phone
                    : ""}
                </p>
                <input
                  onClick={() =>
                    this.props.level === "agent"
                      ? (window.location.href = `mailto:info@worldring.org`)
                      : (window.location.href = `mailto:${this.props.agentContact.email}`)
                  }
                  type="button"
                  style={{
                    fontSize: "12px",
                    color: userLevelColors(this.props.group).buttonFont,
                    background: userLevelColors(this.props.group).buttonBg,
                    borderColor: userLevelColors(this.props.group).buttonFont,
                  }}
                  className={"btn btn-wlr-inverse "}
                  value="Contact Now"
                />
              </div>
            </div>
            <div
              className="position-absolute"
              style={{
                display: "flex",
                width: "100%",
                marginLeft: "0",
                marginRight: "0",
                paddingLeft: "5px",
                paddingRight: "5px",
                bottom: "230px",
              }}
            >
              <div className="col-lg-6 px-0 text-left">
                {/*<a className="text-dark" onClick={() => this.setState({showUserSettingModal: true})} style={{cursor: "pointer"}}><FontAwesomeIcon*/}
                {/*    className="ml-3" icon={faCog}/></a>*/}
                <a
                  className="text-dark col-lg-2 position-absolute pl-0"
                  onClick={() => this.setState({ welcomeModal: true })}
                  style={{ cursor: "pointer", marginLeft: "0px" }}
                >
                  <FontAwesomeIcon
                    className="ml-3"
                    icon={faQuestionCircle}
                    onClick={() => this.setState({ welcomeModal: true })}
                  />
                </a>
              </div>
              <div className="col-lg-6 text-right">
                <a
                  className="text-dark font-weight-light"
                  onClick={this.onClickLogout}
                  style={{ cursor: "pointer" }}
                >
                  Log-out
                  <FontAwesomeIcon className="ml-3" icon={faSignOut} />
                </a>
              </div>
            </div>
            <div
              className="w-100 position-absolute"
              style={{
                left: "0",
                height: "20px",
                background: userLevelColors(this.props.group).bg,
                bottom: "190px",
              }}
            ></div>
          </div>
          <MembershipPackages
            show={this.state.membershipModal}
            onHide={() => this.setState({ membershipModal: false })}
          />
          {/*<ContactUsForm*/}
          {/*    show={this.state.showContactUsModal}*/}
          {/*    onHide={() => this.setState({showContactUsModal: false})}*/}
          {/*/>*/}
          <UserSettingsModal
            show={this.state.showUserSettingModal}
            onHide={() => this.setState({ showUserSettingModal: false })}
          />
          <WelcomeVideoModal
            show={this.state.welcomeModal}
            onHide={() => this.setState({ welcomeModal: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let fname = state.login.fname;
  let companyIds = state.login.company_ids;
  let level = state.login.group;
  let agentContact = state.login.ag_contact;
  let companyLogos = state.login.company_logos;
  let group = state.login.level;
  let havePns = state.hsCodes.haveHsCode;
  let offeringHsCode = state.hsCodes.offering;
  let hsCodeSearching = state.hsCodes.searching;
  let companyLogo = state.login.company_logo;
  return {
    isLoggedIn,
    fname,
    companyIds,
    level,
    agentContact,
    companyLogo,
    companyLogos,
    group,
    havePns,
    offeringHsCode,
    hsCodeSearching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JumpseatSlideInMenu)
);
