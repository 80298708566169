export const urls = {
  dashboard: "/dashboard",
  commoditiesSearch: "/commodities-search",
  search: {
    id: "/search/:searchVal",
  },
  newsFeed: "/newsfeed",
  myCustomers: "/my-customers",
  myProducts: "/my-products",
  myClients: "/my-clients",
  myMatches: "/my-matches",
  clientMatches: "/client-matches",
  impressum: "/impressum",
  privacyPolicy: "/privacy-policy",
  salesPage: "/sales-page",
  salesSupport: "/sales-support",
  adminPage: "/admin-page",
  join: "/join",
  blog: {
    newsFeed: "/blog/newsfeed",
  },
  resetPassword: {
    token: "/reset-password/:token",
  },
  agent: {
    search: "/agent/search",
  },
  product: {
    detail: {
      id: "/product/detail/:id",
    },
  },
  jumpseat: {
    newsFeed: "/jumpseat/newsfeed",
    invite: "/jumpseat/invite",
    registration: "/jumpseat/registration",
    myMatches: "/jumpseat/my-matches",
    hsCodes: "/jumpseat/hs-codes",
  },
  companyProfile: {
    id: "/company-profile/:companyId",
    user: {
      id: "/company-profile/user/:companyId",
    },
  },
  membership: {
    payment: "/membership/payment",
    agreement: "/membership-agreement",
  },
};
