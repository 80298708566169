import React from "react";
import { Redirect, Route } from "react-router-dom";
import { store } from "../../../../store/store";
import { urls } from "../../utils/urls";
import { roles } from "../../../const";

export const JumpseatRoutes = ({ component: Component, ...rest }) => {
  const loginLevel = store.getState().login.level;
  const isLoggedIn = store.getState().login.isLoggedIn;
  const { jumpseat, accepted } = roles;

  const validateUser = () => {
    try {
      return (loginLevel === jumpseat || loginLevel === accepted) && isLoggedIn;
    } catch (e) {
      console.log(e);
    }
  };

  const isJumpseat = validateUser();
  const pathmane = isLoggedIn ? urls.newsFeed : "/";

  return (
    <Route
      {...rest}
      render={(props) =>
        isJumpseat ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathmane, state: { from: props.location } }} />
        )
      }
    />
  );
};
