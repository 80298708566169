import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faImage } from "@fortawesome/free-solid-svg-icons";
import {
  toBase64,
  uploadImage,
  userLevelColors,
} from "../../services/globalFunctions";
import { connect } from "react-redux";
import { countryList } from "../../services/const";
import { NotificationManager } from "react-notifications";
import { useFileUpload } from "../../utils/file";

class CompanyProfileDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyLogo: null,
      showError: false,
      showFileError: false,
      profilePicture: null,
      logoThumbnail: null,
      profileThumbnail: null,
    };
    this.handleCompanyProfileUpload =
      this.handleCompanyProfileUpload.bind(this);
    this.handleProfilePictureUpload =
      this.handleProfilePictureUpload.bind(this);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const companyData = this.props.companyData;
    const companyLogo =
      companyData && companyData.files
        ? companyData.files.find((f) => f.label === "company_logo")
        : null;
    const profilePicture =
      companyData && companyData.files
        ? companyData.files.find((f) => f.label === "profile_picture")
        : null;

    // Проверяем, изменилось ли состояние, прежде чем вызывать setState
    if (
      companyLogo &&
      `/storage/${companyLogo.path}` !== prevState.companyLogo
    ) {
      this.setState({
        companyLogo: `/storage/${companyLogo.path}`,
        logoThumbnail: `/storage/${companyLogo.thumb}`,
      });
    }

    if (
      profilePicture &&
      `/storage/${profilePicture.path}` !== prevState.profilePicture
    ) {
      this.setState({
        profilePicture: `/storage/${profilePicture.path}`,
        profileThumbnail: `/storage/${profilePicture.thumb}`,
      });
    }
  }
  async handleCompanyProfileUpload(e) {
    let file = e.target.files[0];
    if ((file.size / 1024 / 1024).toFixed(4) > 5) {
      NotificationManager.error("File size too large", "Error");
    } else {
      let logo = {
        mime: e.target.files[0].type,
        filename: e.target.files[0].name,
      };
      await toBase64(e.target.files[0]).then((res) => {
        logo.data = res;
        this.setState({ logoThumbnail: logo, showFileError: false });
        // this.props.onChangeHeroImage(logo);
      });
      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("file", file);
      formData.append("label", "company_logo");
      let image_id = await uploadImage(
        `companies/${this.props.companyId}`,
        formData
      );
      this.props.onChangeCompanyLogo(image_id);
      this.setState({ companyLogo: file, showFileError: false });
    }
  }

  async handleProfilePictureUpload(e) {
    let file = e.target.files[0];
    if ((file.size / 1024 / 1024).toFixed(4) > 5) {
      NotificationManager.error("File size too large", "Error");
    } else {
      let logo = {
        mime: e.target.files[0].type,
        filename: e.target.files[0].name,
      };
      await toBase64(e.target.files[0]).then((res) => {
        logo.data = res;
        this.setState({ profileThumbnail: logo, showFileError: false });
        // this.props.onChangeHeroImage(logo);
      });
      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("file", file);
      formData.append("label", "profile_picture");
      let image_id = await uploadImage(
        `/companies/${this.props.companyId}`,
        formData
      );
      this.props.onChangeCompanyContactPic(image_id);
      this.setState({ profilePicture: file, showFileError: false });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.showModal}
        size={"lg"}
        onHide={this.props.onHide}
        className="company-profile-modal"
      >
        <Modal.Header
          closeButton
          style={{ background: userLevelColors(this.props.level).bg }}
        >
          <h3 style={{ color: userLevelColors(this.props.level).font }}>
            Contact details
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4">
              <div className="file-upload-container-contact-person">
                <div className="file-upload-content">
                  <div className={this.state.logoThumbnail ? "d-none" : ""}>
                    {this.state.companyLogo ? (
                      <img
                        style={{ height: "65px" }}
                        src={this.state.companyLogo}
                        alt={"..."}
                      />
                    ) : (
                      <FontAwesomeIcon size={"3x"} icon={faImage} />
                    )}
                  </div>
                  <div className={this.state.logoThumbnail ? "" : "d-none"}>
                    {this.state.logoThumbnail ? (
                      <img
                        style={{ height: "65px" }}
                        src={`${this.state.logoThumbnail}`}
                        alt={"..."}
                      />
                    ) : (
                      <FontAwesomeIcon size={"3x"} icon={faImage} />
                    )}
                  </div>
                  <p className="title pt-3 mb-1">Drag & Drop</p>
                  <p className="sub-title mb-1">Your company logo goes here.</p>
                  <p className="sub-title mb-1">150x150px or 300x75px</p>
                  <p className="sub-title mb-1">.jpg or .png</p>
                  <p className="sub-title mb-1">max 5mb</p>
                  <br />
                </div>
                <input
                  style={{ padding: "175px 0px 12px 23%" }}
                  type="file"
                  id="file-upload"
                  className={
                    "choose-file-input " +
                    (this.state.companyLogo ? " " : "file-selected ")
                  }
                  onChange={this.handleCompanyProfileUpload}
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
            <div className="details-form col-lg-8">
              <div className="col-lg-12 pl-0">
                <h6>Company Details</h6>
              </div>
              <form>
                <div className="row">
                  <div className="form-group col-lg-12">
                    <input
                      type="text"
                      className={
                        "form-control " +
                        userLevelColors(this.props.level).className
                      }
                      onChange={this.props.onChangeCompanyName}
                      defaultValue={
                        this.props.companyData
                          ? this.props.companyData.name
                          : ""
                      }
                      placeholder="Company Name"
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                      type="text"
                      className={
                        "form-control " +
                        userLevelColors(this.props.level).className
                      }
                      onChange={this.props.onChangeCity}
                      defaultValue={
                        this.props.companyData
                          ? this.props.companyData.addresses[0].city_name
                          : ""
                      }
                      placeholder="City"
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <select
                      className={
                        "form-control " +
                        userLevelColors(this.props.level).className
                      }
                      onChange={this.props.onChangeCountry}
                      defaultValue={
                        this.props.companyData
                          ? this.props.companyData.addresses[0].country_name
                          : null
                      }
                    >
                      <option value={null}>Country</option>
                      {countryList.map((item, i) => {
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-8">
                    <input
                      type="text"
                      className={
                        "form-control " +
                        userLevelColors(this.props.level).className
                      }
                      onChange={this.props.onChangeStreet}
                      defaultValue={
                        this.props.companyData
                          ? this.props.companyData.addresses[0].street
                          : ""
                      }
                      placeholder="Street"
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <input
                      type="text"
                      className={
                        "form-control " +
                        userLevelColors(this.props.level).className
                      }
                      placeholder="Postal Code"
                      onChange={this.props.onChangePostCode}
                      defaultValue={
                        this.props.companyData
                          ? this.props.companyData.addresses[0].postal_code
                          : ""
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-4">
              <div className="file-upload-container-contact-person">
                <div className="file-upload-content">
                  <div className={this.state.profileThumbnail ? "d-none" : ""}>
                    {this.state.profilePicture ? (
                      <img
                        style={{ height: "65px" }}
                        src={this.state.profilePicture}
                        alt={"..."}
                      />
                    ) : (
                      <FontAwesomeIcon size={"3x"} icon={faImage} />
                    )}
                  </div>
                  <div className={this.state.profileThumbnail ? "" : "d-none"}>
                    {this.state.profileThumbnail ? (
                      <img
                        style={{ height: "65px" }}
                        src={`${this.state.profileThumbnail}`}
                        alt={"..."}
                      />
                    ) : (
                      <FontAwesomeIcon size={"3x"} icon={faImage} />
                    )}
                  </div>
                  <p className="title pt-2 mb-1">Drag & Drop</p>
                  <p className="sub-title mb-1">
                    Your profile picture goes here.
                  </p>
                  <p className="sub-title mb-1">150x150px</p>
                  <p className="sub-title mb-1">.jpg or .png</p>
                  <p className="sub-title mb-1">max 5mb</p>
                  <br />
                  &nbsp;
                  <small
                    className={
                      "text-danger " +
                      (this.state.showFileError ? "" : "d-none")
                    }
                  >
                    Select a file to import
                  </small>
                </div>
                <input
                  style={{ padding: "175px 0px 12px 23%" }}
                  type="file"
                  id="file-upload"
                  className={
                    "choose-file-input " +
                    (this.state.profilePicture ? "" : "file-selected")
                  }
                  onChange={this.handleProfilePictureUpload}
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
            <div className="col-lg-8 pr-0 row">
              <div className="col-lg-12">
                <h6>Network Contact</h6>
              </div>
              <div
                className={
                  "form-group col-lg-6 pr-0 " +
                  userLevelColors(this.props.level).className
                }
              >
                <select
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].gender_title
                      : null
                  }
                  onChange={this.props.onChangeContactGender}
                  className="form-control"
                >
                  <option disabled value={null}>
                    Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group col-lg-6 pr-0">
                <input
                  type="text"
                  className={
                    "form-control " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="First Name"
                  onChange={this.props.onChangeFirstName}
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].first_name
                      : ""
                  }
                />
              </div>
              <div className="form-group col-lg-6 pr-0">
                <select
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].title
                      : null
                  }
                  onChange={this.props.onChangeContactTitle}
                  className={
                    "form-control " +
                    userLevelColors(this.props.level).className
                  }
                >
                  <option disabled value={null}>
                    Title
                  </option>
                  <option value="Dr.">Dr.</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group col-lg-6 pr-0">
                <input
                  type="text"
                  className={
                    "form-control " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Last Name"
                  onChange={this.props.onChangeLastName}
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].last_name
                      : ""
                  }
                />
              </div>
              <div className="form-group col-lg-12 pr-0">
                <input
                  type="text"
                  className={
                    "form-control " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Job Title"
                  onChange={this.props.onChangeContactJobTitle}
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].job_title
                      : ""
                  }
                />
              </div>
            </div>
            <div className="col-lg-12 row pr-0">
              <div className="form-group col-lg-6 pr-0">
                <input
                  type="text"
                  className={
                    "form-control " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Phone Number"
                  onChange={this.props.onChangePhoneNo}
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].phone
                      : ""
                  }
                />
              </div>
              <div className="form-group col-lg-6 pr-0">
                <input
                  type="text"
                  className={
                    "form-control " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Mobile Number"
                  onChange={this.props.onChangeMobileNo}
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].mobile
                      : ""
                  }
                />
              </div>
              <div className="form-group col-lg-6 pr-0">
                <input
                  type="text"
                  className={
                    "form-control " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Fax Number"
                  onChange={this.props.onChangeFaxNo}
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].fax
                      : ""
                  }
                />
              </div>
              <div className="form-group col-lg-6 pr-0">
                <input
                  type="email"
                  className={
                    "form-control " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Email"
                  onChange={this.props.onChangeContactEmail}
                  defaultValue={
                    this.props.companyData
                      ? this.props.companyData.contacts[0].email
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.props.onHide}
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
          >
            Close
          </Button>
          <Button
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            onClick={this.props.onSubmit}
          >
            Save Details
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyProfileDetailsModal);
