import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { isResponseError } from "../../utils/axios";
import { formatDateToDDMMYYYY } from "../../services/globalFunctions";

export const useGetMyMatchesProducts = ({
  page,
  perPage,
  search,
  userLevel,
  cancel,
  commodity_type_id,
  country,
  sortList,
  refetch,
  setRefetch,
}) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    if (cancel && hasFetchedOnce.current) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const { data } = await axios.get(`commodities`, {
        params: {
          per_page: perPage,
          page,
          commodity_type_id,
          country,
          search,
          user_level_id: userLevel ? [userLevel] : "",
          is_pairings: true,
          is_only_pairings: true,
          my: true,
          sorting: sortList,
          columns_search: ["id"],
          with: [
            "user",
            "user.companies",
            "user.files",
            "user.role",
            "user.inviter",
            //"pairings",
          ],
        },
      });

      if (!isResponseError(data)) {
        const formattedData = data.data.map((commodity) => ({
          customer:
            commodity.user.name ||
            `${commodity.user.companies[0].name}, ${commodity.user.inviter.name}`,
          type: commodity.commodity_type.code,
          HSCode: commodity.product.code,
          "product Description": commodity.description,
          specification: commodity.specification,
          country: commodity.country,
          AIC: commodity.user.inviter ? commodity.user.inviter.name : "-",
          "Membership Level": commodity.user.user_level
            ? commodity.user.user_level.name
            : "-",
          ID: commodity.id,
          "online since": formatDateToDDMMYYYY(commodity.created_at),
          "date of match": commodity.pairing_at
            ? formatDateToDDMMYYYY(commodity.pairing_at)
            : "-",
          user: commodity.user,
          is_belongs_to_me: commodity.is_belongs_to_me,

          pairings: commodity.pairings.map((matchedCommodity) => ({
            customer:
              matchedCommodity.user.name ||
              `${matchedCommodity.user.email}, ${matchedCommodity.user.inviter.name}`,
            type: matchedCommodity.commodity_type.code,
            HSCode: matchedCommodity.product.code,
            "product Description": matchedCommodity.description,
            specification: matchedCommodity.specification,
            country: matchedCommodity.country,
            AIC: matchedCommodity.user.inviter
              ? matchedCommodity.user.inviter.name
              : "-",
            "Membership Level": matchedCommodity.user.user_level
              ? matchedCommodity.user.user_level.name
              : "-",
            ID: matchedCommodity.id,
            "online since": formatDateToDDMMYYYY(matchedCommodity.created_at),
            "date of match": formatDateToDDMMYYYY(commodity.pairing_at) || "-",
            user: matchedCommodity.user,
          })),
        }));

        setData(formattedData);
        setTotal(data.total);
        hasFetchedOnce.current = true;

        setIsLoading(false);
        setRefetch(false);
      }
    };

    fetchData();
  }, [
    page,
    perPage,
    search,
    userLevel,
    cancel,
    commodity_type_id,
    country,
    sortList,
    refetch,
    setRefetch,
  ]);

  return { data: data || [], isLoading, error, total: total || 0 };
};
