import React from "react";
import { connect } from "react-redux";
import { Accordion, Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ConfirmationModal from "../../components/CompanyProfileModals/ConfirmationModal";
import { NotificationManager } from "react-notifications";
import customHistory from "../../services/history";
import ProductModal from "../../components/CompanyProfileModals/ServicesModal/ProductModal";
import { urls } from "../../services/router/utils/urls";
import { isResponseError } from "../../utils/axios";

class JumpseatHsCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offeringVal: [],
      searchingVal: [],
      offeringCodes: [],
      searchingCodes: [],
      typeAdding: "",
      showHsCodeModal: false,
      showConfirmationModal: false,
      pnsId: null,
      allPns: [],
      limitReached: false,
      showProductModal: false,
    };
    this.getPnS = this.getPnS.bind(this);
    this.showBadges = this.showBadges.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.deletePnS = this.deletePnS.bind(this);
  }

  componentDidMount() {
    this.getPnS();
  }

  getPnS() {
    axios
      .get("/commodities?my=true")
      .then((ret) => {
        if (!isResponseError(ret)) {
          if (ret.data.data.length > 0) {
            let arrSearching = [];
            let arrOffering = [];
            ret.data.data.map((item) => {
              if (item.commodity_type.code === "search") {
                let obj = {
                  id: item.id,
                  item: `${item.product.code} - ${item.product.name}`,
                  target: item.target_group,
                  specification: item.specification,
                  pns_id: item.product_id,
                };
                arrSearching.push(obj);
              }
              if (item.commodity_type.code === "offer") {
                let obj = {
                  id: item.id,
                  item: `${item.product.code} - ${item.product.name}`,
                  target: item.target_group,
                  specification: item.specification,
                  pns_id: item.product_id,
                };
                this.setState({
                  [obj.item + "-target"]: obj.target,
                  [obj.item + "-specs"]: item.specification,
                });
                arrOffering.push(obj);
              }
              return null;
            });
            // if(ret.data.data.length >= 3) {
            //     this.setState({limitReached: true});
            // } else {
            //     this.setState({limitReached: false});
            // }
            this.setState(
              {
                searchingCodes: arrSearching,
                offeringCodes: arrOffering,
                allPns: ret.data.data,
              },
              () => {
                this.showBadges(true);
                this.showBadges(false);
              }
            );
          } else {
            this.setState({
              searchingCodes: [],
              offeringCodes: [],
              allPns: [],
              offeringVal: [],
              searchingVal: [],
            });
            customHistory.push("/jumpseat/registration");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onClickDelete(id) {
    this.setState({ pnsId: id, showConfirmationModal: true });
  }

  showBadges(offering) {
    let mapItem = offering
      ? this.state.offeringCodes
      : this.state.searchingCodes;
    let arr = mapItem.map((item, i) => {
      return (
        <Accordion
          defaultActiveKey="0"
          key={item.id}
          className="col-lg-12 pr-2 mt-3"
        >
          <Card>
            <Card.Header>
              <FontAwesomeIcon
                icon={faTrash}
                onClick={(e) => this.onClickDelete(item.id)}
                className="position-absolute"
                style={{ right: "10px", top: "22px", cursor: "pointer" }}
                size={"lg"}
              />
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                onSelect={(e) => console.log(e)}
              >
                <FontAwesomeIcon
                  icon={faArrowCircleUp}
                  className="toggle-icon"
                  size={"lg"}
                />
                <h4 key={i} className="ml-3">
                  <p
                    className="badge badge-secondary text-left"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {item.item}
                  </p>
                </h4>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <form className="row col-lg-12 mt-4">
                  {/*<div className="form-group col-lg-6 px-0" style={{border: '1px solid rgba(0, 0, 0, 0.1)'}}>*/}
                  {/*    <label className="mb-0 font-weight-bold">Target:</label>*/}
                  {/*    <textarea style={{resize: 'none', border: 'none'}} rows={6} value={this.state[item.item+'-target']} onChange={(e) => this.setState({[item.item+'-target']: e.target.value})} type="text" className="form-control w-100" placeholder="Target industry, customers, group..." />*/}
                  {/*</div>*/}
                  {/*<div className="form-group col-lg-6 px-0" style={{border: '1px solid rgba(0, 0, 0, 0.1)'}}>*/}
                  {/*    <label className="mb-0 font-weight-bold">Specifications:</label>*/}
                  {/*    <textarea style={{resize: 'none', border: 'none'}} rows={6} value={this.state[item.item+'-specs']} onChange={(e) => this.setState({[item.item+'-specs']: e.target.value})} type="text" className="form-control w-100" placeholder="Specifications" />*/}
                  {/*</div>*/}
                  <div
                    className="form-group col-lg-6"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      height: "220px",
                    }}
                  >
                    <label>
                      <strong>Target:</strong>
                    </label>
                    <p className="mb-0">{item.target}</p>
                    {/*<input value={this.state[item.item+'-target']} onChange={(e) => this.setState({[item.item+'-target']: e.target.value})} type="text" className="form-control" placeholder="Target industry, customers, group..." />*/}
                  </div>
                  <div
                    className="form-group col-lg-6 pr-0"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      height: "220px",
                    }}
                  >
                    <label>
                      <strong>Specification:</strong>
                    </label>
                    <p className="mb-0">{item.specification}</p>
                    {/*<input value={this.state[item.item+'-specs']} onChange={(e) => this.setState({[item.item+'-specs']: e.target.value})} type="text" className="form-control" placeholder="Specifications" />*/}
                  </div>
                </form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    });

    offering
      ? this.setState({ offeringVal: arr })
      : this.setState({ searchingVal: arr });
  }

  onCloseModal() {
    this.setState({ showHsCodeModal: false }, () => this.getPnS());
  }

  onClickSave() {
    NotificationManager.success("HS codes updated", "Success");
    customHistory.push(urls.jumpseat.myMatches);
  }

  async deletePnS() {
    await axios.delete(`/commodities/${this.state.pnsId}`).then((res) => {
      // console.log(res);
      if (!isResponseError(res)) {
        NotificationManager.success("Entry deleted", "Success");
        this.getPnS();
        this.setState({ showConfirmationModal: false });
      } else {
        NotificationManager.error("Something went wrong. Try again.", "Error");
        this.setState({ showConfirmationModal: false });
      }
    });
  }

  render() {
    const { showProductModal, typeAdding } = this.state;
    const { companyIds } = this.props;
    return (
      <div className="page-container JumpseatInviteContainer">
        <div className={"page-header mb-5 "}></div>
        <div className="container step-wizard" style={{ width: "950px" }}>
          <div className="d-flex align-items-center">
            <h3 className="my-3 col-lg-6 px-0 ">Offering</h3>
            <div className="col-lg-6 px-0 text-right">
              <input
                type="button"
                className="btn btn-wlr text-uppercase"
                style={{ fontWeight: "600" }}
                value="Add new"
                onClick={() =>
                  this.state.limitReached
                    ? NotificationManager.error(
                        "You have reached the max limit.",
                        "Error"
                      )
                    : this.setState({
                        typeAdding: "offer",
                        showProductModal: true,
                      })
                }
              />
            </div>
          </div>
          <div className="row">
            {this.state.offeringVal.length > 0
              ? [this.state.offeringVal]
              : void 0}
          </div>
          <hr className="px-5 my-5" />
          <div className="d-flex align-items-center">
            <h3 className="my-3 col-lg-6 px-0 ">Searching</h3>
            <div className="col-lg-6 px-0 text-right">
              <input
                type="button"
                className="btn btn-wlr text-uppercase"
                style={{ fontWeight: "600" }}
                value="Add new"
                onClick={() =>
                  this.state.limitReached
                    ? NotificationManager.error(
                        "You have reached the max limit.",
                        "Error"
                      )
                    : this.setState({
                        typeAdding: "search",
                        showProductModal: true,
                      })
                }
              />
            </div>
          </div>
          <div className="row">
            {this.state.searchingVal.length > 0
              ? [this.state.searchingVal]
              : void 0}
          </div>
          <div className="col-lg-12 px-0 text-right mt-5">
            <input
              type="button"
              className="btn btn-dark text-uppercase"
              value="Save HS Code Selection"
              style={{ fontSize: "20px" }}
              onClick={this.onClickSave}
            />
          </div>
        </div>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          onHide={() => this.setState({ showConfirmationModal: false })}
          onClickDelete={this.deletePnS}
          itemToDelete={"this item"}
        />
        {showProductModal && (
          <ProductModal
            show={showProductModal}
            onHide={() => this.setState({ showProductModal: false })}
            companyId={companyIds[0]}
            getPnS={this.getPnS}
            typeAdding={typeAdding}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let companyIds = state.login.company_ids;
  let level = state.login.level;
  return {
    token,
    companyIds,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JumpseatHsCodes);
