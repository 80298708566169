import React from 'react'
import { Modal } from "../Modal"
import { Button } from '../Button'

export const ConfirmModal = ({ isOpen, onClose, postConfirmAction }) => {
    return <Modal isOpen={isOpen} onClose={onClose}>
        <div style={{
            background: 'white', padding: 20, display: 'flex', flexDirection: 'column',
            alignItems: 'center', justifyContent: 'space-between', gap: 30

        }}>
            <span>
                Are you sure you want to continue?
            </span>
            <div style={{ display: 'flex', gap: 15 }}>
                <Button label='confirm' onClick={postConfirmAction} isSmall />
                <Button label='decline' onClick={onClose} type='secondary' isSmall />
            </div>
        </div>
    </Modal>
}