import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import customHistory from "../../services/history";
import { authenticateUser } from "../../store/actions/loginAction";
import { JsEncrypt, ButtonSpinner } from "../../services/globalFunctions";
import PublicContactUs from "../../components/PublicContactUs";
import axios from "axios";
import { base_url } from "../../services/const";
import { NotificationManager } from "react-notifications";
import { urls } from "../../services/router/utils/urls";

class JumpSeatLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showError: false,
      showContactUsModal: false,
      showLoading: false,
      emailSent: false,
    };
    this.onClickLoginHandler = this.onClickLoginHandler.bind(this);
    this.onClickForgotPassword = this.onClickForgotPassword.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isLoggedIn !== prevProps.isLoggedIn &&
      this.props.isLoggedIn
    ) {
      customHistory.push(urls.jumpseat.registration);
    }
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      customHistory.push(urls.jumpseat.registration);
    }
  }

  onClickLoginHandler(e) {
    e.preventDefault();
    // customHistory.push('/jumpseat/registration');
    let enc = new JsEncrypt();
    let params = new URLSearchParams();
    params.append("email", this.state.username);
    params.append("pw", enc.encode(this.state.password));
    this.setState({ showError: false, showLoading: true });
    axios
      .request({
        method: "post",
        url: `${base_url}/login`,
        data: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          this.setState({ showLoading: false }, () =>
            this.props.authenticateUser(res.data.data)
          );
        } else {
          this.setState({ showError: true, showLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onClickForgotPassword() {
    if (this.state.username) {
      let params = new URLSearchParams();
      params.append("email", this.state.username);
      axios
        .request({
          method: "post",
          url: `${base_url}/user/requestPassword`,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((ret) => {
          if (ret.status === 200 && ret.data.status === 200) {
            this.setState({ emailSent: true, showError: false });
          } else {
            NotificationManager.error(
              "Something went wrong. Please try again.",
              "Error"
            );
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Something went wrong. Please try again.",
            "Error"
          );
        });
    } else {
      NotificationManager.error("Enter you email", "Error");
    }
  }

  render() {
    return (
      <div className="jumpseat-login-container page-container">
        <div className={"page-header mb-5"}></div>
        <div className="container text-center">
          <h1 className="wlr-title">Welcome to the WorldRing - Marketplace</h1>
          <p
            className="text-left mt-5 w-50"
            style={{ marginLeft: "50%", transform: "translate(-50%)" }}
          >
            The WorldRing - Marketplace is a product of the WorldRing business
            network.
            <br />
            It is the central place, where businesses, people and opportunities
            get linked with each other.
            <br />
            <br />
            If you do not yet have a login and if you are interested to learn
            more about that unique network and the possibilities that it offers
            to you, please{" "}
            <span
              className="text-uppercase text-primary"
              onClick={() => this.setState({ showContactUsModal: true })}
            >
              <u>GET IN TOUCH</u>
            </span>{" "}
            with us.
            <br />
            <br />
            If you already have your login details, please proceed with login
            below.
          </p>
          <hr className="my-5 col-9" />
          <form
            className="p-4 card text-left bg-light border-0"
            style={{ width: "25rem" }}
            onSubmit={this.onClickLoginHandler}
          >
            <label>JUMPSEAT LOGIN</label>
            <div className="floating-fields">
              <div className="field">
                <input
                  type="text"
                  defaultValue={this.state.username}
                  onChange={(e) => {
                    this.setState({ username: e.target.value });
                  }}
                  name="username"
                  id="username"
                  placeholder="John Doe"
                  required
                />
                <label htmlFor="username">Username</label>
              </div>
              <div className="field">
                <input
                  type="password"
                  defaultValue={this.state.password}
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  name="password"
                  id="password"
                  placeholder="Password"
                  required
                />
                <label htmlFor="password">Password</label>
              </div>
            </div>
            <div className="form-check mt-3 mb-4">
              <input
                required={true}
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                onClick={(e) =>
                  e.target.checked
                    ? this.setState({ checkboxVal: e.target.checked })
                    : this.setState({ checkboxVal: e.target.checked })
                }
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                You hereby agree to our{" "}
                <Link to={urls.membership.agreement}>Terms</Link> and that you
                have read our{" "}
                <Link to={urls.privacyPolicy}>privacy policy</Link>, including
                our Cookie use.
              </label>
            </div>
            <small
              className={
                "text-success " + (this.state.emailSent ? "" : "d-none")
              }
            >
              An email has been sent to {this.state.username} address to reset
              the password. Please check.
            </small>
            <small
              className={
                "text-danger " + (this.state.showError ? "" : "d-none")
              }
            >
              Username / password combination invalid
            </small>
            <div className="row">
              <div className="col-6 mt-2 text-left">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={this.onClickForgotPassword}
                  className="forgot-password text-dark"
                >
                  Forgot Password?
                </span>
              </div>
              <div className="col-6 text-right">
                <button
                  disabled={this.state.showLoading}
                  type="submit"
                  className="btn btn-wlr"
                >
                  <span className={this.state.showLoading ? "d-none" : ""}>
                    Login
                  </span>
                  <ButtonSpinner showLoading={this.state.showLoading} />
                </button>
              </div>
            </div>
          </form>
        </div>
        <PublicContactUs
          show={this.state.showContactUsModal}
          onHide={() => this.setState({ showContactUsModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  return {
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JumpSeatLogin);
