import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { urls } from "../services/router/utils/urls";

class Footer extends React.Component {
    render() {
        return (
            <div className="footer-container text-center p-5 position-relative">
                <img src={require('../assets/images/WorldRing_Footer_Logo.png')} alt={'...'} />
                <h2 className="mt-5">WorldRing the business network</h2>
                <p className="mt-4">an organisation of WestRing international trading & consulting GmbH</p>
                <p className="mt-4">Am Herrenlandpark 9</p>
                <p className="mt-4">41379 Brüggen</p>
                <p className="mt-4">Germany</p>
                <p className="mt-4">Tel.: +49 2163 888 14 60</p>
                <p className="mt-4">Mobile: +49 163 334 50 17</p>
                <p className="mt-4">E-Mail: <span>info[at]worldring.org</span></p>
                <p>Copyright 2020 WorldRing GmbH | All Rights Reserved | <Link className="text-white" to={urls.privacyPolicy}>Privacy Policy</Link> | <Link className="text-white" to={urls.impressum}>Imprint</Link></p>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let level = state.login.level;
    return {
        token, level
    }
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
