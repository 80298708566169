import React from "react";
import { connect } from "react-redux";
import { ButtonSpinner, JsEncrypt } from "../services/globalFunctions";
import customHistory from "../services/history";
import axios from "axios";
import { base_url } from "../services/const";
import { NotificationManager } from "react-notifications";
import { authenticateUser } from "../store/actions/loginAction";
import { urls } from "../services/router/utils/urls";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      repeatPassword: "",
      showLoading: false,
      showError: false,
    };
    this.onClickResetPassword = this.onClickResetPassword.bind(this);
    this.checkPasswordMatch = this.checkPasswordMatch.bind(this);
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      customHistory.push(urls.newsFeed);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isLoggedIn !== prevProps.isLoggedIn &&
      this.props.isLoggedIn
    ) {
      customHistory.push(`/company-profile/user/${this.props.companyIds[0]}`);
    }
  }

  onClickResetPassword(e) {
    e.preventDefault();
    this.setState({ showLoading: true });
    let enc = new JsEncrypt();
    let params = new URLSearchParams();
    params.append("password", enc.encode(this.state.newPassword));
    params.append("password_repeat", enc.encode(this.state.repeatPassword));
    params.append("token", this.props.match.params.token);
    axios
      .request({
        method: "post",
        url: `${base_url}/user/resetPassword`,
        data: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        this.setState({ showLoading: false });
        if (res.status === 200 && res.data.status === 200) {
          NotificationManager.success("Password reset successful", "Success");
          this.props.authenticateUser(res.data.data);
        } else {
          NotificationManager.error(
            "Something went wrong. Please try again.",
            "Error"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  checkPasswordMatch() {
    if (this.state.newPassword !== this.state.repeatPassword) {
      this.setState({ showError: true });
    } else {
      this.setState({ showError: false });
    }
  }

  render() {
    return (
      <div className="ResetPasswordContainer page-container">
        <div className={"page-header mb-5"}></div>
        <div className="container text-center">
          <h1 className="wlr-title">Reset Password</h1>
          <p
            className="text-left mt-5 w-50"
            style={{ marginLeft: "50%", transform: "translate(-50%)" }}
          >
            You requested to reset your password. Please change your password
            below. You will be logged in directly. If you have any requests or
            concerns, please contact us immediately.
          </p>
          <hr className="my-5 col-9" />
          <form
            className="p-4 card text-left bg-light border-0"
            style={{ width: "25rem" }}
            onSubmit={this.onClickResetPassword}
          >
            <label>RESET PASSWORD</label>
            <div className="floating-fields">
              <div className="field">
                <input
                  type="password"
                  defaultValue={this.state.newPassword}
                  onChange={(e) => {
                    this.setState({ newPassword: e.target.value }, () =>
                      this.checkPasswordMatch()
                    );
                  }}
                  name="new-password"
                  id="new-password"
                  placeholder="New Password"
                  style={{ zIndex: "1" }}
                  required
                />
                <label htmlFor="username">New Password</label>
              </div>
              <div className="field">
                <input
                  type="password"
                  defaultValue={this.state.repeatPassword}
                  onChange={(e) => {
                    this.setState({ repeatPassword: e.target.value }, () =>
                      this.checkPasswordMatch()
                    );
                  }}
                  name="repeat-password"
                  id="repeat-password"
                  placeholder="Repeat Password"
                  style={{ zIndex: "1" }}
                  required
                />
                <label htmlFor="password">Repeat Password</label>
              </div>
            </div>
            <small
              className={
                "text-danger " + (this.state.showError ? "" : "d-none")
              }
            >
              Passwords don't match
            </small>
            <div className="row">
              <div className="col-lg-12 text-right">
                <button
                  type="submit"
                  disabled={this.state.showLoading || this.state.showError}
                  className="btn btn-wlr w-100"
                >
                  <span className={this.state.showLoading ? "d-none" : ""}>
                    Reset Password
                  </span>
                  <ButtonSpinner showLoading={this.state.showLoading} />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let companyIds = state.login.company_ids;
  return {
    isLoggedIn,
    companyIds,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
