import { useState, useEffect, useRef } from "react";
import axios from "axios";

export const useGetCompanies = ({
  page,
  perPage,
  search,
  userLevel,
  cancel,
  status,
  sortList,
  refetch,
  setRefetch,
}) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    if (cancel && hasFetchedOnce.current) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(`users`, {
          params: {
            per_page: perPage,
            page,
            search,
            my: true,
            is_register: 1,
            sorting: sortList,
            user_level_id: userLevel ? [userLevel] : "",
            user_role: 3,
            status,
            columns_search: ["id"],
          },
        });

        const formattedData = data.data.map((customer) => {
          return {
            "Company name": customer.companies.length
              ? customer.companies[0].name
              : "-",
            "User name": customer.name,
            "Sent to Email": customer.email,
            "Sent on": "-",
            Status: "-",
            "time to expiry": "-",
            ID: customer.id,
          };
        });

        setData(formattedData);
        setTotal(data.total);
        hasFetchedOnce.current = true;
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
        setRefetch(false);
      }
    };

    fetchData();
  }, [
    page,
    perPage,
    search,
    userLevel,
    cancel,
    status,
    sortList,
    refetch,
    setRefetch,
  ]);

  return { data: data || [], isLoading, error, total: total | 0 };
};
