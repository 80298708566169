import React from "react";
import { connect } from "react-redux";
import { userLevelColors } from "../services/globalFunctions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { filePathToGlobal } from "../utils/file";

class MyProductsListItem extends React.Component {
  render() {
    const { level, item, editPnS, hideButton } = this.props;
    let firstImage = item.files
      ? item.files
          .map((f) => filePathToGlobal(f))
          .find((f) => f.label === "image")
      : null;
    return (
      <div
        className={"searchresult w-100 row p-3 m-0 mb-4 position-relative box "}
        id={item.id}
      >
        <div
          style={{ right: "0px", top: "0px" }}
          className={"hsCodes position-absolute "}
        >
          <div
            style={{ background: userLevelColors(level).bg }}
            className="mt-2"
          >
            <span className="text-light p-1 text-capitalize">
              {item.commodity_type.code}
            </span>
          </div>
        </div>
        <div
          className="col-lg-3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              height: "115px",
              width: "auto",
              objectFit: "contain",
              maxWidth: "175px",
            }}
            src={
              firstImage ? firstImage.path : require("../assets/brand/logo.svg")
            }
            className=""
            alt="..."
          />
        </div>
        <div className="col-lg-8 ml-3">
          <h4
            style={{
              maxWidth: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            className="font-weight-light"
          >
            {item.name}
          </h4>
          <p
            className="mt-0"
            style={{
              maxWidth: "100%",
              fontSize: "14px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {item.description || ""}
          </p>
          <div className="row mt-4">
            <div className="col-lg-3 properties">
              <label className="w-100">HS Code</label>
              <label>{item.product.code}</label>
            </div>
            <div className="col-lg-3 properties">
              <label className="w-100">Orign</label>
              <label>{item.country || ""}</label>
            </div>
            {!hideButton && (
              <div className="col-lg-6 px-0 text-right">
                <Button
                  onClick={() => editPnS(item)}
                  style={{
                    background: userLevelColors(level).buttonBg,
                    color: userLevelColors(level).buttonFont,
                    borderColor: userLevelColors(level).buttonFont,
                  }}
                >
                  Edit Product Entry
                </Button>
              </div>
            )}
            {hideButton && (
              <div className="col-lg-5 px-0 text-right">
                <Link
                  to={`/product/detail/${item.id}`}
                  className="btn btn-wlr"
                  style={{
                    background: userLevelColors(level).buttonBg,
                    color: userLevelColors(level).buttonFont,
                    borderColor: userLevelColors(level).buttonFont,
                  }}
                >
                  Product details
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let level = state.login.level;
  return {
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyProductsListItem);
