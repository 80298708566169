import React, { useState } from "react";
import styles from "./index.module.scss";

const arrowDownloadIconBlack = require("../../../../../../assets/icons/donwloadArrowBlack.svg");
const arrowDownloadIconWhite = require("../../../../../../assets/icons/downloadArrowWhite.svg");

const copyIconBlack = require("../../../../../../assets/icons/copyIconBlack.svg");
const copyIconWhite = require("../../../../../../assets/icons/copyIconWhite.svg");

export const DownloadButton = ({ text, isCopy, onClick }) => {
  const [icon, setIcon] = useState(
    isCopy ? copyIconBlack : arrowDownloadIconBlack
  );

  return (
    <button
      onClick={onClick}
      className={styles.container}
      onMouseOver={() =>
        setIcon(isCopy ? copyIconWhite : arrowDownloadIconWhite)
      }
      onMouseOut={() =>
        setIcon(isCopy ? copyIconBlack : arrowDownloadIconBlack)
      }
    >
      <img src={icon} alt="download arrow" />
      <div>{text}</div>
    </button>
  );
};
