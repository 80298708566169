//login action
export const LOGIN_AUTHENTICATION = "LOGIN_AUTHENTICATION";
export const CLEAR_PROPS = "CLEAR_PROPS";
export const UPDATE_USER_LEVEL = "UPDATE_USER_LEVEL";
export const UPDATE_NEW_MATCHES_COUNT = "UPDATE_NEW_MATCHES_COUNT";

//hs codes
export const HS_CODE_OFFERING = "HS_CODE_OFFERING";
export const HS_CODE_SEARCHING = "HS_CODE_SEARCHING";
export const HAVE_HS_CODE = "HAVE_HS_CODE";

//breadcrumbs
export const UPDATE_ROUTES = "UPDATE_ROUTES";
export const CLEAR_ROUTES = "CLEAR_ROUTES";
export const ACTIVE_PRODUCT = "ACTIVE_PRODUCT";
export const ACTIVE_COMPANY = "ACTIVE_COMPANY";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
