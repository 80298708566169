import React, { useState } from "react";
import styles from "./index.module.scss";
import { ClickAwayListener } from "../../../../components/ClickAwayListener";
import { userLevelFilter } from "../../../CommoditiesSearch";

const icon = require("../../../../assets/icons/bottomTriangle.svg");

export const SelectInput = ({ placeHolder, value, options, setValue }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const levelMap = (() => {
    const result = {};

    userLevelFilter.forEach((level) => {
      result[level.value] = level.label;
    });

    return result;
  })();

  const getLabelByValue = (value) => levelMap[value];

  return (
    <ClickAwayListener
      style={{ width: "100%" }}
      onClickAway={() => setIsPopupOpen(false)}
    >
      <div className={styles.container}>
        <div
          className={styles.label}
          onClick={() => setIsPopupOpen((state) => !state)}
        >
          <img src={icon} alt="bottom triangle arrow" />
          <div style={{ color: value ? "#636a72" : "" }}>
            {getLabelByValue(value) || placeHolder}
          </div>
        </div>
        {isPopupOpen && (
          <div className={styles.popupContainer}>
            {options.map((option) => (
              <div
                key={option}
                onClick={() => {
                  setValue(option.value);
                  setIsPopupOpen(false);
                }}
                className={styles.popupContainerOption}
              >
                {getLabelByValue(option.value)}
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
