import React from "react";
import { threeDots } from "../../../assets/icons";
import styles from "./OptionsButton.module.scss";
import { Dropdown } from "antd";

export const OptionsButton = ({ children, clickContainer }) => {
  return (
    <Dropdown
      className={styles.container}
      trigger={["hover"]}
      overlayStyle={{ transition: "none", animationDuration: "0s !important" }}
      dropdownRender={() => {
        return <div className={styles.contentContainer}>{children}</div>;
      }}
    >
      <a onClick={(e) => e.preventDefault()} style={{ textDecoration: "none" }}>
        {clickContainer ? (
          clickContainer
        ) : (
          <img
            src={threeDots}
            alt="threeDots"
            style={{ cursor: "pointer", width: 22, height: 25 }}
          />
        )}
      </a>
    </Dropdown>
  );
};
