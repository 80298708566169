import React from "react";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { queryClient } from "./queryClient";
import { store, persistor } from "../../store/store";
import { PersistGate } from "redux-persist/lib/integration/react";

export const AppProvider = ({ children }) => (
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </PersistGate>
  </Provider>
);
