import React from "react";
import CookieConsent from "react-cookie-consent";

export const CookieAgreement = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="OK"
      cookieName="WorldRingCookies"
      style={{}}
      buttonStyle={{
        marginBottom: "10px",
        color: "#fff",
        fontSize: "14px",
        background: "transparent",
        border: "2px solid white",
        padding: "5px 25px",
        fontWeight: "700",
        marginTop: "10px",
      }}
      expires={150}
      disableStyles={true}
    >
      <span style={{ fontSize: "14px", marginBottom: "0px" }}>
        This website uses cookies and third party services.{" "}
        <a
          style={{ color: "#bfbfbf", textDecoration: "none" }}
          href="https://worldring.org/en/cookie-policy-eu/"
          target={"_blank"}
        >
          View cookie policy
        </a>
        .
      </span>
    </CookieConsent>
  );
};
