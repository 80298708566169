import { axiosAuth } from "../../../utils/axios";

export const getMyMatches = async (token) => {
  console.log(token)
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const responce = await axiosAuth("/company/mymatches/", {
    headers,
  });

  return responce;
};
