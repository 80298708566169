import React, { useEffect } from "react";
import { useFileUpload } from "./utils/file";

const FileUploadHandler =  ({ config, onUploadComplete, ...inputProps }) => {
    const { uploadFile, file, objectURL } = useFileUpload(config);

    useEffect(() => {
        if (file) {
            onUploadComplete(file, objectURL);
        }
    }, [file, objectURL, onUploadComplete]);

    const handleFileChange =(event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          uploadFile(selectedFile)
        }
    };

    return (
        <input
            type="file"
            onChange={handleFileChange}
            {...inputProps}
        />
    );
};

export default FileUploadHandler;