import React, {
  useEffect,
  useState,
  // useRef
} from "react";
import styles from "./index.module.scss";
import { crossIcon } from "../../assets/icons";
import { ClickAwayListener } from "../ClickAwayListener";
import { getBottomSections, getMenuSections } from "./utils";
import { SectionPopup } from "./components/SectionPopup";
import axios from "axios";
import { isResponseError } from "../../utils/axios";

const dashboardNotificationCount = 1;

export const AgentsMenu = ({ isOpen, closeMenu, ...props }) => {
  // const menuRef = useRef(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await axios.get("/me");
      if (!isResponseError(data)) {
        setUserData(data.data);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      if (isOpen) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (isOpen) {
      document.documentElement.classList.add(styles.modalOpen);
      document.documentElement.style.overflow = "hidden";
      document.documentElement.addEventListener("scroll", handleScroll, {
        passive: false,
      });
    } else {
      document.documentElement.classList.remove(styles.modalOpen);
      document.documentElement.style.overflow = "";
      document.documentElement.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.documentElement.classList.remove(styles.modalOpen);
      document.documentElement.style.overflow = "";
      document.documentElement.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  const handleClickAway = (e) => {
    // if (!menuRef.current.contains(e.target)) {
    closeMenu();
    // }
  };

  const sections = getMenuSections(userData);

  const bottomSections = getBottomSections(userData);

  return (
    <>
      {isOpen && userData && (
        <div className={styles.modalWrapper}>
          {/* <div ref={menuRef} className={styles.contactWr} onClick={()=>console.log('contact WR')}>
            Contact wr
          </div> */}
          <div className={styles.modalBackdrop}></div>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.container}>
              <div>
                <div className={styles.closeButtonWrapper}>
                  <img src={crossIcon} alt="cross icon" onClick={closeMenu} />
                </div>
                <div className={styles.sections}>
                  {sections.map(({ title, subSections }) => (
                    <SectionPopup
                      key={title}
                      title={title}
                      subSections={subSections}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.iconsContainer}>
                {bottomSections.map(({ title, icon, link, onClick }) => {
                  const isLogoutButton = title === "Logout";
                  return isLogoutButton ? (
                    <div onClick={onClick}>
                      <div>{title}</div>
                      <div className={styles.iconWrapper}>
                        <img src={icon} alt="profile icon" />
                      </div>
                    </div>
                  ) : (
                    <a key={title} href={link}>
                      {/* {title === "My Dashboard" && <div style={{
                        background: '#FB9202',
                        color: 'white',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '0 2px',
                        height: 24,
                        minWidth: 24,
                        fontSize: 14
                      }}>
                        {dashboardNotificationCount}
                      </div>} */}
                      <div>{title}</div>
                      <div className={styles.iconWrapper}>
                        <img src={icon} alt="profile icon" />
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};
