import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { base_url } from "../services/const";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-select";
import {
  reorderSalesPage,
  translateData,
  userLevelColors,
} from "../services/globalFunctions";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./../services/date-euro";
import customHistory from "../services/history";
require("pdfmake/build/pdfmake.js");
require("../assets/fonts/vfs_fonts.js");

class SalesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: [],
      tableJs: null,
      showFallback: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.initializeDT = this.initializeDT.bind(this);
    this.onClickTableItem = this.onClickTableItem.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios
      .request({
        method: "get",
        url: `${base_url}/sales/productlist/all/all/all`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.status === 200) {
          if (ret.data.data.length > 0) {
            const { level } = this.props;
            let data = ret.data.data;
            let sortedData = reorderSalesPage(data, level);
            const newSortedData = sortedData.map((row) => {
              return {
                ...row,
                details: row.details.slice(0, 49),
              };
            });

            this.initializeDT(newSortedData);
          } else {
            this.setState({ showFallback: true });
          }
        } else {
          this.setState({ showFallback: true });
        }
      })
      .catch((err) => {
        this.setState({ showFallback: true });
        console.log(err);
      });
  }

  initializeDT(ret) {
    let cols = ["ID"];
    let data = [];
    let prevArr = Array.from(new Set(ret.map(JSON.stringify))).map(JSON.parse);
    let arr = prevArr.map((i) => {
      return {
        ...i,
        membership:
          i.membership === "trading_company" ? "Trading Company" : i.membership,
      };
    });
    arr.map((item, j) => {
      let dArr = [];
      dArr.push(j);
      for (let i in item) {
        if (moment(item[i], "YYYY-MM-DD HH:mm:ss", true).isValid()) {
          let date = moment(item[i]).format("DD/MM/YYYY");
          dArr.push(date);
        } else {
          dArr.push(translateData(item[i]));
        }
      }
      data.push(dArr);
    });
    for (let i in arr[0]) {
      let k = i.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
      cols.push(k.replace(/_/g, " "));
    }
    this.setState({ cols: cols });
    const { level } = this.props;
    let _ = this;
    $(document).ready(function () {
      if ($.fn.DataTable.isDataTable("#table_id")) {
        $("#table_id").DataTable().destroy();
      }
      let table = $("#table_id").DataTable({
        data: data,
        dom: "Bfrtip",
        select: {
          style: "multi",
        },
        // "order": [[ 3, "desc" ]],
        buttons: [
          "copyHtml5",
          "csvHtml5",
          {
            extend: "pdfHtml5",
            orientation: "landscape",
          },
        ],
        columnDefs: [
          {
            targets: [0],
            visible: false,
            searchable: false,
          },
          { targets: 8, type: "date-euro" },
          {
            targets: 10,
            data: null,
            render: function (data, type, full, meta) {
              let d = data[10];
              return (
                '<a class="product-button" data-id="' +
                d +
                '" target="_blank">' +
                d +
                "</a>"
              );
            },
          },
        ],
      });
      $("#table_id_wrapper tbody").on("click", ".product-button", function (e) {
        _.onClickTableItem(e.target.getAttribute("data-id"));
      });
      $("#table_id_wrapper input").addClass(userLevelColors(level).className);
      $("#table_id_wrapper label").css("display", "inline-flex");
      $("#table_id_filter label").css("display", "none");
      $(".dt-buttons").addClass("mb-3");
      $("#tableSearch").keyup(function (e) {
        table.search($(this).val()).draw();
      });
    });
  }

  onClickTableItem(d) {
    const { token } = this.props;
    axios
      .request({
        method: "get",
        url: `${base_url}/commodities/product/details/${d}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((ret) => {
        if (ret.data.status === 200 && ret.data.data) {
          customHistory.push(`/product/detail/${ret.data.data.pns_id}`);
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { level } = this.props;
    return (
      <div
        className={
          "mt-5 SalesPageContainer " +
          (level !== "trading_company" ? "position-absolute" : "")
        }
        style={{ left: 0, right: 0 }}
      >
        <div className={"mb-5  " + (level !== "trading_company" ? "container" : "")}>
          <p className="m-0">
            Get an overview about all active searches and offers on the
            WorldRing Marketplace.
          </p>
        </div>
        <div className="position-relative">
          <div
            className="col-lg-4 position-absolute"
            style={{ right: 0, zIndex: 1 }}
          >
            <div className="form-group w-100 position-relative">
              <input
                id="tableSearch"
                type="text"
                className={
                  "form-control nav-search border-top-0 border-right-0 border-left-0 " +
                  userLevelColors(level).className
                }
                placeholder="Search for active offers/searches..."
              />
              <button
                style={{ top: "5px", right: 0 }}
                type="button"
                className="navbar-search-btn bg-transparent border-0 position-absolute"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>

          <table
            id="table_id"
            className="table table-striped table-bordered adminTable"
          >
            <thead>
              <tr>
                {this.state.cols.map((item, i) => {
                  return (
                    <th key={i}>
                      {item === "Details" ? "Product Description" : item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="adminTable-body"></tbody>
          </table>
          <div
            className={
              "searchresult w-100 p-3 mb-4 position-relative col-lg-12 " +
              (this.state.showFallback ? "" : "d-none")
            }
            style={{ marginTop: "80px" }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="col-lg-2"></div>
              <div className="col-lg-6 ml-3 text-center">
                No clients have added searches/offers yet <br />
                Motivate your clients to add products to WorldRing!
              </div>
              <div className="col-lg-2 ml-3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SalesPage);
