import React from "react";
import { OptionsButton } from "../OptionsButton";
import { useHistory } from "react-router";
import axios from "axios";
import { isTempOrJumpSeat } from "../../consts";

export const ThreeDotsContainer = ({ isInvitation, isProductPage, row }) => {
  const history = useHistory();
  return (
    <div
      style={{
        visibility: isInvitation || isProductPage ? "visible" : "hidden",
        height: 74,
        display: "flex",
        alignItems: "center",
      }}
    >
      <OptionsButton>
        {isProductPage && (
          <div>
            <div
              style={{
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/product/detail/${row.original.ID}`)}
            >
              View Entry/Match
            </div>
            {row.original.user.inviter && (
              <div
                style={{
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  marginTop: 5,
                }}
                onClick={() => {
                  window.location.href = `mailto:${
                    isTempOrJumpSeat(row.original.user)
                      ? row.original.user.inviter.email
                      : row.origin.user.email
                  }`;
                }}
              >
                {isTempOrJumpSeat(row.original.user)
                  ? `Contact Agent/Company`
                  : `Contact Customer`}
              </div>
            )}
          </div>
        )}
        {isInvitation && (
          <div>
            <div
              style={{
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() =>
                axios.put(`/invites/${row.original.ID}`, {
                  resend: true,
                })
              }
            >
              Resend Invitation
            </div>
          </div>
        )}
      </OptionsButton>
    </div>
  );
};
