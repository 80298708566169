import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import {authenticateUser, logoutUser} from "../store/actions/loginAction";

class AuthLoader {
    constructor(userClass, errorTitle = 'Authorization Error') {
        this.userClass = userClass;
        this.errorTitle = errorTitle;
        // this.loader = axios.create({ baseURL:window.location.origin });
        this.loader = axios.create();
    }
    static formatValidationErrors(errors) {
        if (typeof errors === 'string') {
            return errors
        }
        let errorMessages = []
        for (let field in errors) {
            if (errors.hasOwnProperty(field)) {
                let fieldErrors = errors[field].join(' ')
                errorMessages.push(`${field}: ${fieldErrors}`)
            }
        }
        return errorMessages.join(' ')
    }
    static isError( response,isSilent = false){
        const res = response.data
        const isError = !!response.data.error
        if(isError && typeof res.error === 'string' && res.error.includes('Unauthenticated.')){
            if(!isSilent)
                NotificationManager.error('Invalid credentials')
            logoutUser()
            return true
        }
        if(!isError)
            return false
        const errorMessage = AuthLoader.formatValidationErrors(res.error)
        if(isError) {
            console.error('AuthLoader.Error:', errorMessage)
            if(!isSilent)
                NotificationManager.error(errorMessage)
        }
        return !!errorMessage

    }
    async loginByCookie(credential) {
        try {
            await this.loader.get('/sanctum/csrf-cookie');
            const response = await this.loader.post('login', credential);
            if (!AuthLoader.isError(response)) {
                const responseUser  = await this.loader.get('/api/v1/app/me');
                if (!AuthLoader.isError(responseUser)) {
                    if(response.data.token)
                        sessionStorage.setItem('wp-token', response.data.token);
                    sessionStorage.setItem('wp-email', responseUser.data.email);
                    authenticateUser(responseUser.data)
                    NotificationManager.success(response.data.message, `Hello ${responseUser.data.first_name}`);
                    window.location.reload()
                    return responseUser.data

                }
            }
        } catch (error) {
            NotificationManager.error(error.message);
            throw error;
        }
    }

    async logout() {
        try {
            const response = await this.loader.post('logout',{},{baseURL:'/'});
            if (!AuthLoader.isError(response)) {
                sessionStorage.setItem('wp-token', '');
                sessionStorage.setItem('wp-email', '');
                logoutUser()
                axios.defaults.baseURL = '/'
                window.location.reload()
                return response.data;
            }
        } catch (error) {
            NotificationManager.error(error.message);
            throw error;
        }
    }
}

export default AuthLoader;
