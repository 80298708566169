import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export const ClickAwayListener = ({ children, onClickAway, ...props }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickAway(event);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClickAway]);

  return <div {...props} ref={wrapperRef}>{children}</div>;
};

ClickAwayListener.propTypes = {
  children: PropTypes.node.isRequired,
  onClickAway: PropTypes.func.isRequired,
};
