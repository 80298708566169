import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import styles from "./index.module.scss";
import { crossIcon, plusCircleIcon, triangleBottom } from "../../assets/icons";
import { Button } from "../Button";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { countryList } from "../../services/const";
import { isResponseError } from "../../utils/axios";

export const Input = ({
  value,
  setValue,
  placeholder,
  label,
  children,
  inputPaddingLeft,
  disabled,
  errors = [],
  isNumberOfOffices,
}) => {
  return (
    <div className={styles.inputContainer}>
      {label && <div className={styles.inputLabel}>{label}</div>}
      <div className={styles.inputWrapper}>
        {children}
        {isNumberOfOffices ? (
          <div className={styles.input}>{value || placeholder}</div>
        ) : (
          <input
            disabled={disabled}
            style={{
              paddingLeft: inputPaddingLeft,
            }}
            className={styles.input}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            placeholder={placeholder}
          />
        )}
      </div>
      {errors.length > 0 && (
        <div style={{ color: "red", marginTop: "-10px" }}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}
    </div>
  );
};

const CountrySelectInput = ({
  value,
  setValue,
  placeholder,
  label,
  children,
  inputPaddingLeft,
  disabled,
  options,
  errors = [],
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return (
    <div className={styles.inputContainer} style={{ cursor: "pointer" }}>
      {label && <div className={styles.inputLabel}>{label}</div>}
      <div className={styles.inputWrapper}>
        {children}
        <div
          style={{
            paddingLeft: inputPaddingLeft,
          }}
          onClick={() => setIsPopupOpen((state) => !state)}
          className={styles.input}
        >
          {value || placeholder}
        </div>
      </div>
      {isPopupOpen && (
        <div className={styles.countryPopup}>
          {options.map(({ name }) => {
            return (
              <div
                key={name}
                className={styles.inputContainer}
                onClick={() => {
                  setValue(name);
                  setIsPopupOpen(false);
                }}
              >
                <div className={styles.inputWrapper}>
                  <div className={styles.input}>{name}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {errors.length > 0 && (
        <div style={{ color: "red", marginTop: "-10px" }}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export const TextArea = ({
  value,
  setValue,
  placeholder,
  label,
  disabled,
  errors = [],
}) => {
  return (
    <div className={styles.inputContainer} style={{ width: "100%" }}>
      {label && <div className={styles.inputLabel}>{label}</div>}
      <div className={styles.inputWrapper} style={{ height: 130 }}>
        <textarea
          disabled={disabled}
          style={{
            resize: "none",
          }}
          className={styles.input}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          placeholder={placeholder}
        />
      </div>
      {errors.length > 0 && (
        <div style={{ color: "red", marginTop: "-10px" }}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}
    </div>
  );
};

const Select = ({ options, isRight, setValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState("");

  return (
    <div
      style={{ right: isRight ? 0 : "" }}
      className={styles.selectContainer}
      onClick={() => setIsOpen((isOpen) => !isOpen)}
    >
      <div className={styles.select}>
        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {label}
        </span>
        <span>
          <img src={triangleBottom} alt="triangleBottomIcon" />
        </span>
      </div>
      <div className={styles.selectPopupContainer}>
        {isOpen &&
          options.map(({ value, content }) => {
            return (
              <div
                onClick={() => {
                  setValue(value);
                  setLabel(content);
                }}
                className={styles.selectPopup}
                key={value}
              >
                {content}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const CreateSuperAgentUserModal = ({
  isOpen,
  onClose,
  setIsUserSuccessfullyCreatedModalOpen,
  setCreatedUSerIinvitationLink,
  setCreatedUserId,
}) => {
  let num = 0;

  const numberOfOfficesOptions = Array.from({ length: 111 }, () => {
    let result = {
      content: num,
      value: num,
    };
    num++;
    return result;
  });

  const initialSate = {
    countryValue: countryList[0],
    numberOfOfficesValue: "",
    companyNameValue: "",
  };

  const [formGroups, setFormGroups] = useState([initialSate]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [contactDetailValue, setContactDetailValue] = useState("");

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setFormGroups([initialSate]);
    setErrors([]);
    setContactDetailValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const addNewCompanyGroup = () => {
    setFormGroups((groups) => [...groups, initialSate]);
  };

  const updateFormGroup = (index, field, value) => {
    setFormGroups((groups) => {
      const updatedGroups = [...groups];
      updatedGroups[index][field] = value;
      return updatedGroups;
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateInputs = () => {
    let validationErrors = [];

    if (!firstName) {
      validationErrors["firstName"] = "First name is required.";
    }

    if (!lastName) {
      validationErrors["lastName"] = "Last name is required.";
    }

    if (!email || !validateEmail(email)) {
      validationErrors["email"] = "Valid email is required.";
    }

    formGroups.forEach((group, index) => {
      const { countryValue, numberOfOfficesValue, companyNameValue } = group;

      let groupErrors = {};

      if (!countryValue) {
        groupErrors["countryValue"] = "Country is required.";
      }

      if (numberOfOfficesValue < 1) {
        groupErrors["numberOfOfficesValue"] =
          "Number of offices should be 1 or more.";
      }

      if (!companyNameValue || typeof companyNameValue !== "string") {
        groupErrors["companyNameValue"] = "Company name is required.";
      }

      if (Object.keys(groupErrors).length > 0) {
        validationErrors[index] = groupErrors;
      }
    });

    setErrors(validationErrors);
    return validationErrors.length === 0;
  };

  const fetchCreateUser = () => {
    if (!validateInputs()) return;

    axios
      .post("invites", {
        companies: formGroups.map((item) => {
          return {
            country: item.countryValue,
            offices_count: item.numberOfOfficesValue,
            company_name: item.companyNameValue,
          };
        }),
        contact_details: contactDetailValue,
        first_name: firstName,
        last_name: lastName,
        email: email,
        is_distributor: true,
      })
      .then((response) => {
        if (!isResponseError(response)) {
          const { id, link } = response.data;
          setCreatedUSerIinvitationLink(link);
          setCreatedUserId(id);
          setIsUserSuccessfullyCreatedModalOpen(true);
        } else {
          throw new Error("123");
        }
      })
      .catch((error) => NotificationManager.error("Failed to create user"))
      .finally((res) => onClose());
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.closeIconWrapper} onClick={onClose}>
          <img src={crossIcon} alt="close icon" />
        </div>
        <div>
          <h2 style={{ fontSize: 25 }}>Add New Superagent User</h2>
        </div>

        <div className={styles.rowContainer}>
          <Input
            placeholder={"First Name"}
            value={firstName}
            setValue={setFirstName}
            label={`First Name:`}
            errors={errors.firstName ? [errors.firstName] : []}
          />

          <Input
            placeholder={"Last Name"}
            value={lastName}
            setValue={setLastName}
            label={`Last Name:`}
            errors={errors.lastName ? [errors.lastName] : []}
          />
        </div>

        <div className={styles.rowContainer} style={{ marginBottom: 20 }}>
          <Input
            placeholder={"Email"}
            value={email}
            setValue={setEmail}
            label={`Email:`}
            errors={errors.email ? [errors.email] : []}
          />
        </div>

        {/* <div className={styles.rowContainer} style={{ marginBottom: 20 }}>
          <TextArea
            placeholder={"Write something..."}
            value={contactDetailValue}
            setValue={setContactDetailValue}
            label={"Contact detail:"}
            errors={errors.contactDetail ? [errors.contactDetail] : []}
          />
        </div> */}

        <div
          className={styles.inputGropus}
          style={{
            // maxHeight: "50vh",
            // overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 32,
          }}
        >
          {formGroups.map(
            (
              { countryValue, numberOfOfficesValue, companyNameValue },
              index
            ) => {
              const groupErrors = errors[index] || {};

              return (
                <div
                  className={styles.inputsGroup}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                  key={index}
                >
                  <div className={styles.rowContainer}>
                    <CountrySelectInput
                      disabled
                      value={countryValue}
                      label={"Country:"}
                      errors={
                        groupErrors.countryValue
                          ? [groupErrors.countryValue]
                          : []
                      }
                      options={countryList.map((country) => ({
                        name: country,
                      }))}
                      setValue={(newValue) =>
                        updateFormGroup(index, "countryValue", newValue)
                      }
                    ></CountrySelectInput>
                    <Input
                      disabled
                      placeholder={"Select the desired quantity"}
                      value={numberOfOfficesValue}
                      label={"Number of offices in country:"}
                      errors={
                        groupErrors.numberOfOfficesValue
                          ? [groupErrors.numberOfOfficesValue]
                          : []
                      }
                      isNumberOfOffices
                    >
                      <Select
                        options={numberOfOfficesOptions}
                        value={numberOfOfficesValue}
                        setValue={(newValue) =>
                          updateFormGroup(
                            index,
                            "numberOfOfficesValue",
                            newValue
                          )
                        }
                        isRight
                      />
                    </Input>
                  </div>
                  <div className={styles.rowContainer}>
                    <Input
                      placeholder={"Company Name"}
                      value={companyNameValue}
                      setValue={(newValue) =>
                        updateFormGroup(index, "companyNameValue", newValue)
                      }
                      label={"Company Name:"}
                      errors={
                        groupErrors.companyNameValue
                          ? [groupErrors.companyNameValue]
                          : []
                      }
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>

        <img
          style={{ width: 30, height: 30, cursor: "pointer" }}
          src={plusCircleIcon}
          alt="plusCircleIcon"
          onClick={addNewCompanyGroup}
        />

        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button width={298} label={"Save"} onClick={fetchCreateUser} />
        </div>
      </div>
    </Modal>
  );
};
