import React, { useState } from "react";
import styles from "./index.module.scss";
import {
  menuBottomArrow,
  menuBottomArrowBlack,
} from "../../../../assets/icons";
import { store } from "../../../../store/store";

const newsfeedNotificationCount = 4

export const SectionPopup = ({ title, subSections }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSection = () => setIsOpen((state) => !state);

  const userId = store.getState().login.company_ids[0];


  return (
    <div className={styles.container}>
      <div className={styles.topContainer} onClick={toggleSection}>
        {isOpen ? (
          <img
            className={styles.bottomArrow}
            src={menuBottomArrowBlack}
            alt="arrow-bottom icon"
          />
        ) : (
          <img
            className={styles.topArrow}
            src={menuBottomArrow}
            alt="arrow-bottom icon"
          />
        )}

        <h2 style={{ fontWeight: isOpen ? 500 : 400 }}>{title}</h2>
      </div>
      {isOpen && (
        <div className={styles.subCategoriesContainer}>
          {subSections.map(({ title, link }) => {
            const isCompanyProfileSection = title === "Company Profile";
            const isNewsFeed = title === 'Newsfeed'
            return (
              <a
                // add id for dynamic route
                href={isCompanyProfileSection ? link + userId : link}
                key={title}
                onClick={() => console.log('newsfeedNotificationCount has been clicked')}
              >
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  {isNewsFeed && <div style={{
                    background: '#FB9202',
                    color: 'white',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 2px',
                    height: 24,
                    minWidth: 24,
                    fontSize: 14
                  }}>
                    {newsfeedNotificationCount}
                  </div>}
                  <span>
                    {title}
                  </span>
                </div>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};
