import React from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle as faCheck, faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import customHistory from "../services/history";
import {paymentButtons} from "../services/globalFunctions";
import {NotificationManager} from "react-notifications";
import { urls } from "../services/router/utils/urls";

class MembershipPackages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false,
        };
        this.onClickPay = this.onClickPay.bind(this);
        this.toggleMore = this.toggleMore.bind(this);
    }

    onClickPay(amount, tariff) {
        // ,  or
        if (amount === null) {
            NotificationManager.success('This is your existing plan');
            return true;
        }
        if (amount === 1) {
            window.location.href = `mailto:info@worldring.org?subject=Downgrade to ${tariff}`;
            return true;
        }
        customHistory.push({
            pathname: urls.membership.payment,
            state: {amount: amount, tariff: tariff}
        });
        this.props.onHide();
    }

    toggleMore() {
        this.setState({showMore: !this.state.showMore});
    }

    render() {
        let {level} = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="packages-modal"
            >
                <Modal.Header className={"membership-header border-0 pb-0 " + (this.props.level === 'jumpseat' ? 'membership-header-yellow ' : 'membership-header-white')}>
                    {
                        this.props.level === 'jumpseat' ?
                            <React.Fragment>
                                <p className="mb-0">Register now and enjoy full range of services of the network.</p>
                                <p className="font-weight-bold">Pay only after the trial period expires and <u>only if
                                    you enjoyed the offer.</u></p>
                                <p className="mt-4">Choose the membership level that fits to your needs below</p>
                            </React.Fragment> : <React.Fragment><h3 className="text-uppercase text-center w-100">
                                UP TO 4 months free{" "}
                            </h3>
                                <p className="join-text text-center w-100 pr-4">
                                    Join now - select your membership tier below
                                </p></React.Fragment>

                    }


                </Modal.Header>
                <Modal.Body className="mb-5">
                    <div className="w-100 row packages">
                        <div className="card col py-3 ml-4 economy-package">
                            <div className="row ml-0">
                                <img
                                    style={{height: "41.3px"}}
                                    src={require("./../assets/images/economy-wh.svg")}
                                    alt="..."
                                />
                                <h3 className="text-uppercase font-weight-bold ml-3">
                                    Economy
                                </h3>
                            </div>
                            <small className="text-uppercase mt-2 mb-4 font-weight-bold">
                                the economic way to business success
                            </small>
                            <div className="row ml-0 mt-1">
                                <div className="col-lg-5 pl-0">
                                    <p className="mb-0">Annual Membership:</p>
                                </div>
                                <div className="col">
                                    <p className="mb-0">
                                        <strong>750,- EUR</strong>
                                    </p>
                                    <p className="font-weight-light font-italic">
                                        (comes with a 3 month free-trial period)
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`benefits-container ${this.state.showMore ? 'list-expanded' : 'list-restricted'}`}>
                                <p>Benefits:</p>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Own log in and full access to the WorldRing Member Area
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free upload of Product details to the WorldRing Marketplace
                                        to search potential buyers within the WorldRing Network
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free upload of query for business contacts to the WorldRing
                                        Marketplace to search for potential suppliers
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Number of maximum Marketplace uploads at the same time: 5
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Full access to the company information of your match
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Full access to the international WorldRing-Agent, Consultant
                                        & Customer contact.
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Invitation to the annual meeting of all WorldRing Members
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Coverage by the WorldRing Security Fund* (WSF) of WorldRing up to
                                        5.000,- EUR against fraud and insolvency of WorldRing Members.

                                    </p>
                                </div>
                            </div>
                            <div className="showmore showmore-economy" onClick={this.toggleMore}>
                                {
                                    this.state.showMore ?
                                        <span><FontAwesomeIcon className="mr-2" icon={faAngleUp}/>Hide details</span>
                                        :
                                        <span><FontAwesomeIcon className="mr-2" icon={faAngleDown}/>Learn more</span>
                                }
                            </div>
                            <input
                                className="btn btn-dark position-absolute"
                                type="button"
                                value={paymentButtons(this.props.level).first.label}
                                onClick={this.onClickPay
                                    .bind(null, paymentButtons(this.props.level).first.value, 'economy')}
                            />
                        </div>
                        <div className="card col py-3 border-0 ml-4 business-package">
                            <div className="row ml-0">
                                {/*<img src={require('../assets/brand/Most_POPULAR_ribbon.png')} alt={'...'} />*/}
                                <img
                                    style={{height: "41.3px"}}
                                    src={require("./../assets/images/business-wh.svg")}
                                    alt="..."
                                />
                                <h3 className="text-uppercase ml-3 font-weight-bold">
                                    Business
                                </h3>
                            </div>
                            <small className="text-uppercase mt-2 font-weight-bold">
                                the comfortable way for regular and frequent new businesses
                            </small>
                            <div className="row ml-0 mt-2">
                                <div className="col-lg-5 pl-0">
                                    <p className="mb-0">Annual Membership:</p>
                                </div>
                                <div className="col">
                                    <p className="mb-0">
                                        <strong>1500,- EUR</strong>
                                    </p>
                                    <p className="font-weight-light font-italic">
                                        (comes with a 3 month free-trial period)
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`benefits-container ${this.state.showMore ? 'list-expanded' : 'list-restricted'}`}>
                                <p>Benefits:</p>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Own log in and full access to the WorldRing Member Area
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free upload of Product details to the WorldRing Marketplace
                                        to search potential buyers within the WorldRing Network
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free upload of query for business contacts to the WorldRing
                                        Marketplace to search for potential suppliers
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Number of maximum Marketplace uploads at the same time: 10
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Full access to the company information of your match
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Full access to the international WorldRing-Agent, Consultant
                                        & Customer contact.
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free advertising pack I (Company Logo in side bar on marketplace)
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Invitation to the annual meeting of all WorldRing Members
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free credit information about your potential business
                                        partner**
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Coverage by the WorldRing Security Fund* (WSF) of WorldRing up to
                                        10.000,- EUR against fraud and insolvency of WorldRing Members.

                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Use of the WorldRing supply chain management software (if
                                        offered by your designated agent)
                                    </p>
                                </div>
                            </div>
                            <div className="showmore showmore-businessclass" onClick={this.toggleMore}>
                                {
                                    this.state.showMore ?
                                        <span><FontAwesomeIcon className="mr-2" icon={faAngleUp}/>Hide details</span>
                                        :
                                        <span><FontAwesomeIcon className="mr-2" icon={faAngleDown}/>Learn more</span>
                                }
                            </div>

                            <input
                                className="btn btn-dark position-absolute"
                                type="button"
                                value={paymentButtons(this.props.level).second.label}
                                onClick={this.onClickPay.bind(null, paymentButtons(this.props.level).second.value, 'business')}
                            />
                        </div>
                        <div className="card col py-3 border-0 ml-4 first-class-package">
                            <div className="row ml-0">
                                <img
                                    style={{height: "41.3px"}}
                                    src={require("./../assets/images/first-wh.svg")}
                                    alt="..."
                                />
                                <h3 className="text-uppercase ml-3 font-weight-bold">
                                    First Class
                                </h3>
                            </div>
                            <small className="text-uppercase mt-2 font-weight-bold">
                                lay back and enjoy the service. the best way to develop your
                                worldwide business with peace of mind
                            </small>
                            <div className="row ml-0 mt-2">
                                <div className="col-lg-5 pl-0">
                                    <p className="mb-0">Annual Membership:</p>
                                </div>
                                <div className="col">
                                    <p className="mb-0">
                                        <strong>2.500,- EUR</strong>
                                    </p>
                                    <p className="font-weight-light font-italic">
                                        (comes with a 4 month free-trial period)
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`benefits-container ${this.state.showMore ? 'list-expanded' : 'list-restricted'}`}>
                                <p>Benefits:</p>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Own log in and full access to the WorldRing Member Area
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free upload of Product details to the WorldRing Marketplace
                                        to search potential buyers within the WorldRing Network
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free upload of query for business contacts to the WorldRing
                                        Marketplace to search for potential suppliers
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Number of maximum Marketplace uploads at the same time: unlimited
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Full access to the company information of your match
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Full access to the international WorldRing-Agent, Consultant
                                        & Customer contact.
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free advertising pack II (company logo displayed and top search results)
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Invitation to the annual meeting of all WorldRing Members
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Free credit information about your potential business
                                        partner**
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Coverage by the WorldRing Security Fund* (WSF) of WorldRing up to
                                        25.000,- EUR against fraud and insolvency of WorldRing Members.
                                    </p>
                                </div>
                                <div className="position-relative py-1">
                                    <FontAwesomeIcon
                                        style={{top: "8px"}}
                                        className="mr-2 position-absolute"
                                        icon={faCheck}
                                    />
                                    <p className="mb-0 ml-4 d-inline-block">
                                        Use of the WorldRing supply chain management software (if
                                        offered by your designated agent)
                                    </p>
                                </div>
                            </div>
                            <div className="showmore showmore-firstclass" onClick={this.toggleMore}>
                                {
                                    this.state.showMore ?
                                        <span><FontAwesomeIcon className="mr-2" icon={faAngleUp}/>Hide details</span>
                                        :
                                        <span><FontAwesomeIcon className="mr-2" icon={faAngleDown}/>Learn more</span>
                                }
                            </div>
                            <input
                                type="button"
                                className="btn btn-dark position-absolute"
                                onClick={this.onClickPay.bind(null, paymentButtons(this.props.level).third.value, 'first_class')}
                                value={paymentButtons(this.props.level).third.label}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    let level = state.login.level;
    return {
        level
    };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipPackages);
