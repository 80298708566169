import { getFilteredRows } from "./downloads";

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

const generateCopiedText = (selectedRows) => {
  let copiedText = "WorldRing Marketplace\n\n";
  const headers = Object.keys(selectedRows[0]);

  copiedText += headers.join("\t") + "\n";

  selectedRows.forEach((row) => {
    const rowValues = headers.map((header) => row[header]);
    copiedText += rowValues.join("\t") + "\n";
  });

  return copiedText;
};

export const handleCopyClick = (selectedRows) => {
  if (selectedRows.length === 0) return;

  const filteredRows = getFilteredRows(selectedRows);

  if (filteredRows.length === 0) return;
  const copiedText = generateCopiedText(filteredRows);
  copyToClipboard(copiedText);
};
