import React from "react";
import styles from "./index.module.scss";

export const Button = ({ label, onClick, type = "primary", isSmall = false, width }) => {
  return (
    <button
      className={`${styles.container} ${type === "primary" ? styles.primary : styles.secondary
        }`}
      onClick={onClick}
      style={{
        height: isSmall ? 35 : 60,
        fontSize: isSmall ? 16 : 22,
        width: width
      }}
    >
      {label}
    </button>
  );
};
