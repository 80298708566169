import React from "react";
import { Link } from "react-router-dom";
import MembershipPackages from "./MembershipPackages";
import { userLevelColors } from "../services/globalFunctions";
import axios from "axios";
import { base_url } from "../services/const";
import SuccessModalMyMatchesJumpseat from "./SuccessModalMyMatchesJumpseat";
import { NotificationManager } from "react-notifications";
import { urls } from "../services/router/utils/urls";

export const SearchResultListItem = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [showSuccessModal, setSuccessModal] = React.useState(false);

  let count = 0;
  let count2 = 0;

  const countryNames = props.addresses.map((address) => address.country_name);
  const uniqueCountryNames = [...new Set(countryNames)];

  const citiyNames = props.addresses.map((address) => address.city_name);
  const uniqueCityNames = [...new Set(citiyNames)];

  const contacts = props.contacts.map((contact) => {
    if (contact.first_name && contact.last_name) {
      return contact.first_name + " " + contact.last_name;
    }
    return contact.first_name;
  });
  const uniqueContactsNames = [...new Set(contacts)];

  return (
    <div
      className={
        "searchresult w-100 row p-3 m-0 mb-4 position-relative box " +
        props.classAg
      }
      id={props.id}
    >
      <div id="overlay" className={props.showOverlay ? "" : "d-none"}></div>
      <div
        style={{ zIndex: "10", right: "10px", top: "5px" }}
        className={
          "hsCodes position-absolute " +
          (props.showOverlay
            ? props.product_and_services
              ? props.product_and_services.length > 0
                ? ""
                : "d-none"
              : "d-none"
            : "d-none")
        }
      >
        {props.product_and_services
          ? props.product_and_services.map((item, i) => {
              if (item.type.includes("match")) {
                if (item.hsCode && item.hsDescription && count < 2) {
                  count++;
                  return (
                    <div key={i} className="mt-2">
                      <span className="text-light bg-secondary p-1">
                        {item.hsCode +
                          " - " +
                          item.hsDescription.substring(0, 8) +
                          "..."}
                      </span>
                    </div>
                  );
                }
                if (count === 2 && item.hsCode && item.hsDescription) {
                  count++;
                  return (
                    <div key={i} className="mt-2">
                      <span className="text-light bg-secondary p-1">
                        Show more...
                      </span>
                    </div>
                  );
                }
              }
            })
          : void 0}
      </div>
      <div
        style={{ zIndex: "10", right: "0px", top: "0px" }}
        className={
          "hsCodes position-absolute " +
          (props.status && props.showStatus ? "" : "d-none")
        }
      >
        {props.status ? (
          <div
            style={{ background: userLevelColors(props.status).bg }}
            className="mt-2"
          >
            <span className="text-light p-1">
              {userLevelColors(props.status).label}
            </span>
          </div>
        ) : (
          void 0
        )}
      </div>
      <div
        style={{ zIndex: "10", right: "10px", top: "5px" }}
        className={
          "hsCodes position-absolute " +
          (!props.showOverlay
            ? props.product_and_services
              ? props.product_and_services.length > 0
                ? ""
                : "d-none"
              : "d-none"
            : "d-none")
        }
      >
        {props.product_and_services
          ? props.product_and_services.map((item, i) => {
              if (item.type.includes("match")) {
                if (item.hsCode && item.hsDescription && count2 < 2) {
                  count2++;
                  return (
                    <div key={i} className="mt-2 float-left ml-2">
                      <span className="text-light bg-secondary p-1">
                        {item.hsCode +
                          " - " +
                          item.hsDescription.substring(0, 8) +
                          "..."}
                      </span>
                    </div>
                  );
                }
                if (count2 === 2 && item.hsCode && item.hsDescription) {
                  count2++;
                  return (
                    <div key={i} className="mt-2 float-left ml-2">
                      <span className="text-light bg-secondary p-1">
                        Show more...
                      </span>
                    </div>
                  );
                }
              }
            })
          : void 0}
      </div>
      <input
        type="button"
        className={"btn register-btn " + (props.showOverlay ? "" : "d-none")}
        value="Register"
        onClick={() => setShowModal(true)}
      />
      <div
        className="col-lg-3"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            height: "70px",
            width: "auto",
            objectFit: "contain",
            maxWidth: "200px",
          }}
          src={props.wp_logo || "https://via.placeholder.com/350x150"}
          className=""
          alt="..."
        />
        {/*<img src={props.logo.length > 0 ? props.logo : 'https://via.placeholder.com/350x150'} className="" alt='...' />*/}
      </div>
      <div className="col-lg-8 ml-3">
        <h4 className="font-weight-light">{props.name}</h4>
        <h6
          className="text-uppercase mt-0"
          style={{ maxWidth: "80%", fontSize: "14px" }}
        >
          {props.short_description}
        </h6>
        <div className="row mt-4">
          <div className="col-lg-3 properties">
            <label className="w-100">Country</label>
            <label>{uniqueCountryNames.join(", ")}</label>
          </div>
          <div className="col-lg-3 properties">
            <label className="w-100">City</label>
            <label>{uniqueCityNames.join(", ")}</label>
          </div>
          <div className="col-lg-3 properties">
            <label className="w-100">Contact</label>
            <label>{uniqueContactsNames.join(", ")}</label>
          </div>
          <div className={"col-lg-3 "} style={{ zIndex: "0" }}>
            <div
              className={"properties " + (props.showOverlay ? "" : "d-none")}
            >
              <label className="w-100">Country</label>
              <label>{props.hq_location}</label>
            </div>
            {determineButton(props, setSuccessModal)}
          </div>
        </div>
      </div>
      <MembershipPackages show={showModal} onHide={() => setShowModal(false)} />
      <SuccessModalMyMatchesJumpseat
        show={showSuccessModal}
        onHide={() => setSuccessModal(false)}
      />
    </div>
  );
};

function determineButton(props, setSuccessModal) {
  if (
    props.status === "jumpseat" &&
    window.location.pathname.includes(urls.myMatches) &&
    !window.location.pathname.includes("jumpseat")
  ) {
    return (
      <input
        onClick={() => onClickRequestDetails(props, setSuccessModal)}
        style={{
          background: userLevelColors(props.user).buttonBg,
          color: userLevelColors(props.user).buttonFont,
          borderColor: userLevelColors(props.user).buttonFont,
          bottom: "0",
          fontWeight: "600",
          fontSize: "14px",
          width: "270px",
          left: "-15px",
        }}
        type="button"
        className="btn btn-wlr text-uppercase position-absolute"
        value="Request contact details"
      />
    );
  } else if (props.isAgentSearchModal) {
    return (
      <button
        className="btn btn-wlr text-uppercase position-absolute"
        onClick={props.setAgentSearchModal}
        style={{
          background: userLevelColors(props.user).buttonBg,
          color: userLevelColors(props.user).buttonFont,
          borderColor: userLevelColors(props.user).buttonFont,
          bottom: "0",
          fontWeight: "600",
          fontSize: "14px",
          width: "270px",
          left: "-15px",
        }}
      >
        AGENT PROFILE
      </button>
    );
  } else {
    return (
      <Link
        to={{ pathname: `${props.wp_link}` }}
        target="_blank"
        className={props.showOverlay ? "d-none" : ""}
      >
        <input
          type="button"
          className="btn btn-wlr text-uppercase position-absolute"
          style={{
            background: userLevelColors(props.user).buttonBg,
            color: userLevelColors(props.user).buttonFont,
            borderColor: userLevelColors(props.user).buttonFont,
            bottom: "0",
            fontWeight: "600",
            fontSize: "14px",
            width: "270px",
            left: "-15px",
          }}
          value={props.button ? props.button : "Visit Company Profile"}
        />
      </Link>
    );
  }
}

function onClickRequestDetails(props, setSuccessModal) {
  let params = new URLSearchParams();
  params.append("target_id", props.company_id);
  axios
    .request({
      method: "post",
      url: `${base_url}/contact/jumpseat/getintouch`,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${props.token}`,
      },
    })
    .then((res) => {
      if (res.data.status === 200) {
        NotificationManager.success("Request sent", "Success");
        setSuccessModal(true);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
