import { countryList } from "../../services/const";

export const perPage = 10;

export const statuses = [
  { value: "sent", label: "sent" },
  { value: "pending", label: "pending" },
  { value: "failed", label: "failed" },
];

export const countries = countryList.map((country) => ({
  value: country,
  label: country,
}));

export const isTempOrJumpSeat = (user) => {
  return user.user_level.code === "jumpseat" || user.user_level.code === "temp";
};
