import React from "react";
import { authenticateUser } from "../store/actions/loginAction";
import { connect } from "react-redux";
import WelcomeModal from "../components/WelcomeDemo/WelcomeModal";
import axios from "axios";
import { base_url } from "../services/const";
import NewsfeedItem from "../components/Newsfeed/NewsfeedItem";
import { SearchSpinner, userLevelColors } from "../services/globalFunctions";
import { Link } from "react-router-dom";
import { urls } from "../services/router/utils/urls";
import {isResponseError} from "../utils/axios";

class Newsfeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreVideoPost: false,
      welcomeModal: false,
      heroImage: null,
      blogsArr: [],
      isFetching: false,
    };
    this.getNewsfeed = this.getNewsfeed.bind(this);
  }

  componentDidMount() {
    this.getNewsfeed();
  }

  getNewsfeed() {
    this.state.blogsArr.length > 0
        ? void 0
        : this.setState({ isFetching: true });
    axios.get("/articles",{
      params:{
       with:[
           'company:id,name',
           'comments',
           'company.users:id,name',
           'company.addresses:id,addressable_id,country_name,city_name',
           'company.contacts:id,contactable_id,first_name',
       ]
      }
    }).then((ret) => {
      this.setState({ isFetching: false });
      if(!isResponseError(ret.data))
        this.setState({ blogsArr: ret.data.data })
      else
        this.setState({ blogsArr: [] })
    });
  }

  render() {
    return (
        <div className="NewsfeedContainer page-container">
          <div className={"page-header mb-5"}>
            <img
                src={
                  this.state.heroImage
                      ? this.state.heroImage.data
                          ? `data:image/jpeg;base64,${this.state.heroImage.data}`
                          : require("../assets/images/newsfeed.jpg")
                      : require("../assets/images/newsfeed.jpg")
                }
                alt={"..."}
            />
          </div>
          <div className="container" style={{ width: "950px" }}>
            <h2 className="font-weight-light mb-3">Newsfeed</h2>
            <SearchSpinner
                level={this.props.level}
                showLoading={this.state.isFetching}
            />
            <div className={this.state.isFetching ? "d-none" : ""}>
              {this.state.blogsArr.length > 0 ? (
                  this.state.blogsArr.map((item, i) => {
                    return (
                        <NewsfeedItem
                          getNewsfeed={this.getNewsfeed}
                          item={item}
                          key={i}
                        />
                    );
                  })
              ) : (
                  <div className="searchresult w-100 p-3 mb-4 position-relative col-lg-12">
                    <div
                        className="col-lg-12"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                    >
                      <div className="col-lg-2">
                        <img
                            style={{
                              height: "70px",
                              width: "auto",
                              objectFit: "contain",
                              maxWidth: "200px",
                            }}
                            src={require("../assets/images/WorldRing.png")}
                            alt="..."
                        />
                      </div>
                      <div className="col-lg-6 ml-3">No blog posted yet.</div>
                      <div className="col-lg-2 ml-3">
                        <Link
                            style={{
                              background: userLevelColors(this.props.level).buttonBg,
                              color: userLevelColors(this.props.level).buttonFont,
                              borderColor: userLevelColors(this.props.level)
                                  .buttonFont,
                            }}
                            className={
                                "btn btn-wlr text-uppercase " +
                                (this.props.level === "admin" ? "" : "d-none")
                            }
                            to={urls.blog.newsFeed}
                        >
                          Create blog post
                        </Link>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>
          <WelcomeModal
              show={this.state.welcomeModal}
              onHide={() => this.setState({ welcomeModal: false })}
          />
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let companyIds = state.login.company_ids;
  let token = state.login.token;
  let firstLogin = state.login.first_login;
  let level = state.login.level;
  return {
    isLoggedIn,
    token,
    companyIds,
    firstLogin,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Newsfeed);
