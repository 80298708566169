import React from "react";
import { checkIsJumpseatOrTmp } from "../../utils";
import { TmpOrJsMainContact } from "./TmpOrJsMainContact";
import { ExceptTmpOrJsMainContact } from "./ExceptTmpOrJsMainContact";

export const MainContact = ({ companyData }) => {
  return checkIsJumpseatOrTmp(companyData) ? (
    <TmpOrJsMainContact companyData={companyData} />
  ) : (
    <ExceptTmpOrJsMainContact companyData={companyData} />
  );
};
