import {
  CLEAR_PROPS,
  LOGIN_AUTHENTICATION,
  UPDATE_NEW_MATCHES_COUNT,
  UPDATE_USER_DATA,
  UPDATE_USER_LEVEL,
} from "./actionTypes";
import { clearRoutes } from "./breadcrumbsAction";

export const authenticateUser = (data) => {
  return (dispatch) => {
    dispatch(setAuthenticationResponse(data));
  };
};

export const updateUserLevel = (newLevel) => {
  return (dispatch) => {
    dispatch(setUserLevel(newLevel));
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(clearProps([]));
    dispatch(clearRoutes());
  };
};

function setAuthenticationResponse(res) {
  return {
    type: LOGIN_AUTHENTICATION,
    payload: res,
  };
}

function clearProps(res) {
  return {
    type: CLEAR_PROPS,
    payload: res,
  };
}

function setUserLevel(res) {
  return {
    type: UPDATE_USER_LEVEL,
    payload: res,
  };
}
export const updateUserData = (userData) => {
  return {
    type: UPDATE_USER_DATA,
    payload: userData,
  };
};

export const updateMatchesCount = (matchesCount) => {
  return {
    type: UPDATE_NEW_MATCHES_COUNT,
    payload: matchesCount,
  };
};
