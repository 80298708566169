import React from "react";
import styles from "./index.module.scss";
import {
  dashboardMatchesArrowIcon,
  dashboardMatchesPopupIcon,
  dashboardMatchesPopupIconOrange
} from "../../../../assets/icons";

export const MatchesButton = ({ onClick, isOpen }) => {
  return (
    <div
      className={styles.container}
      onClick={onClick}
      style={{ background: isOpen ? "white" : "" }}
    >
      <div
        className={
          isOpen
            ? styles.matchesIconWrapperOpen
            : styles.matchesIconWrapperClosed
        }
      >
        <img
          src={
            isOpen ? dashboardMatchesPopupIconOrange : dashboardMatchesPopupIcon
          }
          alt="match icon"
        />
      </div>
      <div
        className={
          isOpen ? styles.reverseArrowIconWrapper : styles.arrowIconwrapper
        }
      >
        <img src={dashboardMatchesArrowIcon} alt="arrow-top icon" />
      </div>
    </div>
  );
};
