import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const checkIsProduct = (row) => row.AIC;

export const getFilteredRows = (selectedRows) => {
  const isProduct = checkIsProduct(selectedRows[0]);

  let filteredRows = [];

  if (isProduct) {
    filteredRows = selectedRows.map((row) => {
      const { user, pairings, is_belongs_to_me, ...fields } = row;

      return { ...fields };
    });
  } else {
    filteredRows = selectedRows.map((row) => {
      const { ID, ...fields } = row;

      return { ...fields };
    });
  }

  return filteredRows;
};

const downloadPDF = (selectedRows) => {
  if (selectedRows.length === 0) return;

  const filteredRows = getFilteredRows(selectedRows);

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  const headers = Object.keys(filteredRows[0]);
  const tableRows = filteredRows.map((row) =>
    headers.map((header) => row[header])
  );

  doc.autoTable({
    head: [headers],
    body: tableRows,
  });

  doc.save("WorldRing_Marketplace.pdf");
};

const downloadCSV = (selectedRows) => {
  if (selectedRows.length === 0) return;

  const filteredRows = getFilteredRows(selectedRows);

  const headers = Object.keys(filteredRows[0]);
  const csvData = [
    headers,
    ...filteredRows.map((row) => headers.map((header) => row[header])),
  ];
  const csvString = csvData.map((row) => row.join(",")).join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });
  saveAs(blob, "WorldRing_Marketplace.csv");
};

const downloadXLSX = (selectedRows) => {
  if (selectedRows.length === 0) return;

  const filteredRows = getFilteredRows(selectedRows);

  const headers = Object.keys(filteredRows[0]);
  const data = [
    headers,
    ...filteredRows.map((row) => headers.map((header) => row[header])),
  ];
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const xlsxBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([xlsxBuffer], { type: "application/octet-stream" });
  saveAs(blob, "WorldRing_Marketplace.xlsx");
};

export { downloadCSV, downloadXLSX, downloadPDF };
