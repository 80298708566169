import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { uploadImage, userLevelColors } from "../../services/globalFunctions";
import { connect } from "react-redux";
import FileUploadHandler from "../../FileUploadHandler";
import axios from "axios";
import { updateUserData} from "../../store/actions/loginAction";
class HeroImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heroImage: null,
      showFileError: false,
      image: null,
      heroThumbnail: null,
    };
    this.onSubmitHeroImg = this.onSubmitHeroImg.bind(this);
    this.onAbortHeroImg = this.onAbortHeroImg.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
  }

  handleUploadComplete(file, objectURL) {
    this.setState({
      heroImage: { data: objectURL, filename: file.name },
      image: file,
      showFileError: false,
    });
  }

  onSubmitHeroImg() {
    axios.put(`users/${this.props.userId}`,{is_save:true}).then((responseUser)=>{
      this.props.updateUserData(responseUser.data);
    })
    //this.props.onSubmit(image_id);
  }
  onAbortHeroImg() {
    if(this.state.heroImage)
      axios.put(`users/${this.props.userId}`,{is_save:false}).finally(()=>{
        this.props.onHide()
      })
    else
      this.props.onHide()
  }
  render() {
    return (
        <Modal
            show={this.props.show}
            size={"lg"}
            onHide={this.onAbortHeroImg}
            className="HeroImageModal"
        >
          <Modal.Header
              closeButton
              style={{ background: userLevelColors(this.props.level).bg }}
          >
            <h3 style={{ color: userLevelColors(this.props.level).font }}>
              Cover picture
            </h3>
          </Modal.Header>
          <Modal.Body>
            <div className="file-upload-container">
              <div className="file-upload-content">
                <div className={this.state.heroImage ? "d-none" : ""}>
                  {this.props.heroImg ? (
                      this.props.heroImg.data ? (
                          <img
                              style={{height: "65px"}}
                              src={`data:image/jpeg;base64,${this.props.heroImg.data}`}
                              alt={"..."}
                          />
                      ) : (
                          <FontAwesomeIcon size={"3x"} icon={faImage}/>
                      )
                  ) : (
                      <FontAwesomeIcon size={"3x"} icon={faImage}/>
                  )}
                </div>
                <div className={this.state.heroImage ? "" : "d-none"}>
                  {this.state.heroImage ? (
                      <img
                          style={{height: "65px"}}
                          src={`${this.state.heroImage.data}`}
                          alt={"..."}
                      />
                  ) : (
                      <FontAwesomeIcon size={"3x"} icon={faImage}/>
                  )}
                </div>
                <h4 className="title pt-3 mb-1">Drag & Drop</h4>
                <h6 className="sub-title mb-3">
                  Your company hero image goes here.
                </h6>
                <br/>
                <small className={"file-formats mt-3 mb-0 "}>
                  {this.state.heroImage
                      ? this.state.heroImage.filename
                      : "(.PNG or .JPG)"}
                </small>
                {!this.state.heroImage && (
                    <p className="sub-title mt-1">max 5mb</p>
                )}
                &nbsp;
                <small
                    className={
                        "text-danger " + (this.state.showFileError ? "" : "d-none")
                    }
                >
                  Select a file to import
                </small>
              </div>
              <FileUploadHandler
                  config={{
                    maxFileSizeMb: 5,
                    fileFormats: ['png', 'jpg', 'jpeg', 'png'],
                    resource: `users/${this.props.userId}`,
                    label: 'hero_image',
                    isManyFiles:false
                  }}
                  onUploadComplete={this.handleUploadComplete}
                  className={
                      "choose-file-input " +
                      (this.state.heroImage ? "" : "file-selected")
                  }
                  accept=".png, .jpeg, .jpg"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="secondary"
                onClick={this.onAbortHeroImg}
                style={{
                  background: userLevelColors(this.props.level).buttonBg,
                  color: userLevelColors(this.props.level).buttonFont,
                  borderColor: userLevelColors(this.props.level).buttonFont,
                }}
            >
              Close
            </Button>
            <Button
                variant="dark"
                style={{
                  background: userLevelColors(this.props.level).buttonBg,
                  color: userLevelColors(this.props.level).buttonFont,
                  borderColor: userLevelColors(this.props.level).buttonFont,
                }}
                onClick={this.onSubmitHeroImg}
            >
              Save Image
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    userId:state.login.user_id,
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (userData) => dispatch(updateUserData(userData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HeroImageModal);
