import React from "react";
import { Modal } from "../Modal";
import styles from "./index.module.scss";
import { copyIcon, crossIcon, successfullyCreatedIcon } from "../../assets/icons";
import { NotificationManager } from 'react-notifications';
import copy from 'copy-to-clipboard';
import { Button } from "../Button";


export const UserSuccessfullyCreatedModal = ({ isOpen, onClose, userId, invitationLink }) => {

    const onCopyBlockClicl = (content) => {
        copy(content)
        NotificationManager.success('copied to clipboard');
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.closeIconWrapper} onClick={onClose}>
                    <img src={crossIcon} alt="close icon" />
                </div>
                <div>
                    <img src={successfullyCreatedIcon} alt='successfullyCreatedIcon' />
                </div>
                <h1 className={styles.title}>
                    User Successfully Created
                </h1>

                <div className={styles.copyContinersWrapper}>
                    <div className={styles.copyContainer}>
                        <h3 className={styles.copyContainerLabel}>
                            User ID:
                        </h3>
                        <div className={styles.copyBlock} onClick={() => onCopyBlockClicl(userId)}>
                            <span>
                                {userId}
                            </span>
                            <img src={copyIcon} alt="copyIcon" />
                        </div>
                    </div>
                    <div className={styles.copyContainer}>
                        <h3 className={styles.copyContainerLabel}>
                            User Invitation Link:
                        </h3>
                        <div className={styles.copyBlock} onClick={() => onCopyBlockClicl(invitationLink)}>
                            <span>
                                {invitationLink}
                            </span>
                            <img src={copyIcon} alt="copyIcon" />
                        </div>
                    </div>
                </div>
                <Button onClick={onClose} label={'Close'} width={300} />
            </div>
        </Modal >
    );
};
