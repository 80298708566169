import { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { formatDateToDDMMYYYY } from "../../services/globalFunctions";

export const useGetInvitationCompanies = ({
  page,
  perPage,
  search,
  userLevel,
  cancel,
  status,
  sortList,
  refetch,
  setRefetch,
}) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    if (cancel && hasFetchedOnce.current) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const { data: invites } = await axios.get(`invites`, {
          params: {
            per_page: perPage,
            page,
            search,
            my: true,
            is_register: 0,
            sorting: sortList,
            user_level_id: userLevel ? [userLevel] : "",
            user_role: 3,
            status,
            columns_search: ["id"],
            with: ["invitedUser"],
          },
        });

        const formattedData = invites.data.map((invite) => {
          const createdAt = moment(invite.created_at);
          const daysSinceCreation = moment().diff(createdAt, "days");
          const timeToExpiry = 365 - daysSinceCreation;

          return {
            "Company name": invite.invited_user.companies[0].name,
            "User name": `${invite.invited_user.first_name} ${invite.invited_user.last_name}`,
            "Sent to Email": invite.invited_user.email,
            "Sent on": formatDateToDDMMYYYY(invite.updated_at),
            Status: invite.status,
            "time to expiry": timeToExpiry,
            ID: invite.id,
          };
        });

        setData(formattedData);
        setTotal(invites.total);
        hasFetchedOnce.current = true;
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
        setRefetch(false);
      }
    };

    fetchData();
  }, [
    page,
    perPage,
    search,
    userLevel,
    cancel,
    status,
    sortList,
    refetch,
    setRefetch,
  ]);

  return { data: data || [], isLoading, error, total: total | 0 };
};
