import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import customHistory from "../../services/history";
import { OptionsButton } from "./components/OptionsButton";
import { Spin } from "antd";
import "./ant.scss";
import { useGetProducts } from "../../requests/products";
import { useGetCompanies } from "../../requests/company/useGetCompanies";
import { countries, perPage, statuses } from "./consts";
import NewInviteModal from "../../components/Jumpseat/NewInviteModal";
import ProductModal from "../../components/CompanyProfileModals/ServicesModal/ProductModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useGetInvitationCompanies } from "../../requests/company/useGetInvitationCompanies";
import { useGetMyMatchesProducts } from "../../requests/products/useGetMyMatchesProducts";
import { useGeMyEntriesProducts } from "../../requests/products/useGeMyEntriesProducts";
import { CreateSuperAgentUserModal } from "../../components/CreateSuperAgentUserModal";
import { UserSuccessfullyCreatedModal } from "../../components/UserSuccessfullyCreatedModal";
import AgentDashboardTable from "./components/Table";
import {
  typeFilter as types,
  userLevelFilter as selectOptions,
} from "../CommoditiesSearch";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { isResponseError } from "../../utils/axios";
import { useGetAgents } from "../../requests/company/useGetAgents";
import { CustomTabs } from "./CustomTabs";
import { FilterGroup } from "./FilterGroup";
import { threeDotsWhite } from "../../assets/icons";

export const AgentDashboard = () => {
  const [refetch, setRefetch] = useState(false);
  const [activeButtonId, setActiveButtonId] = useState(1);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    setActivePage(1);
  }, [activeButtonId]);

  const { push } = customHistory;

  const userGroup = useSelector((state) => state.login.group);
  const userLevel = useSelector((state) => state.login.level);

  const isAdmin = userLevel === "admin";
  const isAgent = userGroup === "agent";

  useEffect(() => {
    if (!isAgent && !isAdmin) {
      push("/");
    }
  }, [isAdmin, isAgent, push]);

  const [isUserCreateModalOpen, setIsUserCreateModalOpen] = useState(false);

  const [
    isUserSuccessfullyCreatedModalOpen,
    setIsUserSuccessfullyCreatedModalOpen,
  ] = useState(false);

  const [createdUserId, setCreatedUserId] = useState(0);
  const [createdUserinvitationLink, setCreatedUSerIinvitationLink] =
    useState("");

  const [isJSInvitesModalVisible, setIsJSInvitesModalVisible] = useState(false);
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 200 });
  }, [activePage]);

  //-------------PRODUCTS BLOCK START----------
  //-------------PRODUCTS BLOCK START----------
  const [productSortList, setProductSortList] = useState(null);
  const [productSearchValue, setProductSearchValue] = useState("");
  const [productSearchValueForRequest, setProductSearchValueForRequest] =
    useState("");
  const [productSelectValue, setProductSelectValue] = useState("");
  const [productCountry, setProductCountry] = useState([]);
  const [productType, setProductType] = useState([]);

  const {
    data: productData,
    isLoading: isProductDataLoading,
    total: productTotal,
  } = useGetProducts({
    page: activePage,
    perPage,
    search: productSearchValueForRequest,
    sortList: productSortList,
    userLevel: productSelectValue,
    commodity_type_id: productType,
    country: productCountry,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------PRODUCTS BLOCK END----------
  //-------------PRODUCTS BLOCK END----------

  //-------------MATCHES BLOCK START----------
  //-------------MATCHES BLOCK START----------
  const [myMatches_ProductCountry, setMyMatches_ProductCountry] = useState([]);
  const [myMatches_ProductType, setMyMatches_ProductType] = useState([]);
  const [myMatches_ProductsSearchValue, setMyMatches_ProductsSearchValue] =
    useState("");
  const [
    myMatches_ProductsSearchValueForRequest,
    setMyMatches_ProductsSearchValueForRequest,
  ] = useState("");
  const [myMatches_ProductsSelectValue, setMyMatches_ProductsSelectValue] =
    useState("");

  const [matchesSortList, setMatchesSortList] = useState(null);

  const {
    data: myMathes_productData,
    isLoading: isMyMathes_ProductDataLoading,
    total: myMathes_productTotal,
  } = useGetMyMatchesProducts({
    page: activePage,
    perPage,
    search: myMatches_ProductsSearchValueForRequest,
    sortList: matchesSortList,
    userLevel: myMatches_ProductsSelectValue,
    commodity_type_id: myMatches_ProductType,
    country: myMatches_ProductCountry,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------MATCHES BLOCK END----------
  //-------------MATCHES BLOCK END----------

  //-------------CUSTOMERS BLOCK START----------
  //-------------CUSTOMERS BLOCK START----------
  const [companiesSortList, setCompaniesSortList] = useState(null);
  const [companiesSearchValue, setCompaniesSearchValue] = useState("");
  const [companiesSearchValueForRequest, setCompaniesSearchValueForRequest] =
    useState("");
  const [companiesSelectValue, setCompaniesSelectValue] = useState("");

  const {
    data: companiesData,
    isLoading: isCompaniesDataLoading,
    total: companiesTotal,
  } = useGetCompanies({
    page: activePage,
    perPage,
    search: companiesSearchValueForRequest,
    sortList: companiesSortList,
    userLevel: companiesSelectValue,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------CUSTOMERS BLOCK END----------
  //-------------CUSTOMERS BLOCK END----------

  //-------------ADMIN AGENTS BLOCK START----------
  //-------------ADMIN AGENTS BLOCK START----------
  const [agentsSortList, setAgentsSortList] = useState(null);
  const [agentsSearchValue, setAgentsSearchValue] = useState("");
  const [agentsSearchValueForRequest, setAgentsSearchValueForRequest] =
    useState("");
  const [agentsSelectValue, setAgentsSelectValue] = useState("");

  const {
    data: agentsData,
    isLoading: isAgentsDataLoading,
    total: agentsTotal,
  } = useGetAgents({
    page: activePage,
    perPage,
    search: agentsSearchValueForRequest,
    sortList: agentsSortList,
    userLevel: agentsSelectValue,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------ADMIN AGENTS BLOCK END----------
  //-------------ADMIN AGENTS BLOCK END----------

  //-------------INVITATIONS BLOCK START----------
  //-------------INVITATIONS BLOCK START----------

  const [invitations_CompaniesStatus, setInvitations_CompaniesStatus] =
    useState([]);
  const [
    invitations_CompaniesSearchValue,
    setInvitations_CompaniesSearchValue,
  ] = useState("");
  const [
    invitations_CompaniesSearchValueForRequest,
    setInvitations_CompaniesSearchValueForRequest,
  ] = useState("");
  const [
    invitations_CompaniesSelectValue,
    setInvitations_CompaniesSelectValue,
  ] = useState("");
  const [invitesSortList, setInvitesSortLiest] = useState(null);

  const {
    data: invitations_companiesData,
    isLoading: isInvitations_CompaniesDataLoading,
    total: invitations_companiesTotal,
  } = useGetInvitationCompanies({
    page: activePage,
    perPage,
    search: invitations_CompaniesSearchValueForRequest,
    sortList: invitesSortList,
    userLevel: invitations_CompaniesSelectValue,
    status: invitations_CompaniesStatus,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------INVITATIONS BLOCK END----------
  //-------------INVITATIONS BLOCK END----------

  //-------------MY ENTRIES BLOCK START----------
  //-------------MY ENTRIES BLOCK START----------
  const [myEntries_ProductCountry, setMyEntries_ProductCountry] = useState([]);
  const [myEntries_ProductType, setMyEntries_ProductType] = useState([]);

  const [myEntries_ProductsSearchValue, setMyEntries_ProductsSearchValue] =
    useState("");
  const [
    myEntries_ProductsSearchValueForRequest,
    setMyEntries_ProductsSearchValueForRequest,
  ] = useState("");
  const [myEntries_ProductsSelectValue, setMyEntries_ProductsSelectValue] =
    useState("");
  const [entriesSortList, setEntriesSortList] = useState(null);

  const {
    data: myEntries_productData,
    isLoading: isMyEntries_ProductDataLoading,
    total: myEntries_productTotal,
  } = useGeMyEntriesProducts({
    page: activePage,
    perPage,
    search: myEntries_ProductsSearchValueForRequest,
    sortList: entriesSortList,
    userLevel: myEntries_ProductsSelectValue,
    commodity_type_id: myEntries_ProductType,
    country: myEntries_ProductCountry,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------MY ENTRIES BLOCK END----------
  //-------------MY ENTRIES BLOCK END----------

  //-------------CUSTOMER ENTRIES BLOCK START----------
  //-------------CUSTOMER ENTRIES BLOCK START----------
  const [customerEntries_ProductCountry, setCustomerEntries_ProductCountry] =
    useState([]);
  const [customerEntries_ProductType, setCustomerEntries_ProductType] =
    useState([]);

  const [
    customerEntries_ProductsSearchValue,
    setCustomerEntries_ProductsSearchValue,
  ] = useState("");
  const [
    customerEntries_ProductsSearchValueForRequest,
    setCustomerEntries_ProductsSearchValueForRequest,
  ] = useState("");
  const [
    customerEntries_ProductsSelectValue,
    setCustomerEntries_ProductsSelectValue,
  ] = useState("");
  const [customerEntriesSortList, setCustomerEntriesSortList] = useState(null);

  const {
    data: customerEntries_productData,
    isLoading: isCustomerEntries_ProductDataLoading,
    total: customerEntries_productTotal,
  } = useGeMyEntriesProducts({
    page: activePage,
    perPage,
    search: customerEntries_ProductsSearchValueForRequest,
    sortList: customerEntriesSortList,
    userLevel: customerEntries_ProductsSelectValue,
    commodity_type_id: customerEntries_ProductType,
    country: customerEntries_ProductCountry,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------CUSTOMER ENTRIES BLOCK END----------
  //-------------CUSTOMER ENTRIES BLOCK END----------

  const tabs = [
    {
      key: "products",
      label: "Products",
      count: isProductDataLoading ? <Spin /> : productTotal | 0,
      id: 1,
    },
    {
      key: "agents",
      label: "Agents",
      count: isAgentsDataLoading ? <Spin /> : agentsTotal | 0,
      id: 6,
      adminOnly: true,
    },
    {
      key: "customers",
      label: "Customers",
      count: isCompaniesDataLoading ? <Spin /> : companiesTotal | 0,
      id: 2,
    },
    {
      key: "invitations",
      label: "Invitations",
      count: isInvitations_CompaniesDataLoading ? (
        <Spin />
      ) : (
        invitations_companiesTotal | 0
      ),
      id: 3,
    },
    {
      key: "matches",
      label: "Matches",
      count: isMyMathes_ProductDataLoading ? (
        <Spin />
      ) : (
        myMathes_productTotal | 0
      ),
      id: 4,
    },
    {
      key: "myEntries",
      label: "My entries",
      count: isMyEntries_ProductDataLoading ? (
        <Spin />
      ) : (
        myEntries_productTotal | 0
      ),
      id: 5,
    },
    {
      key: "customerEntries",
      label: "Customer entries",
      count: isCustomerEntries_ProductDataLoading ? (
        <Spin />
      ) : (
        customerEntries_productTotal | 0
      ),
      id: 7,
      adminOnly: true,
    },
  ];

  const onClickUploadPnS = (e) => {
    let file = e.target.files[0];
    let params = new FormData();
    params.append("file", file);

    axios
      .post("/commodities/mass-upload", params)
      .then((ret) => {
        if (!isResponseError(ret)) {
          NotificationManager.success("HSCodes uploaded successfully");
        }
      })
      .catch((err) => {
        NotificationManager.error("Something went wrong, please try again.");
      });
    e.target.value = null;
  };

  return (
    <div className={styles.wrapper}>
      <h1>MY DASHBOARD</h1>
      <NewInviteModal
        show={isJSInvitesModalVisible}
        onHide={() => {
          setIsJSInvitesModalVisible(false);
          setRefetch(true);
        }}
      />
      {isProductModalVisible && (
        <ProductModal
          show={isProductModalVisible}
          onHide={() => {
            setIsProductModalVisible(false);
            setRefetch(true);
          }}
          clientHSCodeModal={true}
        />
      )}
      {isUserCreateModalOpen && (
        <CreateSuperAgentUserModal
          setCreatedUserId={setCreatedUserId}
          setCreatedUSerIinvitationLink={setCreatedUSerIinvitationLink}
          isOpen={isUserCreateModalOpen}
          onClose={() => {
            setIsUserCreateModalOpen(false);
            setRefetch(true);
          }}
          setIsUserSuccessfullyCreatedModalOpen={
            setIsUserSuccessfullyCreatedModalOpen
          }
        />
      )}
      {isUserSuccessfullyCreatedModalOpen && (
        <UserSuccessfullyCreatedModal
          invitationLink={createdUserinvitationLink}
          userId={createdUserId}
          isOpen={isUserSuccessfullyCreatedModalOpen}
          onClose={() => setIsUserSuccessfullyCreatedModalOpen(false)}
        />
      )}

      <div
        style={{
          width: 1140,
          marginBottom: 40,
          display: "flex",
          justifyContent: "flex-end",
          gap: 20,
        }}
      >
        <OptionsButton
          clickContainer={
            <button className={styles.inviteButton}>
              <p>+</p>
              <span>New</span>
              <img
                src={threeDotsWhite}
                alt="threeDotsWhite"
                style={{ cursor: "pointer", width: 22, height: 25 }}
              />
            </button>
          }
        >
          <div
            className={styles.inviteButtonContainer}
            style={{
              whiteSpace: "nowrap",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              gap: "9px",
            }}
          >
            <div
              onClick={() => setIsJSInvitesModalVisible(true)}
              style={{
                border: "1px solid orange",
                padding: "5px 10px",
                borderRadius: "10px",
              }}
            >
              Jumpseat Invitation
            </div>
            <div
              onClick={() => setIsProductModalVisible(true)}
              style={{
                cursor: "pointer",
                border: "1px solid orange",
                padding: "5px 10px",
                borderRadius: "10px",
              }}
            >
              Create Product Entry
            </div>
            <div
              style={{
                border: "1px solid orange",
                padding: "5px 10px",
                borderRadius: "10px",
              }}
            >
              <label style={{ cursor: "pointer", marginBottom: 0 }}>
                <span style={{ color: "#636a72" }}>Product Mass Upload</span>
                <input
                  type="file"
                  className="d-none"
                  accept=".csv, .xls, .xlsx, .numbers"
                  onChange={onClickUploadPnS}
                />
                <FontAwesomeIcon className="ml-2" icon={faUpload} />
              </label>
            </div>

            {isAdmin && (
              <div
                style={{
                  border: "1px solid orange",
                  padding: "5px 10px",
                  borderRadius: "10px",
                }}
                onClick={() => setIsUserCreateModalOpen(true)}
              >
                <span>Add Superagent</span>
                <span></span>
              </div>
            )}
          </div>
        </OptionsButton>
      </div>
      <div className={styles.container}>
        <div className={styles.sectionsLine}>
          <CustomTabs
            items={tabs}
            setActiveButtonId={setActiveButtonId}
            isAdmin={isAdmin}
          />
        </div>
        <div className={styles.main}>
          {activeButtonId === 1 && (
            <>
              <FilterGroup
                selectValue={productSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setProductSelectValue}
                searchValue={productSearchValue}
                setSearchValueForRequst={setProductSearchValueForRequest}
                setSearchValue={setProductSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                threeIconsData={[
                  {
                    columnName: "Country",
                    threeIconsOptions: countries,
                    threeIconsValue: productCountry,
                    setThreeIcons: setProductCountry,
                  },
                  {
                    columnName: "Type",
                    threeIconsOptions: types,
                    threeIconsValue: productType,
                    setThreeIcons: setProductType,
                  },
                ]}
                sortList={productSortList}
                setSortList={setProductSortList}
                total={productTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={true}
                data={productData}
                isLoading={isProductDataLoading}
                setRefetch={setRefetch}
              />
            </>
          )}
          {activeButtonId === 2 && (
            <>
              <FilterGroup
                selectValue={companiesSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setCompaniesSelectValue}
                searchValue={companiesSearchValue}
                setSearchValueForRequst={setCompaniesSearchValueForRequest}
                setSearchValue={setCompaniesSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                threeIconsData={[]}
                sortList={companiesSortList}
                setSortList={setCompaniesSortList}
                total={companiesTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={false}
                data={companiesData}
                isLoading={isCompaniesDataLoading}
                setRefetch={setRefetch}
              />
            </>
          )}

          {activeButtonId === 6 && (
            <>
              <FilterGroup
                selectValue={agentsSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setAgentsSelectValue}
                searchValue={agentsSearchValue}
                setSearchValueForRequst={setAgentsSearchValueForRequest}
                setSearchValue={setAgentsSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                threeIconsData={[]}
                sortList={agentsSortList}
                setSortList={setAgentsSortList}
                total={agentsTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={false}
                data={agentsData}
                isLoading={isAgentsDataLoading}
                setRefetch={setRefetch}
              />
            </>
          )}

          {activeButtonId === 3 && (
            <>
              <FilterGroup
                selectValue={invitations_CompaniesSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setInvitations_CompaniesSelectValue}
                searchValue={invitations_CompaniesSearchValue}
                setSearchValueForRequst={
                  setInvitations_CompaniesSearchValueForRequest
                }
                setSearchValue={setInvitations_CompaniesSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                threeIconsData={[
                  {
                    columnName: "Status",
                    threeIconsOptions: statuses,
                    threeIconsValue: invitations_CompaniesStatus,
                    setThreeIcons: setInvitations_CompaniesStatus,
                  },
                ]}
                isInvitationsTab={true}
                total={invitations_companiesTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={false}
                data={invitations_companiesData}
                isLoading={isInvitations_CompaniesDataLoading}
                sortList={invitesSortList}
                setSortList={setInvitesSortLiest}
                setRefetch={setRefetch}
              />
            </>
          )}
          {activeButtonId === 4 && (
            <>
              <FilterGroup
                selectValue={myMatches_ProductsSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setMyMatches_ProductsSelectValue}
                searchValue={myMatches_ProductsSearchValue}
                setSearchValueForRequst={
                  setMyMatches_ProductsSearchValueForRequest
                }
                setSearchValue={setMyMatches_ProductsSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                threeIconsData={[
                  {
                    columnName: "Country",
                    threeIconsOptions: countries,
                    threeIconsValue: myMatches_ProductCountry,
                    setThreeIcons: setMyMatches_ProductCountry,
                  },
                  {
                    columnName: "Type",
                    threeIconsOptions: types,
                    threeIconsValue: myMatches_ProductType,
                    setThreeIcons: setMyMatches_ProductType,
                  },
                ]}
                total={myMathes_productTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={true}
                data={myMathes_productData}
                isLoading={isMyMathes_ProductDataLoading}
                sortList={matchesSortList}
                setSortList={setMatchesSortList}
                setRefetch={setRefetch}
              />
            </>
          )}
          {activeButtonId === 5 && (
            <>
              <FilterGroup
                selectValue={myEntries_ProductsSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setMyEntries_ProductsSelectValue}
                searchValue={myEntries_ProductsSearchValue}
                setSearchValueForRequst={
                  setMyEntries_ProductsSearchValueForRequest
                }
                setSearchValue={setMyEntries_ProductsSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                threeIconsData={[
                  {
                    columnName: "Country",
                    threeIconsOptions: countries,
                    threeIconsValue: myEntries_ProductCountry,
                    setThreeIcons: setMyEntries_ProductCountry,
                  },
                  {
                    columnName: "Type",
                    threeIconsOptions: types,
                    threeIconsValue: myEntries_ProductType,
                    setThreeIcons: setMyEntries_ProductType,
                  },
                ]}
                total={myEntries_productTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={true}
                data={myEntries_productData}
                isLoading={isMyEntries_ProductDataLoading}
                sortList={entriesSortList}
                setSortList={setEntriesSortList}
                setRefetch={setRefetch}
              />
            </>
          )}

          {activeButtonId === 7 && (
            <>
              <FilterGroup
                selectValue={customerEntries_ProductsSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setCustomerEntries_ProductsSelectValue}
                searchValue={customerEntries_ProductsSearchValue}
                setSearchValueForRequst={
                  setCustomerEntries_ProductsSearchValueForRequest
                }
                setSearchValue={setCustomerEntries_ProductsSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                threeIconsData={[
                  {
                    columnName: "Country",
                    threeIconsOptions: countries,
                    threeIconsValue: customerEntries_ProductCountry,
                    setThreeIcons: setCustomerEntries_ProductCountry,
                  },
                  {
                    columnName: "Type",
                    threeIconsOptions: types,
                    threeIconsValue: customerEntries_ProductType,
                    setThreeIcons: setCustomerEntries_ProductType,
                  },
                ]}
                total={customerEntries_productTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={true}
                data={customerEntries_productData}
                isLoading={isCustomerEntries_ProductDataLoading}
                sortList={customerEntriesSortList}
                setSortList={setCustomerEntriesSortList}
                setRefetch={setRefetch}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
