import React from "react";
import { ReadyToRequest } from "../ReadyToRequest";
import { MainContact } from "../MainContact";
import { checkIsJumpseatOrTmp } from "../../utils";
import FlippableCard from "../../../../components/FlippableCard/flippable-card";

//cards (Main Contact, Ready to do a request) need redesign
//they must have the same height, padding and etc, otherwise the layout will shift
//main contact card need responsive design, card have no space for content after 1200

export const RequestOrMainContact = ({ companyData }) => {
  const sendRequest = () => {
    const wlAddress = "info@worldring.org";
    const isAgMember = companyData.user_level.code === "member";
    const IsJumpseat = checkIsJumpseatOrTmp(companyData);

    if (isAgMember) {
      const emailToSend = wlAddress;
      window.location.href = `mailto:${emailToSend}`;
      return;
    }
    if (IsJumpseat) {
      const emailToSend = companyData.inviter.companies[0].contacts[0].email;
      window.location.href = `mailto:${emailToSend}`;
      return;
    }
    const emailToSend =
      companyData.companies[0].contacts[0].email ||
      companyData.inviter.companies[0].contacts[0].email ||
      wlAddress;
    window.location.href = `mailto:${emailToSend}`;

    // request logic
    // ...
    // if(request.error) { throw } => => => check inside ready to request, if error => dont flip
  };

  const isRequestApproved = false;

  return isRequestApproved ? (
    <MainContact companyData={companyData} />
  ) : (
    <FlippableCard
      back={<MainContact companyData={companyData} />}
      front={<ReadyToRequest handleSendRequest={sendRequest} />}
    />
  );
};
