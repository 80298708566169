import React from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { base_url, countryList } from "../../services/const";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { userLevelColors } from "../../services/globalFunctions";
import {isResponseError} from "../../utils/axios";

class NewInviteModal extends React.Component {
  constructor(props) {
    super(props);
    this.sendInvite = this.sendInvite.bind(this);
    this.state = {
      countryList: [],
      clientCompanyName: "",
      clientTitle: null,
      clientFirstName: "",
      clientLastName: "",
      clientEmail: "",
      personalMsg: "",
      country: null,
    };
  }

  sendInvite(e) {
    e.preventDefault();
    axios.post('invites',{
      company_name:this.state.clientCompanyName,
      first_name: this.state.clientFirstName,
      last_name:this.state.clientLastName,
      title:this.state.clientTitle,
      email:this.state.clientEmail,
      message:this.state.personalMsg,
      country:this.state.country,
    }).then((res) => {
      console.log('new invite',res)
      if (!isResponseError(res,false)) {
        //this.props.getTableData();
        NotificationManager.success("Invitation Sent", "Yayy!");
        this.props.onHide()
      }
    }).catch((err) => {
          console.log(err);
        });
  }

  componentDidMount() {
    const { token, companyIds } = this.props;
    const d = this.props;
    const companyId = companyIds[0];

    const setCountryList = async () => {
      // const { data } = await getCompany({ token, companyId });
      // if (!data) return; // data should be null (now []) with request error
      // const { company_hq_country } = data;
      // // add hq_country of children companies
      // const countryList = [company_hq_country];
      this.setState({ countryList });
    };

    setCountryList();
  }

  render() {
    return (
        <Modal
            className="NewInviteModal"
            show={this.props.show}
            size={"lg"}
            onHide={this.props.onHide}
        >
          <Modal.Header
              closeButton
              style={{ background: userLevelColors(this.props.level).bg }}
          >
            <h3
                style={{ color: userLevelColors(this.props.level).font }}
                className=""
            >
              Create new Invite
            </h3>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.sendInvite}>
              <div className="row">
                <div className="form-group col-lg-8">
                  <input
                      value={this.state.clientCompanyName}
                      onChange={(e) =>
                          this.setState({ clientCompanyName: e.target.value })
                      }
                      className={
                          "form-control  " +
                          userLevelColors(this.props.level).className
                      }
                      placeholder="Client Company Name *"
                      required
                  />
                </div>
                <div className="form-group col-lg-4">
                  <select
                      className={
                          "form-control  " +
                          userLevelColors(this.props.level).className
                      }
                      onChange={(e) => this.setState({ country: e.target.value })}
                      required
                      style={{ maxWidth: 200 }}
                  >
                    <option value="">Country *</option>
                    {this.state.countryList.map((item, i) => {
                      return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-2">
                  <select
                      onChange={(e) =>
                          this.setState({ clientTitle: e.target.value })
                      }
                      className={
                          "form-control  " +
                          userLevelColors(this.props.level).className
                      }
                      required
                  >
                    <option value="">Title *</option>
                    <option value="Dr.">Dr.</option>
                    <option value="Prof.">Prof.</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-group col-5">
                  <input
                      value={this.state.clientFirstName}
                      onChange={(e) =>
                          this.setState({ clientFirstName: e.target.value })
                      }
                      className={
                          "form-control  " +
                          userLevelColors(this.props.level).className
                      }
                      placeholder="First Name *"
                      required
                  />
                </div>
                <div className="form-group col-5">
                  <input
                      value={this.state.clientLastName}
                      onChange={(e) =>
                          this.setState({ clientLastName: e.target.value })
                      }
                      className={
                          "form-control  " +
                          userLevelColors(this.props.level).className
                      }
                      placeholder="Last Name *"
                      required
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                    value={this.state.clientEmail}
                    type="email"
                    onChange={(e) => this.setState({ clientEmail: e.target.value })}
                    className={
                        "form-control  " + userLevelColors(this.props.level).className
                    }
                    placeholder="Client Email *"
                    required
                />
              </div>
              <div className="form-group">
              <textarea
                  value={this.state.personalMsg}
                  onChange={(e) => this.setState({ personalMsg: e.target.value })}
                  className={
                      "form-control  " + userLevelColors(this.props.level).className
                  }
                  placeholder="Personal Message *"
                  required
              ></textarea>
              </div>
              <Modal.Footer>
                <Button
                    style={{
                      background: userLevelColors(this.props.level).buttonBg,
                      color: userLevelColors(this.props.level).buttonFont,
                      borderColor: userLevelColors(this.props.level).buttonFont,
                    }}
                    variant="secondary"
                    onClick={this.props.onHide}
                >
                  Close
                </Button>
                <Button
                    style={{
                      background: userLevelColors(this.props.level).buttonBg,
                      color: userLevelColors(this.props.level).buttonFont,
                      borderColor: userLevelColors(this.props.level).buttonFont,
                    }}
                    type="submit"
                    className="ml-3"
                    variant="dark"
                >
                  Send Invite
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  const companyIds = state.login.company_ids;
  return {
    token,
    level,
    companyIds,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewInviteModal);
