import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import styles from "./index.module.scss";

export const ReadyToRequest = ({ handleSendRequest, handleFlipCard }) => {
  const onClick = () => {
    try {
      // handleSendRequest();
      handleFlipCard();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="box">
      <div className={`text-center ${styles.container}`}>
        <FontAwesomeIcon icon={faHandshake} size="3x" />
        <h4 className={`mb-4 mt-3 ${styles.title}`}>Ready to do a request?</h4>
        <Button
          onClick={onClick}
          style={{
            background: "black",
            color: "white",
          }}
        >
          Contact now
        </Button>
      </div>
    </div>
  );
};
