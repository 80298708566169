import { combineReducers } from "redux";
import lReducer from "./loginReducer";
import cReducer from "./hsCodeReducer";
import bReducer from "./breadcrumbsReducer";

const rootReducer = combineReducers({
  login: lReducer,
  hsCodes: cReducer,
  breadcrumbs: bReducer,
});
export default rootReducer;
