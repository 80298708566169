import React from "react";
import { connect } from "react-redux";
import { toBase64, userLevelColors } from "../../services/globalFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faMinus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { isResponseError } from "../../utils/axios";

let searchInterval;

class HsCodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.targetTextarea = React.createRef();
    this.state = {
      selectedOptions: [],
      hsCodeVal: "",
      hsCodes: {},
      isOffering: true,
      specification: "",
      target: "",
    };
    this.showBadges = this.showBadges.bind(this);
    this.getHsCode = this.getHsCode.bind(this);
    this.onClickListItem = this.onClickListItem.bind(this);
    this.removeValue = this.removeValue.bind(this);
    this.onChangeSearching = this.onChangeSearching.bind(this);
    this.onChangeOffering = this.onChangeOffering.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { editableItem } = this.props;
    if (editableItem !== prevProps.editableItem && editableItem) {
      this.setState({
        selectedOptions: [
          {
            id: "",
            item:
              editableItem.hsCode.code +
              " - " +
              editableItem.hsCode.description,
          },
        ],
        specification: editableItem.specification,
        target: editableItem.target_group,
        isOffering: editableItem.type === "offer",
      });
    }
  }

  checkIfItAlreadyExists(obj, list) {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i].id === obj.id) {
        return true;
      }
    }

    return false;
  }

  onClickListItem(item, hsCode) {
    let arr = this.state.selectedOptions;
    this.props.childErrors[this.props.active] = null;
    if (arr.length === 0) {
      let obj = {
        id: hsCode,
        item: item,
      };
      if (!this.checkIfItAlreadyExists(obj, arr)) {
        arr.push(obj);
        this.setState({ [item + "-target"]: "", [item + "-specs"]: "" });
      }
      this.setState(
        { selectedOptions: arr, showSearchResult: false, hsCodeVal: item },
        () => {
          this.props.onChangeHsCode(this.props.active, arr);
        }
      );
    } else {
      NotificationManager.error("Only one HS Code per item", "Error");
    }
    this.targetTextarea.current.focus();
    this.setState({ showSearchResult: false });
  }

  removeValue(item) {
    let ne = this.state.selectedOptions.filter(function (obj) {
      return obj.id !== item.id;
    });
    this.props.onChangeHsCode(this.props.active, null);
    this.setState({ selectedOptions: ne, hsCodeVal: "" });
  }

  async onChangeImage(e) {
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 5) {
      NotificationManager.error("File size too large. Max size 5mb.", "Error");
    } else {
      this.setState({ image: e.target.files[0] }, () =>
        this.props.onChangeImage(this.state.image, this.props.active)
      );
      let logo = {
        mime: e.target.files[0].type,
        filename: e.target.files[0].name,
      };
      await toBase64(e.target.files[0]).then((res) => {
        logo.data = res;
        this.setState({ newImageThumbnail: logo, showFileError: false });
      });
    }
  }

  getHsCode(e) {
    clearTimeout(searchInterval);
    let searchVal = e.target.value;
    this.setState({
      hsCodeVal: searchVal,
      showSearchResult: true,
      hsCodes: [],
    });
    if (searchVal.length > 2) {
      searchInterval = setTimeout(() => {
        axios
          .get("/products")
          .then((ret) => {
            if (!isResponseError(ret)) {
              //TRANSFORM OBJ[] to {id: code-name}
              const hsCodesResult = ret.data.data.reduce((acc, item) => {
                acc[item.id] = `${item.code} - ${item.name}`;
                return acc;
              }, {});
              this.setState({
                hsCodes: hsCodesResult,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 3000);
    }
    if (searchVal.length <= 0) {
      this.setState({ showSearchResult: false });
    }
  }

  showBadges() {
    return this.state.selectedOptions.map((item, i) => {
      return (
        <h4 key={i}>
          <p className="badge badge-secondary  text-left">
            {item.item.length > 50
              ? item.item.substr(0, 50) + "..."
              : item.item}
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="ml-2"
              onClick={this.removeValue.bind(null, item)}
            />
          </p>
        </h4>
      );
    });
  }

  onChangeOffering(e) {
    this.setState({ isOffering: e.target.checked }, () => {
      if (this.state.isOffering) {
        this.props.onChangeType(this.props.active, "offer");
      }
    });
  }

  onChangeSearching(e) {
    this.setState({ isOffering: !e.target.checked }, () => {
      if (!this.state.isOffering) {
        this.props.onChangeType(this.props.active, "search");
      }
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="mb-1 ml-0 row col-lg-12">{this.showBadges()}</div>
          {this.state.selectedOptions.length === 0 ? (
            <div className="form-group w-100 col-lg-12">
              <input
                value={this.state.hsCodeVal}
                onChange={this.getHsCode}
                className={
                  "form-control  " +
                  userLevelColors(this.props.level).className +
                  (this.props.childErrors[this.props.active]
                    ? "input-error"
                    : "")
                }
                placeholder="Search HS Code"
                required
              />
              <small className="">
                <i>
                  Tip: If you are looking for a wide range of products, please
                  enter only the first 3 digits of the HS code. <br />
                  You can always contact your local WorldRing-Agent for any
                  assistance.
                </i>
              </small>
            </div>
          ) : (
            ""
          )}
          <div
            style={
              Object.keys(this.state.hsCodes).length > 0
                ? {
                    maxHeight: "200px",
                    borderRadius: "4px",
                    overflow: "scroll",
                    zIndex: "10",
                    marginTop: "80px",
                  }
                : {}
            }
            className={
              "position-absolute col-lg-12 w-100 " +
              (this.state.showSearchResult ? "" : "d-none")
            }
          >
            <ul className="list-group w-100">
              {Object.keys(this.state.hsCodes).length > 0 ? (
                Object.keys(this.state.hsCodes).map((item, i) => {
                  return (
                    <li
                      key={i}
                      className="list-group-item"
                      onClick={this.onClickListItem.bind(
                        null,
                        this.state.hsCodes[item],
                        item
                      )}
                    >
                      {this.state.hsCodes[item]}
                    </li>
                  );
                })
              ) : (
                <div
                  className="spinner-border"
                  role="status"
                  style={{ marginLeft: "50%" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </ul>
          </div>
          <div className="custom-checkbox-dkpns col-lg-4 d-flex justify-content-between">
            <label className="checkbox-container">
              <p className="the-label">Search</p>
              <input
                onChange={this.onChangeSearching}
                type="checkbox"
                checked={!this.state.isOffering}
              />
              <span className="checkmark"></span>
            </label>
            <label className="checkbox-container">
              <p className="the-label">Offer</p>
              <input
                onChange={this.onChangeOffering}
                type="checkbox"
                checked={this.state.isOffering}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <form className="row col-lg-12 mt-2 pr-0">
            <div className="form-group col-lg-6 pr-0">
              <div style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <textarea
                  value={this.state.target}
                  style={{ border: "none", color: "black" }}
                  rows={6}
                  ref={this.targetTextarea}
                  className="form-control w-100 mt-0"
                  onChange={(e) =>
                    this.setState({ target: e.target.value }, () =>
                      this.props.onChangeTarget(
                        this.props.active,
                        this.state.target
                      )
                    )
                  }
                  placeholder="Target industry, customers, group..."
                />
              </div>
            </div>
            <div className="form-group col-lg-6 pr-0">
              <div style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <textarea
                  value={this.state.specification}
                  style={{ border: "none", color: "black" }}
                  rows={6}
                  maxLength={250}
                  className="form-control w-100 mt-0"
                  placeholder="Specifications"
                  onChange={(e) =>
                    this.setState({ specification: e.target.value }, () =>
                      this.props.onChangeSpecs(
                        this.props.active,
                        this.state.specification
                      )
                    )
                  }
                />
              </div>
            </div>
          </form>
        </div>
        {this.props.id === this.props.totalLength - 1 &&
          this.props.id !== 0 && (
            <div
              style={{
                cursor: "pointer",
                marginLeft: "50%",
                transform: "translate(-50%)",
                padding: "10px",
                borderRadius: "50%",
                border: "1px solid black",
                width: "40px",
                height: "40px",
                textAlign: "center",
              }}
              onClick={this.props.onClickRemoveItem.bind(null, this.props.id)}
            >
              <FontAwesomeIcon icon={faMinus} />
            </div>
          )}
        <hr className="seperator" style={{ width: "100%" }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HsCodeForm);
