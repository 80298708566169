import React, { useRef, useEffect } from "react";
import styles from "./flippable-card.module.scss";
import Card from "./card/card";
import { CSSTransition } from "react-transition-group";
import { useState } from "react";

function FlippableCard({ back, front }) {
  const [showFront, setShowFront] = useState(true);
  const [height, setHeight] = useState(0);
  const frontSideContentRef = useRef(null);

  useEffect(() => {
    if (frontSideContentRef.current) {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(frontSideContentRef.current.clientHeight);
        }, 0);
      }).then(setHeight);
    }
  }, [showFront]);

  const handleFlipCard = () => {
    setShowFront(false);
  };

  return (
    // take the height of the highest side ( back side (Main Contact) )
    <div className={styles.flippableCardContainer} style={{ height }}>
      <CSSTransition in={showFront} timeout={300} classNames="flip">
        <Card
          showFront={showFront}
          back={back}
          front={front}
          handleFlipCard={handleFlipCard}
          ref={frontSideContentRef}
        />
      </CSSTransition>
    </div>
  );
}

export default FlippableCard;
