import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateMatchesCount } from "./store/actions/loginAction";

const EchoListener = React.memo(({ userId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId) {
      console.log(`Subscribing to broadcast`);
      const broadcastChannel = window.Echo.channel("Broadcast").listen(
        "BroadcastEvent",
        (event) => {
          console.log("EchoListener::EventForAll:", event);
        }
      );
      console.log(`Subscribing to User.${userId}`);
      const privateChannel = window.Echo.private(`User.${userId}`)
        .listen("PairingCreated", (event) => {
          console.log("EchoListener::PairingCreated:", event);
        })
        .listen("PairingSeen", (event) => {
          console.log("EchoListener::PairingSeen:", event);
          dispatch(updateMatchesCount(event.total));
        })
        .listen("NotifyEvent", (event) => {
          console.log(`EchoListener::NotifyEvent:`, event);
        });
      console.log(`Subscribing to Presence`);
      const presenceChannel = window.Echo.join("Presence")
        .here((members) => {
          console.log("Current members:", members);
        })
        .joining((member) => {
          console.log("Member joining:", member);
        })
        .leaving((member) => {
          console.log("Member leaving:", member);
        })
        .error((error) => {
          console.error("Presence:error", error);
        });

      return () => {
        console.log(`Unsubscribing from User.${userId} and Presence channels`);
        // privateChannel.stopListening('TestEventB');// Stop listening to 'TestEventB'
        window.Echo.leave(`User.${userId}`); // Leave the private channel completely
        window.Echo.leave("Presence"); // Leave the presence channel completely
        window.Echo.leave("Broadcast"); // Leave the broadcast channel completely
      };
    }
  }, [userId]);

  return null;
});

export default EchoListener;
