import React from "react";
import { authenticateUser } from "../store/actions/loginAction";
import { connect } from "react-redux";
import axios from "axios";
import { base_url } from "../services/const";
import { SearchSpinner, userLevelColors } from "../services/globalFunctions";
import { Link } from "react-router-dom";
import AgentInfoModal from "../components/ClientManagement/AgentInfoModal";
import { NotificationManager } from "react-notifications";
import MyProductsListItem from "../components/MyProductsListItem";
import { SearchResultListItem } from "../components/SearchResultListItem";
import { urls } from "../services/router/utils/urls";

class MyMatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      searchVal: "",
      matchesCc: [],
      noCompanies: false,
      showAgentInfoModal: false,
      matchCountry: "",
      agMatches: [],
      items: [],
      showLoading: false,
    };
    this.getMatches = this.getMatches.bind(this);
    this.getItems = this.getItems.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.getMyClients = this.getMyClients.bind(this);
    this.markMatchesAsSeen = this.markMatchesAsSeen.bind(this);
    this.onClickContactAgent = this.onClickContactAgent.bind(this);
    this.matchesSearchResult = this.matchesSearchResult.bind(this);
  }

  componentDidMount() {
    // this.getItems();
    this.getMatches();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeKey } = this.props;
    if (activeKey !== prevProps.activeKey && activeKey === "customerMatches") {
      this.markMatchesAsSeen();
    }
  }

  markMatchesAsSeen() {
    axios
      .request({
        method: "patch",
        url: `${base_url}/agent/clientmatches/mark/seen`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        console.log(ret);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getMyClients() {
    return new Promise((resolve, reject) => {
      axios
        .request({
          method: "get",
          url: `${base_url}/agent/myclients/${this.state.searchVal}`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.props.token}`,
          },
        })
        .then((ret) => {
          resolve(ret);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  }

  async getMatches(e) {
    e ? e.preventDefault() : void 0;
    this.setState({ showLoading: true });
    let allClients = await this.getMyClients();
    if (allClients.data.status === 204) {
      this.setState({ showLoading: false, noCompanies: true });
      return false;
    }
    axios
      .request({
        method: "get",
        url: `${base_url}/agent/clientmatches`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.data && ret.data.status === 200 && ret.data.data.length > 0) {
          let result = ret.data.data;
          let tempArr = [];
          let tempArr2 = [];
          // for (let i = 0; i < result.length; i++) {
          //   let item = result[i];
          //   if (item.enteredByAg) {
          //     if (
          //       Object.keys(item.offer).length > 0 &&
          //       Object.keys(item.search).length > 0
          //     ) {
          //       if (item.offer.isPrimary) {
          //         let offer = item.offer;
          //         offer.matchedCompany = item.search;
          //         offer.type = "offer";
          //         offer.seen = item.seen;
          //         tempArr2.push(offer);
          //       } else {
          //         let search = item.search;
          //         search.matchedCompany = item.offer;
          //         search.type = "search";
          //         search.seen = item.seen;
          //         tempArr2.push(search);
          //       }
          //     }
          //   } else {
          //     if (
          //       Object.keys(item.offer).length > 0 &&
          //       Object.keys(item.search).length > 0
          //     ) {
          //       if (item.offer.isPrimary) {
          //         let offer = item.offer;
          //         offer.matchedCompany = item.search;
          //         offer.type = "offer";
          //         offer.seen = item.seen;
          //         tempArr.push(offer);
          //       } else {
          //         let search = item.search;
          //         search.matchedCompany = item.offer;
          //         search.type = "search";
          //         search.seen = item.seen;
          //         tempArr.push(search);
          //       }
          //     }
          //   }
          // }
          this.setState({
            matches: result,
            matchesCc: tempArr,
            showLoading: false,
            agMatches: tempArr2,
          });
        } else {
          this.setState({ showLoading: false, noCompanies: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ showLoading: false });
      });
  }

  getItems(e) {
    e ? e.preventDefault() : void 0;
    this.setState({ showLoading: true });
    axios
      .request({
        method: "get",
        url: `${base_url}/company/mymatches/${this.state.searchVal}`,
        // url: `${base_url}/commodities/search/100/0`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.status === 200) {
          this.setState({
            // countries: getCountries(ret.data.data.companies),
            // matchesCc: ret.data.data.companies,
            items: ret.data.data,
            showLoading: false,
          });
        } else {
          this.setState({ showLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ showLoading: false });
      });
  }

  onChangeSearch(e) {
    e.preventDefault();
    let { searchVal, matchesCc } = this.state;
    if (searchVal.length >= 3) {
      let filter = matchesCc.filter((e) =>
        e.name.toLowerCase().includes(searchVal.toLowerCase())
      );
      this.setState({ matches: filter });
    } else {
      this.setState({ matches: matchesCc });
    }
  }

  getMatchedItem(item) {
    const { product_and_services, matchedCompany } = item;
    const matchedPnS = matchedCompany.product_and_services;
    let matchedHSCode = {};
    for (let i = 0; i < product_and_services.length; i++) {
      let pns = product_and_services[i];
      for (let k = 0; k < matchedPnS.length; k++) {
        let matched = matchedPnS[k];
        if (
          matched.id === pns.id &&
          matched.type.includes("match") &&
          pns.type.includes("match")
        ) {
          matchedHSCode = matched;
          break;
        }
      }
    }
    return matchedHSCode;
  }

  onClickContactAgent(item) {
    if (
      Array.isArray(item.matchedCompany.agent) &&
      item.matchedCompany.agent.length === 0
    ) {
      NotificationManager.error("Agent information not available");
    } else {
      this.setState({
        agentInfo: item.matchedCompany.agent,
        showAgentInfoModal: true,
        matchCountry: item.matchedCompany.hq_country,
      });
    }
  }

  matchesSearchResult(item, i) {
    let date = item.matched
      .split(" ")[0]
      .split("-")
      .reverse()
      .join()
      .replaceAll(/,/g, ".");
    return (
      <div
        key={i}
        style={
          !item.seen
            ? {
                boxShadow:
                  "0 0 4px rgb(0 0 0 / 30%), 0 6px 6px rgb(0 0 0 / 7%)",
              }
            : {}
        }
        className={
          "searchresult w-100 row p-3 m-0 mb-4 position-relative box " +
          (item.seen ? "" : "unseen-match-frame")
        }
      >
        {!item.seen && <div className="new-match-label">New match</div>}
        <label
          style={
            !item.seen
              ? { position: "absolute", right: "100px" }
              : { position: "absolute", right: "18px" }
          }
        >
          Match created on: {date}
        </label>
        <div
          className="col-lg-3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {item.matchedCompany.logo.data ? <div id="overlay"></div> : ""}
          <img
            style={{
              height: "auto",
              width: "100%",
              // maxWidth: '200px'
            }}
            src={
              item.matchedCompany.logo
                ? item.matchedCompany.logo.data.length > 0
                  ? `data:image/jpeg;base64,${item.matchedCompany.logo.data}`
                  : require("../assets/images/WR_match_logo.png")
                : require("../assets/Company_Logo_Dummy_-2.png")
            }
            className=""
            alt="..."
          />
        </div>
        <div className="col-lg-8 ml-3">
          <h4 style={{ fontWeight: "900", fontSize: "22px" }}>
            <span>Your customer:</span>{" "}
            <span className="text-uppercase">{item.name}</span>
          </h4>
          <div className="row mt-4">
            <div className="col-lg-3 properties">
              <label className="w-100">HS Code Match</label>
              <label>{this.getMatchedItem(item).hsCode}</label>
            </div>
            <div className="col-lg-3 properties">
              <div id="overlay"></div>
              <label className="w-100">Match Company</label>
              <label>{item.matchedCompany.name}</label>
            </div>
            <div className="col-lg-3 properties">
              <label className="w-100">Match Country</label>
              <label>{item.matchedCompany.hq_country}</label>
            </div>
            <div className={"col-lg-3 "} style={{ zIndex: "0" }}>
              <input
                onClick={this.onClickContactAgent.bind(null, item)}
                type="button"
                className="btn btn-wlr text-uppercase position-absolute"
                style={{
                  background: userLevelColors(this.props.level).buttonBg,
                  color: userLevelColors(this.props.level).buttonFont,
                  borderColor: userLevelColors(this.props.level).buttonFont,
                  bottom: "0",
                  fontWeight: "600",
                  fontSize: "14px",
                  width: "270px",
                  left: "-15px",
                }}
                value={"Contact agent in charge"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="mt-5 MyMatchesContainer">
        <div className="mb-5">
          <p className="m-0">
            Get an overview of your customer's matches on the Worldring
            Marketplace.
          </p>
        </div>
        <div>
          <SearchSpinner showLoading={this.state.showLoading} />
          <div className={"mt-5 " + (this.state.showLoading ? "d-none" : "")}>
            {this.state.matches.length > 0 && (
              <h2>Direct matches for your customer</h2>
            )}
            {/* {this.state.matches.length > 0
              ? this.state.matches.map((item, i) => {
                  // return this.matchesSearchResult(item, i);
                  return (
                    <SearchResultListItem
                      user={this.props.level}
                      showOverlay={false}
                      {...item}
                      key={i}
                      token={this.props.token}
                    />
                  );
                })
              : ""} */}
            {this.state.agMatches.length > 0 && (
              <h2>Matches on your entries</h2>
            )}
            {this.state.agMatches.length > 0
              ? this.state.agMatches.map((item, i) => {
                  return this.matchesSearchResult(item, i);
                })
              : ""}

            {this.state.noCompanies && (
              <div className="searchresult box p-3 mb-4 position-relative row col-lg-12 align-items-center">
                <div className="col-lg-2">
                  <img
                    style={{
                      height: "70px",
                      width: "auto",
                      objectFit: "contain",
                      maxWidth: "200px",
                    }}
                    src={require("../assets/images/WorldRing.png")}
                    alt="..."
                  />
                </div>
                <div className="col-lg-6">
                  <p className="m-0 text-center">
                    You have not added any Clients yet.
                  </p>
                </div>
                <div className="col-lg-4 text-right">
                  <Link
                    style={{
                      background: userLevelColors(this.props.level).buttonBg,
                      color: userLevelColors(this.props.level).buttonFont,
                      borderColor: userLevelColors(this.props.level).buttonFont,
                    }}
                    className="btn btn-wlr text-uppercase"
                    to={urls.jumpseat.invite}
                  >
                    Start now!
                  </Link>
                </div>
              </div>
            )}
            {this.state.matches.map((item, i) => {
              return (
                <MyProductsListItem
                  key={item.pns_id}
                  item={item}
                  hideButton={true}
                />
              );
            })}

            {this.state.matches.length === 0 &&
            this.state.agMatches.length === 0 &&
            !this.state.noCompanies ? (
              <div className="searchresult box p-3 mb-4 position-relative row col-lg-12 align-items-center">
                <div className="col-lg-2">
                  <img
                    style={{
                      height: "70px",
                      width: "auto",
                      objectFit: "contain",
                      maxWidth: "200px",
                    }}
                    src={require("../assets/images/WorldRing.png")}
                    alt="..."
                  />
                </div>
                <div className="col-lg-4 text-center">
                  <img
                    src={require("../assets/icons/ban.svg")}
                    width={65}
                    alt={"..."}
                  />
                </div>
                <div className="col-lg-6">
                  <p className="m-0 text-left">
                    There are currently no matches in our Network.
                  </p>
                  <p className="m-0 text-left">
                    Inform your fellow Agents about the needs of your customer
                    to present him a match ASAP.
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {/*{this.state.agMatches.length > 0 ? <h1>Matches on your entries</h1> : ""}*/}
          </div>
        </div>
        <AgentInfoModal
          show={this.state.showAgentInfoModal}
          onHide={() => this.setState({ showAgentInfoModal: false })}
          agentInfo={this.state.agentInfo}
          country={this.state.matchCountry}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let token = state.login.token;
  let level = state.login.level;
  let companyIds = state.login.company_ids;
  return {
    isLoggedIn,
    token,
    level,
    companyIds,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyMatches);
