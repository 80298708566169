import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { uploadImage, userLevelColors } from "../services/globalFunctions";
import { isResponseError } from "../utils/axios";

class UserSettingsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      profilePicture: "",
      fname: "",
      lname: "",
      email: "",
      newEmail: "",
      nick: "",
      companyName: "",
      jobtitle: "",
      gender: "",
      title: "",
      password: "",
      newPassword: "",
      repeatPassword: "",
      showPwError: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.show !== prevProps.show && this.props.show) {
      this.getUserData();
    }
  }

  getUserData() {
    axios.get("/me").then((ret) => {
      if (!isResponseError(ret)) {
        this.setState({
          email: ret.data.email,
          fname: ret.data.first_name,
          gender: ret.data.gender,
          lname: ret.data.last_name,
          companyName: ret.data.companies[0].name,
          phone: ret.data.phone,
          userId: ret.data.id,
          //OLD FIELDS. DONT KNOW WHY WE NEED IT -- START
          // jobtitle: ret.data.data.jobtitle,
          // title: ret.data.data.title,
          // nick: ret.data.data.nick,
          // profilePicture: "",
          //OLD FIELDS. DONT KNOW WHY WE NEED IT -- END
        });
      }
    });
  }

  async handleFileUpload(e) {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    let image_id = await uploadImage(formData, this.props.token);
    this.setState({ profilePicture: image_id, showFileError: false });
  }

  onSubmit() {
    if (!this.state.showPwError) {
      axios
        .put(`/user/${this.state.userId}`, {
          email: this.state.newEmail || undefined,
          gender: this.state.gender || undefined,
          first_name: this.state.fname || undefined,
          last_name: this.state.lname || undefined,
          password: this.state.newPassword || undefined,
          old_password: this.state.password || undefined,
        })
        .then((ret) => {
          if (!isResponseError(ret)) {
            NotificationManager.success("Profile Updated", "Success");
            this.getUserData();
            this.props.onHide();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  onChangePassword() {
    if (this.state.newPassword !== this.state.repeatPassword) {
      this.setState({ showPwError: true });
    } else {
      this.setState({ showPwError: false });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        size={"lg"}
        onHide={this.props.onHide}
        className="company-profile-modal"
      >
        <Modal.Header
          className="border-0"
          style={{ background: userLevelColors(this.props.level).bg }}
          closeButton
        >
          <h3 className="">User settings</h3>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form>
            <div className="row mt-3">
              <div className="form-group col-lg-2">
                <select
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  onChange={(e) => this.setState({ gender: e.target.value })}
                  defaultValue={this.state.gender}
                >
                  <option disabled value={null}>
                    Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group col-lg-2">
                <select
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  onChange={(e) => this.setState({ title: e.target.value })}
                  defaultValue={this.state.title}
                >
                  <option disabled value={null}>
                    Title
                  </option>
                  <option value="Dr.">Dr.</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group col-lg-4">
                <input
                  onChange={(e) => this.setState({ fname: e.target.value })}
                  defaultValue={this.state.fname}
                  type="text"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="First Name"
                />
              </div>
              <div className="form-group col-lg-4">
                <input
                  onChange={(e) => this.setState({ lname: e.target.value })}
                  defaultValue={this.state.lname}
                  type="text"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Last Name"
                />
              </div>
              <div className="form-group col-lg-6">
                <input
                  onChange={(e) => this.setState({ email: e.target.value })}
                  defaultValue={this.state.email}
                  type="email"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Old email"
                  autoComplete="old-password"
                />
              </div>
              <div className="form-group col-lg-6">
                <input
                  onChange={(e) => this.setState({ newEmail: e.target.value })}
                  defaultValue={this.state.newEmail}
                  type="email"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="New email"
                  autoComplete="new-email"
                />
              </div>
              <div className="form-group col-lg-12">
                <input
                  onChange={(e) => this.setState({ password: e.target.value })}
                  defaultValue={this.state.password}
                  type="password"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Old password"
                  autoComplete="old-password"
                />
              </div>
              <div className="form-group col-lg-6">
                <input
                  onChange={(e) =>
                    this.setState({ newPassword: e.target.value }, () =>
                      this.onChangePassword()
                    )
                  }
                  defaultValue={this.state.newPassword}
                  type="password"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="New password"
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group col-lg-6">
                <input
                  onChange={(e) =>
                    this.setState({ repeatPassword: e.target.value }, () =>
                      this.onChangePassword()
                    )
                  }
                  defaultValue={this.state.repeatPassword}
                  type="password"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Repeat password"
                  autoComplete="repeat-password"
                />
                <small
                  className={
                    "text-danger " + (this.state.showPwError ? "" : "d-none")
                  }
                >
                  <i>Password do not match</i>
                </small>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            variant="secondary"
            onClick={this.props.onHide}
          >
            Close
          </Button>
          <Button
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            variant="dark"
            onClick={this.onSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let userRole = state.login.group;
  let token = state.login.token;
  let level = state.login.level;
  return {
    userRole,
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSettingsModal)
);
