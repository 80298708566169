import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FooterCard } from "../components/FooterCard";
import axios from "axios";
import { base_url } from "../services/const";
import CompanyProfileDescriptionModal from "../components/CompanyProfileModals/CompanyProfileDescriptionModal";
import CompanyProfileDetailsModal from "../components/CompanyProfileModals/CompanyProfileDetailsModal";
import HeroImageModal from "../components/CompanyProfileModals/HeroImageModal";
import { NotificationManager } from "react-notifications";
import OfferingShowcase from "../components/CompanyProfileComponents/OfferingShowcase";
import SearchingShowcase from "../components/CompanyProfileComponents/SearchingShowcase";
import PnSShowCase from "../components/CompanyProfileComponents/PnSShowCase";
import {
  faPen,
  faMobileAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { SearchSpinner, userLevelColors } from "../services/globalFunctions";
import { activeCompany } from "../store/actions/breadcrumbsAction";
import { Link } from "react-router-dom";

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      companyId: this.props.match.params.companyId,
      companyLongDescription: "",
      updatedDescription: "",
      companyContacts: [],
      updatedContacts: [],
      companyAddresses: [],
      updatedAddresses: [],
      companyUserId: "",
      companyName: "",
      updatedCompanyName: "",
      companyHeroImage: "",
      updatedHeroImage: "",
      companyLogo: "",
      updatedLogo: "",
      companyServices: "",
      showDescriptionModal: false,
      showDetailModal: false,
      showHeroImageModal: false,
      showServicesModal: false,
      editProfile: false,
      showEditBtn: false,
      pns: [],
      agContact: {},
      showLoading: false,
      companyUserLevel: "",
    };
    this.getCompanyDetails = this.getCompanyDetails.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.isActiveUserProfile = this.isActiveUserProfile.bind(this);
    this.getPnS = this.getPnS.bind(this);
    this.returnPlaceHolderImg = this.returnPlaceHolderImg.bind(this);
    this.onClickMap = this.onClickMap.bind(this);
  }

  componentDidMount() {
    this.getCompanyDetails();
    this.isActiveUserProfile();
    this.getPnS();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.match.params.companyId !== prevProps.match.params.companyId
    ) {
      this.setState({ companyId: this.props.match.params.companyId }, () => {
        this.getCompanyDetails();
        this.isActiveUserProfile();
        this.getPnS();
      });
    }
    if (
      this.props.firstLogin !== prevProps.firstLogin &&
      this.props.firstLogin
    ) {
      this.setState({ welcomeModal: true });
    }
  }

  getCompanyDetails() {
    this.setState({ showLoading: true });
    axios
      .get(`companies/${this.state.companyId}`)
      .then((res) => {
        if (res.data.status === 200) {
          this.props.activeCompany(res.data.data.name);
          this.setState({
            companyData: res.data.data,
            companyLongDescription: res.data.data.long_description
              ? res.data.data.long_description
              : "Enter your Company description here.",
            updatedDescription: res.data.data.long_description,
            companyContacts: res.data.data.contacts || [],
            updatedContacts: res.data.data.contacts || [],
            companyAddresses: res.data.data.addresses || [],
            updatedAddresses: res.data.data.addresses || [],
            companyUserId: res.data.data.user_id,
            companyName: res.data.data.name,
            updatedCompanyName: res.data.data.name,
            updatedLogo: res.data.data.logo,
            companyLogo: res.data.data.logo,
            companyHeroImage: res.data.data.hero_image,
            updatedHeroImage: res.data.data.hero_image,
            companyServices: res.data.data.services,
            showLoading: false,
            companyUserLevel: res.data.data.user_level,
          });
        } else {
          this.setState({ showLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ showLoading: false });
        console.log(err);
      });
  }

  getPnS() {
    axios
      .get(`/companies/${this.state.companyId}/commodities`)
      .then((ret) => {
        if (ret.status === 200 && ret.data.data) {
          this.setState({ pns: ret.data.data });
        } else {
          this.setState({ pns: [] });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  isActiveUserProfile() {
    this.props.activeUserCompanyArr.map((id) => {
      if (id === parseInt(this.state.companyId)) {
        this.setState({ showEditBtn: true });
      }
      return true;
    });
  }

  updateProfile() {
    let data = new URLSearchParams();
    data.append("long_description", this.state.companyLongDescription);
    data.append("name", this.state.companyName);
    // Преобразование обновленных контактов и адресов
    const updatedContacts = this.state.updatedContacts.map((contact) => {
      return {
        id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        job_title: contact.job_title,
        title: contact.title,
        gender_title: contact.gender_title,
        phone: contact.phone,
        mobile: contact.mobile,
        fax: contact.fax,
        email: contact.email,
      };
    });

    const updatedAddresses = this.state.updatedAddresses.map((address) => {
      return {
        id: address.id,
        iso_code: address.iso_code,
        zip_code: address.zip_code,
        metro_code: address.metro_code,
        area_code: address.area_code,
        postal_code: address.postal_code,
        country_code: address.country_code,
        region_code: address.region_code,
        country_name: address.country_name,
        region_name: address.region_name,
        city_name: address.city_name,
        latitude: address.latitude,
        longitude: address.longitude,
        is_main: address.is_main,
      };
    });

    data.append("contacts", JSON.stringify(updatedContacts));
    data.append("addresses", JSON.stringify(updatedAddresses));

    axios
      .put(`/api/v1/app/companies/${this.state.companyId}`)
      .then((res) => {
        if (res.data.status === 200) {
          this.getCompanyDetails();
          this.setState({ showHeroImageModal: false });
          NotificationManager.success("Profile Updated", "Success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  returnPlaceHolderImg(gender) {
    switch (gender) {
      case "Male":
        return "MalePlaceholder.jpg";
      case "Female":
        return "FemalePlaceholder.jpg";
      default:
        return "MalePlaceholder.jpg";
    }
  }

  onClickMap(address) {
    let addressStr = `${address.street} ${address.postal_code} ${address.city_name} ${address.country_name}`;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${addressStr}`
    );
  }

  render() {
    let navIcon = userLevelColors(this.state.companyUserLevel).icon;
    return (
      <div className="CompanyProfile page-container">
        <div className="position-relative page-header">
          <img
            className={
              "page-header " + (this.state.companyHeroImage ? "" : "d-none")
            }
            style={{
              backgroundImage: this.state.companyHeroImage,
              objectFit: "cover",
            }}
            src={
              this.state.companyHeroImage
                ? this.state.companyHeroImage.data
                  ? `data:image/jpeg;base64,${this.state.companyHeroImage.data}`
                  : require(`../assets/images/static-ad.jpg`)
                : require(`../assets/images/static-ad.jpg`)
            }
            alt="..."
          />
          <span
            className={
              "position-absolute upload-hero-image-btn px-3 py-2 " +
              (this.state.editProfile ? "" : "d-none")
            }
            onClick={() => this.setState({ showHeroImageModal: true })}
          >
            <FontAwesomeIcon icon={faUpload} size={"2x"} />
          </span>
        </div>
        <nav
          className={
            "navbar navbar-light " + (this.state.showLoading ? "d-none" : "")
          }
          style={{
            background: userLevelColors(this.state.companyUserLevel).bg,
          }}
        >
          <div className="container">
            <div className="row w-100" style={{ alignItems: "center" }}>
              <div className="col-lg-8">
                <input
                  type="button"
                  style={{ padding: "3px 10px", fontSize: "14px" }}
                  className={
                    "btn btn-small btn-dark " +
                    (this.state.showEditBtn ? "" : "d-none")
                  }
                  value={
                    this.state.editProfile ? "Finish Editing" : "Edit Profile"
                  }
                  onClick={() =>
                    this.setState((prevState) => ({
                      editProfile: !prevState.editProfile,
                    }))
                  }
                />
              </div>
              <div className="col-lg-4" style={{ paddingRight: "0" }}>
                <div
                  className="text-uppercase text-white font-weight-light w-100 text-left pl-3"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  <img
                    className="pr-4"
                    style={{ height: "40px", left: "140px", top: "5px" }}
                    src={require(`../assets/userIcons/${navIcon}`)}
                    alt={"..."}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      color: userLevelColors(this.state.companyUserLevel).font,
                    }}
                  >
                    {userLevelColors(this.state.companyUserLevel).label}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <SearchSpinner
          showLoading={this.state.showLoading}
          level={this.props.group}
        />
        <div
          className={"container " + (this.state.showLoading ? "d-none" : "")}
          style={{ marginTop: 90 }}
        >
          <div className="row">
            <div className="col-lg-8 company-description">
              <div className="company-description-box box">
                <div
                  className="company-description-content"
                  style={{ fontSize: "14px" }}
                >
                  {this.state.editProfile ? (
                    <button
                      className="btn"
                      onClick={() =>
                        this.setState({ showDescriptionModal: true })
                      }
                      style={{ padding: "0", marginBottom: "10px" }}
                    >
                      <FontAwesomeIcon icon={faPen} /> Edit Description
                    </button>
                  ) : (
                    ""
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.companyLongDescription,
                    }}
                  />
                </div>
              </div>
              <div>
                <FooterCard />
              </div>
              {/* Offering */}
              <div>
                <OfferingShowcase
                  offerings={this.state.pns}
                  editProfile={this.state.editProfile}
                  companyId={this.state.companyId}
                  getPnS={this.getPnS}
                  agContact={this.state.agContact}
                />
              </div>
              {/* Offering Ends */}
              {/* Searching */}
              <div>
                <SearchingShowcase
                  searches={this.state.pns}
                  editProfile={this.state.editProfile}
                  companyId={this.state.companyId}
                  getPnS={this.getPnS}
                  agContact={this.state.agContact}
                />
              </div>
              {/* Searching ends */}
              {/* PNS */}
              <div>
                <PnSShowCase
                  company_logo={this.state.companyLogo}
                  pns={this.state.pns}
                  editProfile={this.state.editProfile}
                  companyId={this.state.companyId}
                  getPnS={this.getPnS}
                  agContact={this.state.agContact}
                />
              </div>
              {/* PNS ends */}
            </div>
            <div className="col-lg-4 company-profile">
              <div className="company-profile-image-box box">
                <div className="company-image-box">
                  <img
                    src={
                      this.state.companyLogo
                        ? this.state.companyLogo.data
                          ? `data:image/jpeg;base64,${this.state.companyLogo.data}`
                          : require("../assets/placeholders/comp-logo-placeholder.png")
                        : require("../assets/placeholders/comp-logo-placeholder.png")
                    }
                    className="company-profile-image"
                    alt="..."
                  />
                </div>
              </div>
              <div className="company-profile-box box">
                <div className="company-credentials">
                  {this.state.editProfile ? (
                    <button
                      className="btn"
                      onClick={() => this.setState({ showDetailModal: true })}
                      style={{ padding: "0", marginBottom: "10px" }}
                    >
                      <FontAwesomeIcon icon={faPen} /> Edit Profile
                    </button>
                  ) : (
                    ""
                  )}
                  <h3 className="company-profile-title">
                    {this.state.companyName}
                  </h3>
                  <p className="company-profile-location">
                    {this.state.companyAddresses[0].city_name},{" "}
                    {this.state.companyAddresses[0].country_name}
                  </p>
                  <hr className="seperator" />
                  <p className="subsection text-uppercase">Headquarters</p>
                  {this.state.companyAddresses.map((address, index) => (
                    <div key={index} onClick={() => this.onClickMap(address)}>
                      <p className="company-address-list">
                        {address.street}, {address.postal_code}
                      </p>
                      <p className="company-address-list">
                        {address.city_name}, {address.country_name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="company-profile-box box">
                <div className="company-contact">
                  <p className="subsection text-uppercase">Main Contacts</p>
                  {this.state.companyContacts.map((contact, index) => (
                    <div key={index} className="row mb-4">
                      <div className="col-lg-6">
                        <img
                          style={{
                            marginRight: "20px",
                            width: "auto",
                            height: "150px",
                            objectFit: "contain",
                          }}
                          src={
                            contact.image
                              ? contact.image.data
                                ? `data:image/jpeg;base64,${contact.image.data}`
                                : require(`../assets/placeholders/${this.returnPlaceHolderImg(
                                    contact.gender_title
                                  )}`)
                              : require(`../assets/placeholders/${this.returnPlaceHolderImg(
                                  contact.gender_title
                                )}`)
                          }
                          alt="..."
                        />
                      </div>
                      <div className="company-contact-list col-lg-6">
                        <p className="company-contact-list-name">
                          <span className={contact.title ? "" : "d-none"}>
                            {contact.title}
                          </span>{" "}
                          {contact.first_name} {contact.last_name}
                        </p>
                        <p
                          className={
                            "font-weight-light " +
                            (contact.job_title ? "" : "d-none")
                          }
                        >
                          {contact.job_title}
                        </p>
                        <p
                          className={
                            "company-contact-list-phone " +
                            (contact.phone ? "" : "d-none")
                          }
                          style={{ fontSize: "14px" }}
                        >
                          <FontAwesomeIcon icon={faPhone} className="mr-1" />{" "}
                          {contact.phone}
                        </p>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            (window.location.href = `skype:${contact.mobile}?call`)
                          }
                        >
                          <p
                            className={
                              "company-contact-list-phone " +
                              (contact.mobile ? "" : "d-none")
                            }
                            style={{ fontSize: "14px" }}
                          >
                            <FontAwesomeIcon
                              icon={faMobileAlt}
                              style={{ fontSize: "16px" }}
                              className="mr-2"
                            />{" "}
                            {contact.mobile}
                          </p>
                        </a>
                        <a
                          onClick={() =>
                            (window.location.href = `mailto:${contact.email}`)
                          }
                        >
                          <p
                            className={
                              "company-contact-list-email d-inline-flex " +
                              (contact.email ? "" : "d-none")
                            }
                            style={{ fontSize: "14px" }}
                          >
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="mr-2 mt-1"
                            />
                            <span style={{ wordBreak: "break-word" }}>
                              {contact.email}
                            </span>
                          </p>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={
                  "company-profile-box box " +
                  (Array.isArray(this.state.agContact) ? "d-none" : "")
                }
                style={{ paddingTop: "15px" }}
              >
                <div className={"agent-incharge mt "}>
                  <p className="subsection text-uppercase">Agent In Charge</p>
                  <Link
                    to={`/company-profile/${this.state.agContact.company_id}`}
                  >
                    <img
                      style={{
                        width: "100%",
                        maxHeight: "150px",
                        objectFit: "contain",
                      }}
                      alt={"..."}
                      src={
                        !Array.isArray(this.state.agContact.logo)
                          ? this.state.agContact.logo
                            ? `data:image/jpeg;base64,${this.state.agContact.logo.data}`
                            : "https://via.placeholder.com/350x150"
                          : "https://via.placeholder.com/350x150"
                      }
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CompanyProfileDescriptionModal
          description={this.state.companyLongDescription}
          showModal={this.state.showDescriptionModal}
          onHide={() => this.setState({ showDescriptionModal: false })}
          onChange={(e) => this.setState({ updatedDescription: e })}
          onSubmit={() =>
            this.setState(
              {
                companyLongDescription: this.state.updatedDescription,
                showDescriptionModal: false,
              },
              () => this.updateProfile()
            )
          }
        />
        <CompanyProfileDetailsModal
          showModal={this.state.showDetailModal}
          onHide={() => this.setState({ showDetailModal: false })}
          companyData={this.state.companyData}
          onChangeCompanyName={(e) =>
            this.setState({ updatedCompanyName: e.target.value })
          }
          onChangeContact={(index, key, value) => {
            let updatedContacts = [...this.state.updatedContacts];
            updatedContacts[index][key] = value;
            this.setState({ updatedContacts });
          }}
          onChangeAddress={(index, key, value) => {
            let updatedAddresses = [...this.state.updatedAddresses];
            updatedAddresses[index][key] = value;
            this.setState({ updatedAddresses });
          }}
          onSubmit={() => {
            this.setState(
              {
                companyName: this.state.updatedCompanyName,
                companyContacts: this.state.updatedContacts,
                companyAddresses: this.state.updatedAddresses,
                showDetailModal: false,
              },
              () => this.updateProfile()
            );
          }}
        />
        <HeroImageModal
          show={this.state.showHeroImageModal}
          onHide={() => this.setState({ showHeroImageModal: false })}
          onSubmit={(image_id) =>
            this.setState({ updatedHeroImage: image_id }, () =>
              this.updateProfile()
            )
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let activeUserCompanyArr = state.login.company_ids;
  let level = state.login.group;
  let group = state.login.level;
  let firstLogin = state.login.first_login;
  return {
    token,
    activeUserCompanyArr,
    level,
    firstLogin,
    group,
  };
};

const mapDispatchToProps = (dispatch) => ({
  activeCompany: (data) => dispatch(activeCompany(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
