import React from "react";
import { Button, Modal } from "react-bootstrap";
import {
  uploadImage,
  uploadPdf,
  userLevelColors,
} from "../../../services/globalFunctions";
import { connect } from "react-redux";
import OSFormComponent from "../../CompanyProfileComponents/OSFormComponent";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import classNames from "classnames";
import { isResponseError } from "../../../utils/axios";
// import Select from "react-select";

const INITIAL_STATE = {
  isNewCommodity:false,
  productImage: [],
  name: "",
  pns_id: "",
  country: "",
  companyNameForHSCode: "",
  countryForHSCode: { value: "", label: "Country" },
  companyNameForHSCodeError: false,
  countryForHSCodeError: false,
  companyName: "",
  description: "",
  target: "",
  specification: "",
  fileName: "",
  hsCode: "",
  pdf: "",
  quantity: 1,
  weight: 0,
  minUnits: 1,
  certification: "false",
  season: "false",
  dimension: "0,0,0",
  modeOfProduction: "",
  capacity: "",
  color: "",
  minPrice: 0,
  maxPrice: 0,
  incoterm: "",
  filteredClients: [],
  certificationDescription: "",
  seasonalityDescription: "",
  showDescriptionError: false,
  showTitleError: false,
  showHsCodeError: false,
  type: "",
};
class ProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      image: [],
      itemToEdit: {},
    };
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSpecs = this.onChangeSpecs.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onChangeFileName = this.onChangeFileName.bind(this);
    this.onChangeCountru = this.onChangeCountru.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleServicesImage = this.handleServicesImage.bind(this);
    this.addNewProduct = this.addNewProduct.bind(this);
    this.handlePdfUpload = this.handlePdfUpload.bind(this);
    this.onChangeHsCode = this.onChangeHsCode.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onChangeWeight = this.onChangeWeight.bind(this);
    this.onChangeSeason = this.onChangeSeason.bind(this);
    this.onChangeDimension = this.onChangeDimension.bind(this);
    this.onChangeModeOfProduction = this.onChangeModeOfProduction.bind(this);
    this.onChangeCapacity = this.onChangeCapacity.bind(this);
    this.onChangeColor = this.onChangeColor.bind(this);
    this.onChangeMaxPrice = this.onChangeMaxPrice.bind(this);
    this.onChangeMinPrice = this.onChangeMinPrice.bind(this);
    this.onChangeIncoterm = this.onChangeIncoterm.bind(this);
    this.onChangeMinUnits = this.onChangeMinUnits.bind(this);
    this.onChangeCertification = this.onChangeCertification.bind(this);
    this.getItemToEdit = this.getItemToEdit.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onClickClientCompany = this.onClickClientCompany.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.onChangeCertificationDescription =
        this.onChangeCertificationDescription.bind(this);
    this.onChangeSeasonalityDescription =
        this.onChangeSeasonalityDescription.bind(this);
  }

  async componentDidMount() {
    if (this.props.isEditingItem) {
      await this.getItemToEdit();
    }
    if(!this.props.isEditingItem){
      //const { itemToEdit } = this.props;
      await axios.post(`/commodities`, {is_draft:true},
          {
            params: {
              with: [
                "user",
                "user.companies",
                "user.files",
                "user.role",
                "user.inviter",
                "files",
                "commodityType"
              ],
            }
          })
          .then((ret) => {
            if (!isResponseError(ret,false)) {
              this.setState({
                isNewCommodity:true,
                itemToEdit: ret.data,
                pns_id: ret.data.id,
                //DONT KNOW WHAT IS IT
                companyNameForHSCode: ret.data.user.companies[0].name || "",
                countryForHSCode: {
                  value: ret.data.country || "",
                  label: ret.data.country || "",
                },
              })
            }
          })
      console.log('NEW PRODUCT')
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
        this.props.isEditingItem &&
        this.props.itemToEdit !== prevProps.itemToEdit &&
        Object.keys(this.props.itemToEdit).length > 0
    ) {
      await this.getItemToEdit();
    }
    if (
        this.props.clientsHsCode !== prevProps.clientsHsCode &&
        this.props.clientsHsCode
    ) {
      this.props.getAllClients();
    }
    if (this.props.show !== prevProps.show && !this.props.show) {
      this.setState({
        ...INITIAL_STATE,
        image: [],
        itemToEdit: {},
      });
    }
  }

  onChangeSeasonalityDescription(e) {
    this.setState({ seasonalityDescription: e });
  }

  onChangeCertificationDescription(e) {
    this.setState({ certificationDescription: e });
  }

  onChangeName(val) {
    this.setState({ name: val, showTitleError: false });
  }

  onChangeDescription(val) {
    this.setState({ description: val, showDescriptionError: false });
  }

  onChangeTarget(val) {
    this.setState({ target: val });
  }

  onChangeSpecs(val) {
    this.setState({ specification: val });
  }

  onChangeFileName(val) {
    this.setState({ fileName: val });
  }

  onChangeHsCode(val) {
    this.setState({ hsCode: val, showHsCodeError: false });
  }

  onChangeQuantity(val) {
    this.setState({ quantity: val });
  }

  onChangeWeight(val) {
    this.setState({ weight: val });
  }

  onChangeMinUnits(val) {
    this.setState({ minUnits: val });
  }

  onChangeCertification(val) {
    this.setState({ certification: val });
  }

  onChangeSeason(val) {
    this.setState({ season: val });
  }

  onChangeIncoterm(val) {
    let strVal = "";
    for (let i = 0; i < val.length; i++) {
      strVal += `${val[i].value}${i !== val.length - 1 ? "," : ""}`;
    }
    this.setState({ incoterm: strVal });
  }

  onChangeDimension(val, key) {
    const { dimension } = this.state;
    let arr = dimension.split(",");
    const obj = {
      length: arr[0],
      width: arr[1],
      height: arr[2],
    };
    obj[key] = val;
    this.setState({ dimension: `${obj.length},${obj.width},${obj.height}` });
  }

  onChangeModeOfProduction(val) {
    this.setState({ modeOfProduction: val });
  }

  onChangeCapacity(val) {
    this.setState({ capacity: val });
  }

  onChangeColor(val) {
    this.setState({ color: val });
  }

  onChangeMaxPrice(val) {
    this.setState({ maxPrice: val });
  }

  onChangeMinPrice(val) {
    this.setState({ minPrice: val });
  }

  async onClickSubmit() {
    if (this.props.clientHSCodeModal) {
      if (!this.props.isEditingItem) {
        if (
            !this.state.hsCode ||
            !this.state.name ||
            !this.state.description ||
            !this.state.countryForHSCode.value ||
            !this.state.companyNameForHSCode
        ) {
          if (!this.state.hsCode) {
            this.setState({ showHsCodeError: true });
          }
          if (!this.state.name) {
            this.setState({ showTitleError: true });
          }
          if (!this.state.description) {
            this.setState({ showDescriptionError: true });
          }
          if (!this.state.countryForHSCode.value) {
            this.setState({ countryForHSCodeError: true });
          }
          if (!this.state.companyNameForHSCode) {
            this.setState({ companyNameForHSCodeError: true });
          }
          return;
        }
      } else {
        if (
            !this.state.name ||
            !this.state.description ||
            !this.state.countryForHSCode.value ||
            !this.state.companyNameForHSCode
        ) {
          if (!this.state.name) {
            this.setState({ showTitleError: true });
          }
          if (!this.state.description) {
            this.setState({ showDescriptionError: true });
          }
          if (!this.state.countryForHSCode.value) {
            this.setState({ countryForHSCodeError: true });
          }
          if (!this.state.companyNameForHSCode) {
            this.setState({ companyNameForHSCodeError: true });
          }
          return;
        }
      }
    } else {
      if (!this.props.isEditingItem) {
        if (!this.state.hsCode || !this.state.name || !this.state.description) {
          if (!this.state.hsCode) {
            this.setState({ showHsCodeError: true });
          }
          if (!this.state.name) {
            this.setState({ showTitleError: true });
          }
          if (!this.state.description) {
            this.setState({ showDescriptionError: true });
          }
          return;
        }
      } else {
        if (!this.state.name || !this.state.description) {
          if (!this.state.name) {
            this.setState({ showTitleError: true });
          }
          if (!this.state.description) {
            this.setState({ showDescriptionError: true });
          }
          return;
        }
      }
    }

    await this.addNewProduct();
  }
  onCancel() {
    const { itemToEdit } = this.props;
    if (this.state.isNewCommodity)
      this.deleteProduct()
    else
      axios.put(`/commodities/${itemToEdit.id}`, { is_save: false }).then((res) => {
        if (!isResponseError(res)) {
          this.props.onHide();
          this.props.getPnS();
        } else {
          NotificationManager.error("Something went wrong. Try again");
        }
      })
    this.props.onHide();
  }

  async handlePdfUpload(file) {
    let formData = new FormData();
    const { token } = this.props;
    formData.append("pdf", file);
    let pdf_id = await uploadPdf(formData, token);
    this.setState({ pdf: pdf_id });
  }

  async handleServicesImage(file) {
    let formData = new FormData();
    const { token } = this.props;
    const { image } = this.state;
    formData.append("image", file);
    let image_id = await uploadImage(formData, token);
    image.push(image_id);
    this.setState({ image: image });
  }

  onChangeType(type) {
    this.setState({ type: type });
  }

  getItemToEdit() {
    return new Promise((resolve, reject) => {
      const { itemToEdit } = this.props;
      axios
          .get(`/commodities/${itemToEdit.id}`, {
            params: {
              with: [
                "user",
                "user.companies",
                "user.files",
                "user.role",
                "user.inviter",
                "files",
                "commodityType"
              ],
            },
          })
          .then((ret) => {
            if (!isResponseError(ret)) {
              this.setState({
                itemToEdit: ret.data,
                pns_id: ret.data.id,
                //DONT KNOW WHAT IS IT
                companyNameForHSCode: ret.data.user.companies[0].name || "",
                countryForHSCode: {
                  value: ret.data.country || "",
                  label: ret.data.country || "",
                },
              });
            }
            resolve();
          })
          .catch((err) => resolve());
    });
  }

  addNewProduct() {
    return new Promise((resolve, reject) => {
      const { companyIds, isEditingItem} = this.props;
      const {itemToEdit} = this.state;
      const {
        name,
        target,
        specification,
        incoterm,
        hsCode,
        description,
        type,
        companyNameForHSCode,
        countryForHSCode,
      } = this.state;
      // if (itemToEdit) {
      let dataForSend;
      if (this.props.fromAgentDashboard) {
        dataForSend = {
          commodity_type_id: !!type
              ? type === "offer"
                  ? 1
                  : 2
              : this.props.typeAdding === "offer"
                  ? 1
                  : 2,
          company_id: companyIds[0],
          product_id: hsCode ? +hsCode.id : this.state.itemToEdit.product.id,
          name: name || this.state.itemToEdit.name,
          specification: specification || this.state.itemToEdit.specification,
          incoterm: incoterm || this.state.itemToEdit.incoterm,
          target_group: target || this.state.itemToEdit.target_group || "-",
          number: hsCode
              ? String(hsCode.id)
              : String(this.state.itemToEdit.product.id),
          description: description || this.state.itemToEdit.description,
          company_name: companyNameForHSCode || undefined,
          country: countryForHSCode ? countryForHSCode.value : undefined,
          is_save: true,
          is_draft:false
        };
      } else {
        dataForSend = {
          commodity_type_id: !!type
              ? type === "offer"
                  ? 1
                  : 2
              : this.props.typeAdding === "offer"
                  ? 1
                  : 2,
          company_id: companyIds[0],
          product_id: hsCode ? +hsCode.id : itemToEdit.product.id,
          name: name || itemToEdit.name,
          specification: specification || itemToEdit.specification,
          incoterm: incoterm || itemToEdit.incoterm,
          target_group: target || itemToEdit.target_group || "-",
          number: String(hsCode.id) || String(itemToEdit.product.id),
          description: description || itemToEdit.description,
          company_name: companyNameForHSCode || undefined,
          country: countryForHSCode ? countryForHSCode.value : undefined,
          is_save: true,
          is_draft:false
        };
      }
      axios.put(`/commodities/${itemToEdit.id}`, dataForSend).then((res) => {
        if (!isResponseError(res)) {
          NotificationManager.success("Product and Services Added");
          this.props.onHide();
          this.props.getPnS();
          resolve(res);
        } else {
          NotificationManager.error("Something went wrong. Try again");
        }
      });
      // }
    });
  }

  deleteProduct() {
    return new Promise((resolve, reject) => {
      const { pns_id } = this.state;
      let params = new URLSearchParams();

      pns_id ? params.append("pns_id", pns_id) : void 0;

      axios.delete(`/commodities/${pns_id}`).then((res) => {
        if (!isResponseError(res)) {
          NotificationManager.success("Product removed");
          this.props.onHide();
          this.props.getPnS();
          resolve(res);
        } else {
          NotificationManager.error("Something went wrong. Try again");
        }
      });
    });
  }

  onClickClientCompany(e) {
    this.setState({
      companyName: e.company_name,
      country: e.country,
      fname: e.fname,
      lname: e.lname,
      filteredClients: [],
      selectedCompanyId: e.company_id,
    });
  }

  onChangeCountru(e) {
    this.setState({
      countryForHSCode: { value: e.value, label: e.label },
      countryForHSCodeError: false,
    });
  }

  onChangeCompanyName(e) {
    this.setState({
      companyNameForHSCode: e.target.value,
      companyNameForHSCodeError: false,
    });
    const { allClients } = this.props;
    let value = e.target.value;
    this.setState({ companyName: value });
    if (value.length >= 3) {
      let filter = allClients.filter((e) =>
          e.company_name.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ filteredClients: filter });
    } else {
      this.setState({ filteredClients: [] });
    }
  }

  render() {
    const { isEditingItem, typeAdding, clientsHsCode } = this.props;

    const {
      itemToEdit,
      filteredClients,
      companyName,
      showTitleError,
      showDescriptionError,
      showHsCodeError,
    } = this.state;

    return (
        <Modal
            show={this.props.show}
            size={"lg"}
            onHide={this.onCancel}
            className="CompanyServicesModal"
        >
          <Modal.Header
              closeButton
              style={{ background: userLevelColors(this.props.level).bg }}
          >
            <h3
                style={{
                  color: userLevelColors(this.props.level).font,
                  textTransform: "capitalize",
                }}
            >
              Detailed {typeAdding ? typeAdding : "Product"}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <p>
              Here you have the possibility to show a range of products and
              services in detail to your fellow network members. You can upload
              pictures for each item to make it highly visible.
            </p>
            {clientsHsCode && !this.props.clientHSCodeModal && (
                <input
                    onChange={this.onChangeCompanyName}
                    type="text"
                    className={classNames(
                        "mb-3 form-control",
                        userLevelColors(this.props.level).className,
                        { "input-error": this.state.companyNameForHSCodeError }
                    )}
                    placeholder="Client Company Name*"
                    value={companyName}
                />
            )}

            {this.props.clientHSCodeModal && (
                <input
                    onChange={(e) => {
                      this.setState({
                        companyNameForHSCode: e.currentTarget.value,
                        companyNameForHSCodeError: false,
                      });
                    }}
                    type="text"
                    className={classNames(
                        "mb-3 form-control",
                        userLevelColors(this.props.level).className,
                        { "input-error": this.state.companyNameForHSCodeError }
                    )}
                    placeholder="Client Company Name*"
                    value={this.state.companyNameForHSCode}
                />
            )}
            <div
                style={
                  filteredClients.length > 0
                      ? {
                        maxHeight: "200px",
                        borderRadius: "4px",
                        overflow: "scroll",
                        zIndex: "10",
                      }
                      : {}
                }
                className={
                    "position-absolute mt-3 col-lg-12 pl-0 pr-4 w-100 " +
                    (filteredClients.length > 0 ? "" : "d-none")
                }
            >
              <ul className="list-group w-100">
                {filteredClients.map((item, i) => {
                  return (
                      <li
                          style={{ cursor: "pointer" }}
                          key={i}
                          className="list-group-item"
                          onClick={this.onClickClientCompany.bind(null, item)}
                      >
                        {item.company_name}
                      </li>
                  );
                })}
              </ul>
            </div>
            <OSFormComponent
                de
                companyNameForHSCodeError={this.state.companyNameForHSCodeError}
                showDescriptionError={showDescriptionError}
                showTitleError={showTitleError}
                showHsCodeError={showHsCodeError}
                typeAdding={typeAdding}
                isEditing={isEditingItem}
                itemToEdit={itemToEdit}
                onChangeSeasonalityDescription={this.onChangeSeasonalityDescription}
                onChangeCertificationDescription={
                  this.onChangeCertificationDescription
                }
                clientHSCodeModal={this.props.clientHSCodeModal}
                countryForHSCodeError={this.state.countryForHSCodeError}
                onChangeCountru={this.onChangeCountru}
                countryForHSCode={this.state.countryForHSCode}
                onChangeName={this.onChangeName}
                onChangeDescription={this.onChangeDescription}
                onChangeTarget={this.onChangeTarget}
                onChangeSpecs={this.onChangeSpecs}
                onChangeFileName={this.onChangeFileName}
                onChangeImage={this.handleServicesImage}
                onChangePdf={this.handlePdfUpload}
                onChangeType={this.onChangeType}
                onChangeHsCode={this.onChangeHsCode}
                onChangeQuantity={this.onChangeQuantity}
                onChangeWeight={this.onChangeWeight}
                onChangeMinUnits={this.onChangeMinUnits}
                onChangeCertification={this.onChangeCertification}
                onChangeSeason={this.onChangeSeason}
                onChangeDimension={this.onChangeDimension}
                onChangeModeOfProduction={this.onChangeModeOfProduction}
                onChangeColor={this.onChangeColor}
                onChangeCapacity={this.onChangeCapacity}
                onChangeMinPrice={this.onChangeMinPrice}
                onChangeMaxPrice={this.onChangeMaxPrice}
                onChangeIncoterm={this.onChangeIncoterm}
                onChangeCompanyName={this.onChangeCompanyName}
            />
          </Modal.Body>
          <Modal.Footer>
            {(!this.state.isNewCommodity ? <Button
                variant="primory"
                style={{
                  background: userLevelColors(this.props.level).buttonBg,
                  color: userLevelColors(this.props.level).buttonFont,
                  borderColor: userLevelColors(this.props.level).buttonFont,
                }}
                onClick={this.deleteProduct}
            >
              Delete
            </Button> : null)}
            <Button
                variant="secondary"
                style={{
                  background: userLevelColors(this.props.level).buttonBg,
                  color: userLevelColors(this.props.level).buttonFont,
                  borderColor: userLevelColors(this.props.level).buttonFont,
                }}
                onClick={this.onCancel}
            >
              Close
            </Button>
            <Button variant="dark" style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
                    onClick={this.onClickSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let level = state.login.level;
  let token = state.login.token;
  let companyIds = state.login.company_ids;
  return {
    level,
    token,
    companyIds,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
