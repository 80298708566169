import React from "react";
import { authenticateUser } from "../store/actions/loginAction";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { base_url, countryList } from "../services/const";
import { SearchResultListItem } from "../components/SearchResultListItem";
import {
  filterCountry,
  getCountries,
  SearchSpinner,
} from "../services/globalFunctions";
import MembershipPackages from "../components/MembershipPackages";
import MyProductsListItem from "../components/MyProductsListItem";
import { isResponseError } from "../utils/axios";

let timeInterval;

class MyMatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      searchVal: "",
      matchesCc: [],
      searchResult: [],
      loading: false,
      countries: countryList,
    };
    this.getMatches = this.getMatches.bind(this);
    // this.onChangeCountryFilter = this.onChangeCountryFilter.bind(this);
  }

  componentDidMount() {
    this.getMatches();
    axios.post("/matches");
  }

  loadingHandler() {
    return setTimeout(() => {
      this.setState({ showLoading: false });
    }, 3000);
  }

  getMatches(e) {
    e ? e.preventDefault() : void 0;

    this.setState({ showLoading: true });
    axios
      .get("/pairings", {
        params: {
          search: this.state.searchVal || undefined,
          columns_search: ["product.code", "name", "description", "country"],
        },
      })
      .then((response) => {
        if (!isResponseError(response)) {
          this.setState({
            // countries: getCountries(ret.data.data.companies),
            // matchesCc: ret.data.data.companies,
            matches: response.data.data,
          });
          this.loadingHandler();
        }
      })
      .catch((err) => {
        console.log(err);
        this.loadingHandler();
      });
  }

  //   searchItem() {
  //     const {
  //       type,
  //       searchVal,
  //       maxPrice,
  //       minPrice,
  //       selectedHsCode,
  //       selectedCountries,
  //       incoterm,
  //       minQuantity,
  //     } = this.state;
  //     this.setState({ loading: true });
  //     let url = `${base_url}/commodities/search/100/0?`;
  //     if (type) {
  //       url += `type=${type}&`;
  //     }
  //     if (searchVal) {
  //       url += `search=${searchVal}&`;
  //     }
  //     if (maxPrice) {
  //       url += `min_price=${maxPrice}&`;
  //     }
  //     if (minPrice) {
  //       url += `max_price=${minPrice}&`;
  //     }
  //     if (selectedHsCode) {
  //       url += `hscode=${selectedHsCode}&`;
  //     }
  //     if (selectedCountries.length > 0) {
  //       url += `country=${selectedCountries.join(",")}&`;
  //     }
  //     if (incoterm.length > 0) {
  //       console.log(incoterm.join());
  //       url += `incoterm=${incoterm.join(",")}&`;
  //     }
  //     if (minQuantity) {
  //       url += `quantity=${minQuantity}`;
  //     }
  //     clearTimeout(timeInterval);
  //     timeInterval = setTimeout(() => {
  //       axios
  //         .request({
  //           method: "GET",
  //           url: url,
  //           headers: {
  //             "Content-Type": "application/x-www-form-urlencoded",
  //             Authorization: `Bearer ${this.props.token}`,
  //           },
  //         })
  //         .then((ret) => {
  //           if (ret.data.data && ret.data.status === 200) {
  //             this.setState({ searchResult: ret.data.data, loading: false });
  //           } else {
  //             this.setState({ searchResult: [], loading: false });
  //           }
  //           console.log(ret);
  //         });
  //     }, 1000);
  //   }

  //   onChangeCountryFilter(e) {
  //     if (e.target.value === "") {
  //       this.getMatches();
  //     } else {
  //       this.setState({
  //         matches: filterCountry(this.state.matchesCc, e.target.value),
  //       });
  //     }
  //   }

  render() {
    return (
      <div className="page-container MyMatchesContainer">
        <div className={"page-header mb-5"}>
          <form
            onSubmit={this.getMatches}
            className="form-group col-5 position-absolute topsearch-container  row"
            style={{
              bottom: "55px",
            }}
          >
            <h1 className="search-heading">My Matches</h1>
            <input
              className="form-control topsearch"
              placeholder="Search.."
              value={this.state.searchVal}
              onChange={(e) => this.setState({ searchVal: e.target.value })}
            />
            <button
              type="button"
              className="navbar-search-btn bg-transparent border-0"
              onClick={this.getMatches}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 pl-0"></div>
            {this.state.matches.length > 0 && (
              <div className="col-lg-8 col-sm-12 col-sm-12 row">
                {/* <div className="col-4 ml-auto">
                  <select
                    onChange={this.onChangeCountryFilter}
                    className="form-control border-right-0 border-left-0 border-top-0 text-uppercase"
                    style={{ borderRadius: 0 }}
                  >
                    <option value={""}>Country</option>
                    {this.state.countries.map((item, i) => {
                      return (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
              </div>
            )}
          </div>
          {this.state.showLoading && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ fontWeight: 700 }}>
                We are looking for matches to your search/offer on the WorldRing
                Network
              </div>
              <SearchSpinner showLoading={this.state.showLoading} />
              <div>
                Please allow up to 60 seconds for this process to complete
              </div>
            </div>
          )}
          {}
          <div
            className={"row mt-5 " + (this.state.showLoading ? "d-none" : "")}
          >
            {this.state.matches.length ? (
              this.state.matches.map((item, i) => {
                return (
                  //   <SearchResultListItem
                  //     user={this.props.level}
                  //     showOverlay={false}
                  //     {...item}
                  //     key={i}
                  //     token={this.props.token}
                  //   />
                  <MyProductsListItem
                    key={item.id}
                    item={item}
                    hideButton={true}
                  />
                );
              })
            ) : (
              <div className="searchresult w-100 p-3 mb-4 position-relative col-lg-12">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="col-lg-2">
                    <img
                      style={{
                        height: "70px",
                        width: "auto",
                        objectFit: "contain",
                        maxWidth: "200px",
                      }}
                      src={require("../assets/images/WorldRing.png")}
                      alt="..."
                    />
                  </div>
                  <div className="col-lg-6 ml-3">
                    <p>
                      There is currently no match for your request available.
                    </p>
                    <p>
                      Our network is still trying to find the right match for
                      your request.
                    </p>
                    <p>
                      Please contact your designated agent, if you need any
                      further support.
                    </p>
                  </div>
                  <div className="col-lg-2 ml-3"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let token = state.login.token;
  let level = state.login.level;
  return {
    isLoggedIn,
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyMatches);
